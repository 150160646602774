.head-category-part {
  // border: 1px solid black;
  margin-top: 40px;
  h2 {
    text-align: center;
    font-weight: 500;
    font-size: 40px;
    text-transform: uppercase;
    font-family: "Cormorant", sans-serif;
  }
  @media screen and (max-width: 480px) {
    margin-top: 20px;
    h2 {
      font-size: 20px;
    }
  }
  // @include bp(md) {
  //   h2 {
  //     font-size: 20px;
  //   }
  // }
}

.wrapper {
  padding: 30px auto;
  width: 95%;
  position: relative;
  margin: 0px auto 40px auto;
  // border: 1px solid black;
  @media screen and (max-width: 1000px) {
    padding: 20px 0;
  }
  @media screen and (max-width: 480px) {
    padding: 0;
    margin: 0px auto 10px auto;
  }
}

.wrapper .carousel {
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: calc((100% / 5) - 12px);
  overflow-x: auto;
  scroll-snap-type: x mandatory;
  gap: 16px;
  border-radius: 8px;
  scroll-behavior: smooth;
  scrollbar-width: none;
  @media screen and (max-width: 1000px) {
    grid-auto-columns: calc((90% / 2) - 80px);
    // border: 1px solid black;
    padding: 0;
    gap: 0;
  }
  @media screen and (max-width: 480px) {
    grid-auto-columns: calc((100% / 2) - 80px);
    // border: 1px solid black;
    padding: 0;
    gap: 0;
  }
  @media screen and (max-width: 350px) {
    grid-auto-columns: calc((100% / 2) - 60px);
    // border: 1px solid black;
    padding: 0;
    gap: 0;
  }
}
.carousel::-webkit-scrollbar {
  display: none;
}
.carousel.no-transition {
  scroll-behavior: auto;
}
.carousel.dragging {
  scroll-snap-type: none;
  scroll-behavior: auto;
}
.carousel.dragging .card {
  cursor: grab;
  user-select: none;
}
.carousel :where(.card, .img) {
  display: flex;
  justify-content: center;
  align-items: center;
}
.carousel .card {
  scroll-snap-align: start;

  list-style: none;
  background: #fff;
  cursor: pointer;
  padding-bottom: 5px;
  flex-direction: column;
  border-radius: 8px;
}
.carousel .card .img {
  height: 178px;
  width: 178px;
  border-radius: 50%;
  @media screen and (max-width: 480px) {
    height: 80px;
    width: 80px;
    // border: 1px solid black;
  }
}
.card .img img {
  width: 178px;
  height: 178px;
  border-radius: 50%;
  object-fit: cover;
  border: 4px solid #fff;
  @media screen and (max-width: 480px) {
    height: 80px;
    width: 80px;
  }
}
.carousel .card h2 {
  font-family: "Lato", sans-serif;
  font-weight: 300;
  font-size: 20px;
  margin-top: 10px;
  @media screen and (max-width: 480px) {
    font-size: 14px;
    margin-top: 0;
  }
}
// @media (max-width: 480px) {
//   .head-part h2 {
//     text-align: center;
//     font-weight: 500;
//     font-size: 46px;
//     text-transform: uppercase;
//     font-family: "Cormorant", sans-serif;
//   }
//   .wrapper .carousel {
//     grid-auto-columns: calc((100% / 2) - 9px);
//     gap: 0;

//     .card .img img {
//       width: 100px;
//       height: 100px;
//     }
//   }
// }
@media screen and (max-width: 992px) and (orientation: landscape) {
  .head-category-part {
    h2 {
      font-size: 30px;
      margin-bottom: 0px;
    }
  }
  .wrapper .carousel{
    grid-auto-columns: calc((90% / 2) - 100px); 
  }
  .card .img img{
    width: 135px;
    height: 135px;
  }
  .wrapper{
    padding: 0px 0px;
  }
}