.profile-main-content {
  padding: 4% 5.50%;
  display: flex;
  gap: 40px;
  padding-bottom: 80px;
}
.profile-menu-icons {
  display: flex;
  flex-direction: column;
  gap: 30px;
}
.icons-details {
  display: flex;
  gap: 15px;
  align-items: center;
}
.icons-details p {
  font-family: Cormorant;
  font-size: 28px;
  font-weight: 400;
  color: #494949;
}
.icons-details p:active {
  color: #0c0c0c;
}
.icons-details svg path  {
  color: #494949;
  // fill: red;
}
.icons-details {
  // Default styles for the icon details
  cursor: pointer; // Change cursor on hover
  &.active {
    p {
      color: #000000; // Active text color
    }
    svg path {
      color: #000000;
    }
  }
}
.icons-details svg path {
  stroke: #767676; /* Default stroke color */
  // font-size: 25px;
  // color: #767676;
}

// .icons-details:hover svg path {
//   stroke: #000000; /* Change stroke color on hover */
// }

.icons-details {
  cursor: pointer; /* Change cursor on hover */
}

.icons-details.active p {
  color: #000000; /* Active text color for <p> elements */
}

.icons-details.active svg path {
  stroke: #000000; /* Change stroke color for active state */
}

.border-div {
  border: 1px solid #a6a6a6;
  height: 350px;
}
.active {
  color: #0c0c0c;
}
.profile-all-details-box {
  width: 100%;
  border: 1px solid #595959;
  box-shadow: 0px 4px 20px 0px #59595940;
  padding: 15px 25px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.user-all-about-details {
  display: flex;
  flex-direction: column;
  gap: 5px;
}
.user-all-about-details label {
  font-family: Cormorant;
  font-size: 22px;
  font-weight: 400;
  color: #0c0c0c;
}
.user-all-about-details input,
.address-details {
  font-family: Lato;
  font-size: 22px;
  font-weight: 400;
  color: #303030;
  outline: none;
  border-style: none;
}
.address-details {
  width: 70%;
  margin-bottom: 20px;
}
.user-all-about-details input::placeholder {
  font-family: Lato;
  font-size: 22px;
  font-weight: 400;
  color: #303030;
  outline: none;
}
.profile-all-details-box button {
  border-radius: 0%;
  border: 1px solid #000000;
  background: #000000;
  font-family: Lato;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 1px;
  color: #ffffff;
  width: 35%;
  padding: 10px 0px;
}
@media (max-width: 992px) {
  .icons-details svg {
    // font-size: 20px;
    width: 25px;
    height: 25px;
  }
  .icons-details p {
    font-size: 25px;
  }
  .border-div {
    height: 330px;
  }
  .user-all-about-details label {
    font-size: 18px;
  }
  .user-all-about-details input,
  .address-details {
    font-size: 20px;
  }
  .address-details {
    width: 100%;
  }
  .user-all-about-details input::placeholder {
    font-size: 20px;
  }
  .profile-all-details-box button {
    font-size: 14px;
    width: 50%;
    padding: 10px 0px;
  }
}
@media (max-width: 768px) {
  .profile-main-content {
    display: block;
    padding: 50px 20px;
  }
  .profile-all-details-box {
    margin-top: 40px;
  }
  .icons-details svg {
    // font-size: 20px;
    width: 22px;
    height: 22px;
  }
  .icons-details p {
    font-size: 22px;
  }
  .border-div {
    display: none;
  }
  .user-all-about-details label {
    font-size: 18px;
  }
  .user-all-about-details input,
  .address-details {
    font-size: 18px;
  }
  .user-all-about-details input::placeholder {
    font-size: 18px;
  }
  .profile-all-details-box button {
    font-size: 14px;
    width: 100%;
    padding: 8px 0px;
  }
  .profile-menu-icons {
    width: 100%;
    flex-direction: row;
    overflow-x: auto;
    // overflow-x: hidden;
    scrollbar-width: none;
    white-space: nowrap;
  }
}
