.checkout-address-content {
  padding: 3% 4%;
  width: 100%;

  h5 {
    font-family: Cormorant;
    font-size: 26px;
    font-weight: 500;
    color: #000000;
  }
  .checkout-address-form-left-right {
    display: flex;
    gap: 30px;
    @media (max-width: 500px) {
      display: block;
    }
  }
}
.label-and-input-box ::placeholder {
  font-size: 18px !important;
}
.checkout-address-form-left {
  width: 70%;
  // border: 1px solid black;
  @media (max-width: 500px) {
    margin-bottom: 20px;
  }
}
.checkout-address-form-left .address-form-inputs {
  width: 100%;
  // margin-top: 10px;
}
.two-input-box {
  display: flex;
  gap: 20px;
  justify-content: space-between;
}
.label-and-input-box {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  position: relative;
  .error {
    color: red;
  }
  .default-india-code-checkout-add {
    position: absolute;
    color: #595959;
    font-size: 18px;
    z-index: 20;
    top: 21px;
    margin-right: 20px;
    left: 20px;
  }
}
.label-and-input-box {
  margin-bottom: 15px;
}
.label-and-input-box label {
  font-family: Lato;
  font-size: 18px;
  font-weight: 500;
  color: #595959;
  margin-top: 20px;
}
.label-and-input-box input {
  border: 1px solid #c9c9c9;
  color: #595959;
  font-size: 18px;
  height: 48px;
  margin-top: 10px;
  width: 100%;
  padding: 0 20px;
  // margin-bottom: 15px;
}
.label-and-input-box input[name="phoneNumber"] {
  padding-left: 60px; /* Increase padding to make space for the code */
  // border: 1px solid #c9c9c9;
  // height: 44px;
  // margin-top: 8px;
  // padding: 0px 20px;
  // font-size: 16px;
  // margin-bottom: 15px;
}
.checkout-address-form-left button {
  border-radius: 0;
  margin-top: 30px;
  width: 50%;
  background: #000000;
  padding: 10px;
  color: #ffffff;
  border-style: none;
  font-family: Lato;
  font-size: 12px;
  font-weight: 400;
  letter-spacing: 1px;
}

.checkout-address-form-left button:hover {
  background-color: #fff;
  color: #000;
  border: 1px solid black;
}
.checkout-address-form-left button:disabled {
  background-color: #ccc; /* Light gray background */
  color: #666; /* Dim text color */
  cursor: not-allowed; /* Change cursor to indicate the button is disabled */
  opacity: 0.6;
}
.checkout-address-form-right {
  width: 40%;
}

.default-india-code-main {
  position: absolute;
  top: 45%; /* Center vertically */
  left: 20px; /* Adjust to place it outside the input */
  transform: translateY(-50%); /* Center align vertically */
  font-size: 18px; /* Adjust font size */
  color: #595959; /* Change as needed */
  margin-top: -1.1px;
}

.form-field-state-field {
  width: 100%;
  .error {
    color: red;
  }
  .form-field-input-state {
    margin-top: 8px;
    // margin-bottom: 8px;
    border: 1px solid #c9c9c9;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5px 20px;
    height: 44px;
    position: relative;
    svg {
      cursor: pointer;
    }
    input {
      outline: none;
      border: none;
      font-family: Lato;
      font-size: 18px;
      font-weight: 500;
      color: #595959;
    }
    input::placeholder {
      font-family: Lato;
      font-size: 18px;
      font-weight: 500;
      color: #595959;
    }
    svg {
      color: #595959;
    }
  }
}
@media (max-width: 992px) {
  .checkout-address-form-left {
    width: 50%;
  }
  .checkout-address-form-right {
    width: 50%;
  }
  .checkout-address-content {
    gap: 30px;
  }
  .label-and-input-box input {
    height: 40px;
  }
}
@media (max-width: 768px) {
  .checkout-address-content h5 {
    margin-bottom: 10px;
  }
  .checkout-address-form-left {
    width: 100%;
  }
  .checkout-address-form-right {
    width: 100%;
    margin-top: 80px;
  }
  .checkout-address-content {
    display: block;
  }
  .two-input-box {
    display: block;
  }
  .checkout-address-form-left button {
    width: 100%;
  }
  .checkout-address-content {
    padding: 10% 4%;
    margin-bottom: 20px;
  }
}
.state-field {
  margin-top: 10px !important;
  height: 48px !important;
}
