.mobile-collection-cards-page-container {
  width: 100%;
}
.mobile-collection-cards-main-content {
  padding: 5% 4%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.all-mobile-collection-cards {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
}
.collection-mobile-card {
  box-shadow: 0px 0px 9.31px 0px #0000001f;
  text-align: center;
  margin-top: 10px;
  width: 160px;
  height: auto;
  padding-bottom: 10px;
}
.collection-mobile-card p {
  margin-top: 5px;
  font-family: Cormorant;
  font-size: 14px;
  font-weight: 500;
  color: #000000;
}
.image-of-collection-mobile-card {
  height: 200px;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
