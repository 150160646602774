.checkout-login-content {
  padding: 3% 4%;
  padding-bottom: 50px;
}
.checkout-login-content {
  display: flex;
  justify-content: space-between;
}
.checkout-login-form {
  width: 45%;
}
.checkout-login-form h5 {
  font-family: Cormorant;
  font-size: 26px;
  font-weight: 500;
  color: #000000;
}
.login-form-inputs {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  position: relative;
  .error {
    color: red;
    // border: 1px solid black;
    margin-bottom: 16px;
  }
}
.login-form-inputs label {
  font-family: Lato;
  font-size: 18px;
  font-weight: 500;
  color: #595959;
  margin-bottom: 8px;
}
.login-form-inputs input {
  border: 1px solid #c9c9c9;
  height: 44px;
  margin-bottom: 20px;

  padding-right: 40px; /* Leave space for the eye icon */
  position: relative;
  padding: 0px 20px;
  font-size: 16px;
  color: #636262;
}
.login-form-inputs input::placeholder {
  font-family: Lato;
  font-weight: 400;
  color: #636262;
  font-size: 18px;
}
.password-container {
  position: relative;
}
.password-container input {
  width: 100%;
}
.password-icon {
  position: absolute;
  right: 20px;
  top: 75px;
  cursor: pointer;
  color: #595959;
  font-size: 20px;
}
.terms-condition-text {
  font-family: "Lato", sans-serif;
  font-size: 16px;
  font-weight: 500;
  color: #595959;
  margin-top: 20px;
  a {
    color: inherit;
    text-decoration: underline;
  }
}
.checkout-login-form button {
  width: 100%;
  background: #000000;
  font-family: Lato;
  font-size: 12px;
  font-weight: 400;
  letter-spacing: 1px;
  color: #ffffff;
  height: 40px;
  margin-top: 20px;
  border-radius: 0px;
}
.checkout-login-form button:hover {
  border: 1px solid black;
  color: #000;
  background-color: #fff;
  // opacity: 0.6;
}
.checkout-login-form button:disabled {
  background-color: #ccc; /* Light gray background */
  color: #666; /* Dim text color */
  cursor: not-allowed; /* Change cursor to indicate the button is disabled */
  opacity: 0.6;
}
.guest-checkout-button {
  button {
    width: 100%;
    background: #ffffff;
    font-family: "Lato", sans-serif;
    font-size: 12px;
    font-weight: 400;
    letter-spacing: 1px;
    color: #000000;
    height: 40px;
    margin-top: 20px;
    border-radius: 0px;
    text-transform: uppercase;
    border: 1px solid #000000;
  }
  button:hover {
    background-color: #000;
    color: white;
  }
}
.signup-forget-button {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.signup-forget-button p {
  cursor: pointer;
  font-family: Lato;
  font-size: 14px;
  font-weight: 400;
  color: #595959;
}
.signup-forget-button p:hover {
  color: #0c0c0c;
}
.sign-up-with {
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
  p {
    font-size: 14px;
    font-weight: 400;
    font-family: "Lato", sans-serif;
  }
  span {
    text-decoration: underline;
    cursor: pointer;
  }
}
.facebook-google-apple-button button {
  background-color: transparent;
  color: #000000;
  border: 1px solid #000000;
  padding: 8px 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  font-family: Lato;
  font-size: 12px;
  font-weight: 400;
  letter-spacing: 1px;
  width: 200px;
}
.facebook-google-apple-button {
  display: flex;
  justify-content: center;
  margin-top: 20px;
  gap: 80px;
}
.facebook-google-apple-button button svg {
  color: #000000;
  font-size: 20px;
}
//card
.checkout-payment-form {
  width: 39%;
}
.order-summary {
  border-bottom: 1px solid #a6a6a6;
}
.order-summary h5 {
  font-family: Cormorant;
  font-size: 28px;
  font-weight: 500;
  color: #0c0c0c;
  padding-bottom: 10px;
}
.payment-details-cart {
  display: flex;
  gap: 15px;
  margin-top: 20px;
  width: 100%;
  height: auto;
}
.small-details-payment {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.payment-image img {
  width: 100%;
  height: auto;
  max-height: 130px;
  object-fit: cover;
}
.name-price {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.name-price h6 {
  font-family: Cormorant;
  font-size: 20px;
  font-weight: 600;
  color: #0c0c0c;
}
.name-price p {
  font-family: Cormorant;
  font-size: 24px;
  font-weight: 600;
  color: #363636;
}
.size-text-and-color {
  margin-top: 10px;
}
.size-text-and-color p {
  font-family: Lato;
  font-size: 14px;
  font-weight: 400;
  color: #595959;
}
.text-of-cart p {
  font-family: Lato;
  font-size: 12px;
  font-weight: 400;
  color: #636262;
  margin-top: 15px;
}
.subtotal {
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #a6a6a6;
  padding-bottom: 10px;
  align-items: center;
}
.subtotal h5 {
  font-family: Cormorant;
  font-size: 18px;
  font-weight: 500;
  color: #000000;
}
.subtotal p {
  font-family: Cormorant;
  font-size: 28px;
  font-weight: 500;
  color: #0c0c0c;
}
.end-text-of-cart {
  margin-top: 5px;
  font-family: Lato;
  font-size: 16px;
  font-weight: 400;
  color: #303030;
}
@media (max-width: 992px) {
  .checkout-login-form {
    width: 50%;
  }
  .checkout-payment-form {
    width: 50%;
  }
  .checkout-login-content {
    gap: 20px;
  }
  .facebook-google-apple-button button {
    padding: 8px 10px;
    gap: 10px;
  }
}
@media (max-width: 768px) {
  .checkout-login-form {
    width: 100%;
    padding: 20px 10px;
  }
  .checkout-payment-form {
    width: 100%;
    margin-top: 80px;
  }
  .facebook-google-apple-button button {
    padding: 8px 30px;
    gap: 20px;
  }
  .checkout-login-content {
    display: block;
  }
  .facebook-google-apple-button {
    gap: 20px;
  }
  .center-the-cart {
    width: 100%;
  }
}
@media (max-width: 576px) {
  .facebook-google-apple-button button {
    gap: 15px;
    padding: 8px 10px;
  }
  .facebook-google-apple-button {
    gap: 10px;
  }
  .facebook-google-apple-button button svg {
    font-size: 18px;
  }
}
