//ContactUs.scss
.contactus-page-container {
  // padding-bottom: 100px;
}
.contact-us-h2 {
  font-family: Cormorant;
  font-size: 40px;
  font-weight: 500;
  line-height: 44px;
  text-align: center;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #000000;
  margin-top: 50px;
}
.contact-us-form-details-and-heading h5 {
  font-family: Lato;
  font-size: 22px;
  font-weight: 500;
  text-align: center;
  color: #595959;
}
.contact-us-form-main-content {
  width: 75%;
  margin: 0 auto;
}
.contact-us-inputs-form {
  display: flex;
  gap: 30px;
  width: 100%;
  margin-top: 22px;
}
.form-field {
  width: 100%;
  display: flex;
  // align-items: center;
}
.form-field input {
  width: 100% !important;
}
.form-field textarea {
  border-radius: 0px !important;
  margin-top: 8px;
  font-family: Lato;
  font-size: 18px;
  font-weight: 500;
  color: #595959;
  padding-left: 20px !important;
}
.form-field textarea::placeholder {
  font-size: 18px;
}
.contact-us-inputs-form-city-field {
  margin-top: 30px;
  width: 48.8%;
}
.contact-us-send-button {
  border-radius: 0px;
  padding: 10px 55px;
  margin-top: 15px;
}
.form-field input::placeholder {
  font-family: Lato;
  font-size: 18px;
  font-weight: 500;
  color: #595959;
}
.form-field input {
  font-family: Lato;
  font-size: 18px;
  font-weight: 500;
  color: #595959;
}
.form-field-input {
  margin-top: 8px;
  margin-bottom: 8px;
  input {
    outline: none;
    border: none;
    font-family: Lato;
    font-size: 18px;
    font-weight: 500;
    color: #595959;
  }
  input::placeholder {
    font-family: Lato;
    font-size: 18px;
    font-weight: 500;
    color: #595959;
  }
  svg {
    color: #595959;
  }
}
.form-field-input {
  border: 1px solid #c9c9c9;
  width: 100%;
  display: flex !important;
  justify-content: space-between !important;
  align-items: center !important;
  padding: 5px 20px;
  height: 44px;
  position: relative;
  svg {
    cursor: pointer;
  }
}
.dropdown-options {
  position: absolute;
  background-color: #fff;
  border: 1px solid #ccc;
  max-height: 180px;
  // overflow-y: auto;
  width: 100%;
  z-index: 1000;
  top: 100%;
  // left: 5;
  margin-left: -21px;
  overflow-y: auto;
}
.dropdown-option.selected {
  background-color: #e0e0e0; /* Highlight color */
  font-weight: bold;
}
.input-with-icons {
  width: 100%;
  padding-right: 20px;
  border: none;
  background: transparent;
}
.dropdown-option {
  padding: 5px 20px;
  color: #595959;
  font-size: 15px;
  cursor: pointer;
  &:hover {
    background-color: #f0f0f0;
  }
}
.dropdown {
  display: flex;
  align-items: center; // Center the icon vertically
  width: 100%; // Ensure it occupies the full width of the parent container
  position: relative;
}
.contactus-address-details-and-image {
  display: flex;
  width: 100%;
  margin-top: 50px;

  .contactus-address-image {
    width: 70%;
    .my-contactus-image-div img {
      width: 100%;
      height: 550px;
      object-fit: cover;
    }
  }
  .contactus-address-email-phone-number {
    width: 30%;
    border-top: 1px solid #595959;
    padding: 60px 50px;
    .all-email-phone-details-of-contact {
      h5 {
        font-family: Cormorant;
        font-size: 32px;
        font-weight: 500;
        line-height: 38.75px;
        text-underline-position: from-font;
        text-decoration-skip-ink: none;
        color: #0c0c0c;
      }
      .headquarters-details {
        h2 {
          font-family: Cormorant;
          font-size: 24px;
          font-weight: 500;
          line-height: 29.06px;
          text-align: left;
          text-underline-position: from-font;
          text-decoration-skip-ink: none;
          color: #000000;
          margin-top: 20px;
        }
        p {
          font-family: Lato;
          font-size: 16px;
          font-weight: 400;
          line-height: 22px;
          text-align: left;
          text-underline-position: from-font;
          text-decoration-skip-ink: none;
          margin-top: -15px;
          width: 95%;
          color: #595959;
        }
      }
      .headquarters-text {
        margin-top: 30px;
        font-family: Cormorant;
        font-size: 24px;
        font-weight: 500;
        line-height: 29.06px;
        text-underline-position: from-font;
        text-decoration-skip-ink: none;
        color: #000000;
        .email-text-head {
          font-family: Lato;
          font-size: 16px;
          font-weight: 400;
          line-height: 22px;
          text-align: left;
          text-underline-position: from-font;
          text-decoration-skip-ink: none;
          color: #595959;
        }
      }
    }
  }
}
@media (max-width: 768px) {
  .contactus-address-email-phone-number {
    padding: 30px 20px !important;
  }
  .contact-us-h2 {
    font-size: 32px !important;
  }
  .contact-content {
    display: block;
    gap: 50px !important;
  }
  .Address h2 {
    margin-top: 40px;
  }
  .contact-us-form-details-and-heading h5 {
    font-size: 22px;
    padding: 0px 20px;
  }
  .contact-us-form-main-content {
    width: 90%;
    margin: 0 auto;
  }
  .contact-us-inputs-form {
    display: block;
    margin-top: 15px;
  }
  .form-field {
    margin-top: 15px;
  }
  .form-field label {
    font-size: 15px;
  }
  .contact-us-inputs-form-city-field {
    width: 100%;
  }
  .contact-us-send-button {
    font-size: 12px;
    width: 100%;
  }
  .contactus-address-details-and-image {
    display: block;
    .contactus-address-image {
      width: 100%;
    }
    .contactus-address-email-phone-number {
      width: 100% !important;
      border-top: none;
    }
  }
  .state-field {
    height: 40px !important;
  }
  .contactus-address-image {
    .my-contactus-image-div img {
      width: 100%;
      height: auto !important;
    }
  }
  .subject-input-filed {
    margin-top: 40px;
  }
  .business-filed {
    margin-top: 25px;
  }
  .contactus-address-details-and-image
    .contactus-address-email-phone-number
    .all-email-phone-details-of-contact
    h5 {
    font-size: 32px;
  }
}
