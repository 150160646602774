.head-new-part {
  // border: 1px solid black;
  h2 {
    text-align: center;
    font-weight: 500;
    font-size: 40px;
    text-transform: uppercase;
    font-family: "Cormorant", sans-serif;
  }
  @media screen and (max-width: 480px) {
    margin-top: -10px;
    h2 {
      font-size: 20px;
    }
  }
}

.new-product-carousel {
  padding: 0 20px;
  @media screen and (max-width: 480px) {
    padding: 0 10px;
  }
}

.new-product-slider {
  position: relative;
  width: 100%;
  margin: 40px auto;
  overflow: hidden;
  @media screen and (max-width: 480px) {
    margin: 10px auto;
  }
}

.new-slider-wrapper {
  width: 95%;
  overflow: hidden;
  margin: 0px auto;
  @media screen and (max-width: 480px) {
    width: 80%;
  }
}

.new-slider-content {
  display: flex;
  cursor: pointer;
  transition: transform 0.3s ease-in-out;
  gap: 1rem;
  // @media screen and (max-width: 480px) {
  //   gap: 0;
  // }
}

.new-product-item {
  min-width: 24%;
  max-width: 24%;
  padding: 10px;
  box-sizing: border-box;
  border: 1px solid #eee;
}
.new-product-image {
  width: 100%;
  height: 400px;
  overflow: hidden;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: transform 0.4s ease;
  }
}
.new-product-image:hover img {
  transform: scale((1.1));
}
.new-information {
  display: flex;
  justify-content: space-between;
}

.new-head-info h3 {
  font-size: 24px;
  font-family: "Cormorant", sans-serif;
  font-weight: 500;
  margin: 10px 0;
  max-width: 290px;
  color: #000000;
  white-space: nowrap; /* Prevent text wrapping */
  overflow: hidden; /* Hide the overflowing text */
  text-overflow: ellipsis; /* Add ellipses (...) for truncated text */
}

.new-head-info p {
  font-size: 18px;
  font-style: italic;
  color: #363636;
  font-family: "Lato", sans-serif;
  font-weight: 400;
  .original-price {
    text-decoration: line-through;
    color: #b0b0b0; // Grey color for the original price
    margin-right: 8px;
  }

  .sale-price {
    font-weight: bold;
  }
}

.new-head-price {
  font-size: 22px;
  margin: 10px 0;
  font-weight: 400;
  font-family: "Cormorant", sans-serif;
  font-style: italic;
  color: #363636;
}

.old-price {
  text-decoration: line-through;
  color: #999;
}

.new-price {
  color: #e74c3c;
  font-weight: bold;
}

.buy-now-btn {
  background-color: #000;
  color: #fff;
  border: none;
  padding: 10px;
  cursor: pointer;
  margin-top: 10px;
  width: 100%;
  font-size: 14px;
  font-family: "Lato", sans-serif;
  border-radius: 0%;
  text-transform: uppercase;
  letter-spacing: 0.025em;
}

.prev-btn,
.next-btn {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background: transparent;
  //   color: white;
  border: none;
  padding: 15px;
  cursor: pointer;
  z-index: 10;
}

.prev-btn {
  left: 0;
}

.next-btn {
  right: 0;
}
.new-product-carousel-mob {
  display: none;
}
.new-product-slider-mob {
  position: relative;
  width: 100%;
  margin: 0 5px;
  overflow: hidden;
  // border: 1px solid black;
}

.new-slider-wrapper-mob {
  width: 80%;
  overflow: hidden;
  margin: 0px auto;
}

.new-slider-content-mob {
  display: flex;
  transition: transform 0.3s ease-in-out;
  gap: 25px;
  overflow-x: auto; /* Allow horizontal scrolling */
  white-space: nowrap;
  scroll-behavior: smooth;
  -webkit-overflow-scrolling: touch; /* Optional, for momentum scrolling on iOS */
  -ms-overflow-style: none; /* IE and Edge */
}
.new-slider-content-mob::-webkit-scrollbar {
  display: none;
}
.new-product-item-mob {
  min-width: 80%;
  max-width: 80%;
  padding: 3px;
  box-sizing: border-box;
  border: 1px solid #eee;
}
.new-product-image-mob img {
  width: 100%;
  height: 300px;
  object-fit: cover;
}
.new-information-mob {
  display: flex;
  justify-content: space-between;
}
.new-head-info-mob h3 {
  font-size: 18px;
  font-family: "Cormorant", sans-serif;
  font-weight: 500;
  margin: 10px 0;
  color: #000000;
  max-width: 120px;
  white-space: nowrap; /* Prevent text wrapping */
  overflow: hidden; /* Hide the overflowing text */
  text-overflow: ellipsis; /* Add ellipses (...) for truncated text */
  text-transform: capitalize;
}

.new-head-info-mob p {
  font-size: 16px;
  font-style: italic;
  color: #363636;
  font-family: "Lato", sans-serif;
  .original-price {
    text-decoration: line-through;
    color: #b0b0b0; // Grey color for the original price
    margin-right: 8px;
    font-weight: 400;
  }
  .sale-price {
    font-weight: 500;
  }
}

.new-head-price-mob {
  font-size: 20px;
  margin: 10px 0;
  font-weight: 400;
  font-family: "Cormorant", sans-serif;
  font-style: italic;
  color: #363636;
}

.old-price-mob {
  text-decoration: line-through;
  color: #999;
}

.new-price-mob {
  color: #e74c3c;
  font-weight: bold;
}

.buy-now-btn-mob {
  background-color: #000;
  color: #fff;
  border: none;
  padding: 10px;
  cursor: pointer;
  margin-top: 10px;
  width: 100%;
  font-size: 10px;
  font-family: "Lato", sans-serif;
  border-radius: 0%;
}
@media (max-width:1024px) {
  .new-product-item {
    min-width: 28%;
    max-width: 28%;
  }
  .new-product-image {
    height: 350px;
  }
  .new-head-info h3{
    max-width: 200px;
  }
}
@media screen and (max-width: 1000px) {
  .new-product-carousel {
    display: none;
  }
  .new-product-carousel-mob {
    display: flex;
    align-items: flex-start;
    padding: 0;
  }
  .new-product-item-mob {
    min-width: auto;
    max-width: auto;
  }
  .new-slider-wrapper-mob {
    width: 90%;
  }
  .new-product-image-mob {
    width: 400px;
    height: 500px;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}
@media screen and (max-width: 500px) {
  .new-product-carousel {
    display: none;
  }
  .new-product-carousel-mob {
    display: flex;
    align-items: flex-start;
    padding: 0;
  }
  .new-product-image-mob {
    width: 255px;
    height: 350px;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}
@media screen and (max-width: 350px) {
  .new-slider-wrapper-mob {
    width: 100%;
    overflow: hidden;
    margin: 0px 15px;
  }
  .new-head-info-mob {
    h3 {
      font-size: 22px;
    }
    p {
      font-size: 15px;
    }
  }
  .new-head-price-mob {
    font-size: 20px;
  }
}
@media (min-width: 350px) and (max-width: 400px) {
  .new-slider-wrapper-mob {
    width: 100%;
    overflow: hidden;
    margin: 0px 10px;
  }
  .new-product-item-mob {
    min-width:  45%;
    max-width: 45%;
  }
  .new-slider-content-mob{
    gap: 20px;
  }
  .new-product-image-mob {
    width: 100%;
    height: 200px;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}
@media (min-width: 400px) and (max-width: 480px) {
  .new-slider-wrapper-mob {
    width: 100%;
    overflow: hidden;
    margin: 0px 20px;
  }
  .new-slider-content-mob{
    gap: 20px;
  }
  .new-product-item-mob {
    min-width: 45%;
    max-width: 45%;
  }
  .new-product-image-mob {
    width: 100%;
    height: 200px;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}
@media screen and (max-width: 992px) and (orientation: landscape) {
  .head-new-part {
    h2 {
      font-size: 30px;
    }
  }
  .new-slider-content-mob{
    gap: 15px;
  }
  .new-product-image-mob {
    width: 200px;
    height: 250px;
}
}