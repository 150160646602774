.intro-faq-container {
  background-color: #e9e9e9;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 30px 80px;
}

.intro-faq-container .heading-faq {
  padding: 0;
  width: 100%;
}

.heading-faq h2 {
  font-family: "Lato", sans-serif;
  font-size: 18px;
  font-weight: 400;
  line-height: 25px;
  text-align: left;
  color: #595959;
  margin-bottom: 0;
  text-align: center;
}

.faq-container {
  padding: 50px 80px;
  max-width: 1200px;
  margin: 0 auto;
}

.faq-container h3 {
  font-family: Cormorant;
  font-size: 32px;
  font-weight: 500;
  line-height: 38.75px;
  letter-spacing: 0.005em;
  text-align: center;
}

.faq-container .accordion {
  padding: 40px 20px 0 20px;
}

.accordion {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
}

.accordion h5 {
  font-family: Cormorant;
  font-size: 28px;
  font-weight: 500;
  letter-spacing: 0.01em;
  text-align: left;
  margin-bottom: 0px;
}

.accordion__item {
  border-bottom: 0.5px solid #c9c9c9;
  overflow: hidden;
  padding-bottom: 5px;
}

.accordion__header {
  padding: 20px 25px;
  cursor: pointer;
  position: relative;
  font-family: Lato;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0.01em;
  text-align: left;
  color: #595959;
}

.accordion__header::after {
  content: "";
  background: url(https://www.svgrepo.com/show/357035/angle-down.svg) no-repeat
    center;
  width: 20px;
  height: 20px;
  transition: 0.4s;
  display: inline-block;
  position: absolute;
  right: 20px;
  top: 20px;
  z-index: 1;
}

.accordion__header.active {
  // background: #e5f3fa;
}

.accordion__header.active::after {
  transform: rotateX(180deg);
}

.accordion__item .accordion__content {
  padding: 0 35px;
  max-height: 0;
  transition: 0.5s;
  overflow: hidden;
  font-family: Lato;
  font-size: 16px;
  font-weight: 400;
  line-height: 30px;
  letter-spacing: 0.01em;
  text-align: left;
  color: #595959;
}
.accordion__content {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.3s ease;
}
.accordion__content.active {
  max-height: 500px; /* A large enough value */
}

.accordion__header span {
  margin-right: 10px;
}

@media screen and (max-width: 480px) {
  .faq-container {
    padding: 10px;
    margin-bottom: 70px;
  }
  .faq-container h3{
    margin-top: 30px;
    margin-bottom: 30px;
  }
  .accordion h5{
    margin-top: 20px;
    margin-bottom: 20px;
  }
  .faq-container .accordion {
    padding: 0px;
  }
  .accordion__content p{
     font-size: 0.9rem;
  }
  .accordion__header{
    padding-right: 50px;
  }
}
