.head-col-part {
  h2 {
    text-align: center;
    font-weight: 500;
    font-size: 40px;
    text-transform: uppercase;
    font-family: "Cormorant", sans-serif;
    margin-top: 100px;
  }
  @media screen and (max-width: 480px) {
    h2 {
      font-size: 20px;
      margin-top: 30px;
    }
  }
}

.grid-container {
  display: grid;
  grid-template-columns: 2fr 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  gap: 10px;
  // width: 1330px;
  // width: 100%;
  padding: 0 60px;
  margin: 50px auto 80px auto;
  // border: 1px solid black;
}

.grid-item {
  position: relative;
  overflow: hidden;
  cursor: pointer;
}

.grid-item {
  overflow: hidden;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: transform 0.3s ease;
  }
}
.grid-item:hover img {
  transform: scale(1.1);
}
.grid-item .overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.2);
  color: white;
  font-size: 22px;
  font-weight: 500;
  text-transform: uppercase;
  font-family: "Cormorant", sans-serif;
}

/* Large item that spans two rows */
.grid-item-large {
  grid-row: span 2;
}

.collection-mob-slide {
  display: none;
  gap: 25px;
  padding: 5px;
  overflow-x: auto; /* Allow horizontal scrolling */
  white-space: nowrap; /* Prevent line breaks for child elements */
  width: 100%;
  scroll-behavior: smooth; /* Smooth scrolling effect */
  padding-bottom: 20px;
  -webkit-overflow-scrolling: touch; /* Optional, for momentum scrolling on iOS */
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  .collection-mob-slide::-webkit-scrollbar {
    display: none; /* Hide scrollbar in Chrome, Safari, WebKit */
  }
  .grid-item-mob {
    width: 250px;
    height: 200px;
    position: relative;
    display: inline-block;

    img {
      width: 250px;
      height: 200px;
    }
    .overlay-mob {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: rgba(0, 0, 0, 0.2);
      color: white;
      font-size: 12px;
      font-weight: 300;
      text-transform: uppercase;
      font-family: "Cormorant", sans-serif;
    }
  }
}
@media screen and (max-width: 500px) {
  // .grid-container {
  //   display: none;
  // }
  // .collection-mob-slide {
  //   display: flex;
  // }
}
@media screen and (max-width: 992px) and (orientation: landscape) {
  .head-col-part {
    h2 {
      font-size: 30px;
    }
  }
  .grid-item .overlay {
    font-size: 16px;
  }
  .collection-mob-slide {
    margin-bottom: 80px;
  }
}
@media (max-width:768px) {
  .grid-container {
    display: none;
  }
  .collection-mob-slide {
    display: flex;
  }
}
