.loading-component {
  border: 1px solid black;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  img {
    width: 30%;
    height: auto;
  }
}
@media (max-width: 576px) {
  .loading-component {
    img {
      width: 40%;
      height: auto;
    }
  }
}
