.television-page-container {
    width: 100%;
  p {
    font-family: Lato;
    font-size: 10px;
    font-weight: 400;
    color: #595959;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
  }
  h2 {
    font-family: Cormorant;
    font-size: 28px;
    font-weight: 500;
    // line-height: 33.91px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: #000000;
  }
  .youtube-image-television{
    img{
        width: 100%;
        height: auto;
        object-fit: cover;
    }
  }
}
@media (max-width:768px) {
    .television-page-container{
        margin-top: 40px;
        p {
            font-size: 12px;
          }
          h2 {
            font-size: 20px;
          }
    }
}