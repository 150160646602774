.profile-edit-page-container {
  width: 100%;
}
.profile-edit-main-content {
  border: 1px solid #595959;
  padding: 0px 18px;
  width: 100%;
  padding-bottom: 25px;
}
.edit-two-input-box {
  display: flex;
  gap: 30px;
  justify-content: space-between;
  width: 100%;
  position: relative;
  margin-bottom: 15px;
  .default-india-code-profile {
    position: absolute;
    color: #595959;
    font-size: 18px;
    z-index: 20;
    top: 20.5px;
    margin-right: 20px;
    left: 20px;
  }
  .form-field-state-field-edit {
    width: 100%;
    .error {
      color: red;
    }
    .form-field-input-state {
      margin-top: 8px;
      // margin-bottom: 8px;
      border: 1px solid #c9c9c9;
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 5px 20px;
      height: 44px;
      position: relative;
      svg {
        cursor: pointer;
      }
      input {
        outline: none;
        border: none;
        font-family: Lato;
        font-size: 18px;
        font-weight: 500;
        color: #595959;
      }
      input::placeholder {
        font-family: Lato;
        font-size: 18px;
        font-weight: 500;
        color: #595959;
      }
      svg {
        color: #595959;
      }
    }
  }
}
.label-and-input-box-edit {
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;
  .error {
    color: red;
  }
}
.label-and-input-box-edit input[name="mobileNumber"] {
  padding-left: 60px; /* Increase padding to make space for the code */
  // border: 1px solid #c9c9c9;
  // height: 44px;
  // margin-top: 8px;
  // padding: 0px 20px;
  // font-size: 16px;
  // margin-bottom: 15px;
}
.label-and-input-box-edit .password-edit-icon,
.newpassword-edit-icon,
.confirmpassword-edit-icon {
  position: absolute;
  right: 20px;
  top: 23px;
  // left: 300px;
  cursor: pointer;
  color: #595959;
  font-size: 20px;
}

.label-and-input-box-edit label {
  font-family: Lato;
  font-size: 18px;
  font-weight: 500;
  color: #595959;
  margin-top: 20px;
}
.label-and-input-box-edit input {
  border: 1px solid #c9c9c9;
  color: #595959;
  font-size: 18px;
  height: 48px;
  margin-top: 10px;
  width: 100%;
  padding: 0 20px;
  // margin-bottom: 15px;
}
.Address-input {
  width: 68% !important;
}
.city-input {
  width: 32% !important;
}
.profile-edit-buttons {
  margin-top: 30px;
  display: flex;
  gap: 30px;
}
.cancel-button,
.save-button {
  border: 1px solid #000000;
  font-family: Lato;
  font-size: 14px;
  font-weight: 400;
  color: #000000;
  letter-spacing: 1px;
  padding: 10px 0px;
  background-color: transparent;
  width: 37%;
  border-radius: 0px;
}
.save-button {
  background: #000000;
  border-style: none;
  color: #ffffff;
}
@media (max-width: 600px) {
  .profile-edit-page-container {
    margin-top: 30px;
  }
  .profile-edit-main-content {
    padding: 30px 20px;
    .state-field {
      height: 48px !important;
    }
    .country-field {
      margin-top: 18px !important;
    }
  }
  .edit-two-input-box {
    flex-direction: column;
    gap: 0px;
  }
  .Address-input {
    width: 100% !important;
  }
  .city-input {
    width: 100% !important;
  }
  .label-and-input-box-edit .password-edit-icon,
  .newpassword-edit-icon,
  .confirmpassword-edit-icon {
    left: 300px;
  }
}
