.all-cards-container {
  display: grid;
  grid-template-columns: repeat(4, 1fr); /* 4 fixed columns */
  gap: 20px;
  padding: 0;
  margin-top: 15px;
  // margin-bottom: 25px;
  // border: 1px solid black;
  .product-card-link {
    text-decoration: none; /* Remove underline */
    color: inherit; /* Inherit color from parent */
  }

  .product-card-link:hover {
    text-decoration: none; /* Ensure no underline on hover */
  }
}
.three-columns {
  .all-cards-container {
    grid-template-columns: repeat(3, 1fr);
    gap: 40px;
  }
  .title-price h6 {
    text-align: center;
    font-family: Cormorant;
    font-size: 18px;
    font-weight: 500;
    margin: 0px;
    color: #0c0c0c;
    margin-top: 10px;
    // max-width: 207px;
    white-space: nowrap; /* Prevent text wrapping */
    overflow: hidden; /* Hide the overflowing text */
    text-overflow: ellipsis; /* Add ellipses (...) for truncated text */
  }
  @media (min-width: 700px) and (max-width: 1000px) {
    .all-cards-container {
      grid-template-columns: repeat(3, 1fr);
      gap: 20px;
    }
    .title-price h6 {
      font-size: 18px;
    }
  }
  @media (max-width: 700px) {
    .all-cards-container {
      grid-template-columns: auto auto;
      justify-content: center;
      gap: 20px;
    }
  }
}
.four-columns {
  .all-cards-container {
    grid-template-columns: repeat(4, 1fr);
  }
  .title-price h6 {
    text-align: center;
    font-family: Cormorant;
    font-size: 18px;
    font-weight: 500;
    margin: 0px;
    color: #0c0c0c;
    margin-top: 10px;
    max-width: 207px;
    white-space: nowrap; /* Prevent text wrapping */
    overflow: hidden; /* Hide the overflowing text */
    text-overflow: ellipsis; /* Add ellipses (...) for truncated text */
  }
  @media (min-width: 700px) and (max-width: 1000px) {
    .all-cards-container {
      grid-template-columns: repeat(3, 1fr);
    }
  }
  @media (max-width: 700px) {
    .all-cards-container {
      grid-template-columns: auto auto;
      justify-content: center;
    }
  }
}
// .abstract-all-cards.four-columns {
//   width: 100%;
//   display: grid;
//   grid-template-columns: repeat(4, 1fr);
// }
// /* Layout for grey box clicked: 3 columns */
// .abstract-all-cards.three-columns {
//   width: 100%;
//   display: grid;
//   grid-template-columns: repeat(3, 1fr);
// }
.main-cards {
  // box-shadow: 0px 3.26px 12.21px 0px #b4b4b440;
  // background: #f8f9fa;
  position: relative;
  transition: transform 0.3s ease;
  cursor: pointer;
  margin-bottom: 15px;
  border: 1px solid #eeeeee;
}
.main-cards:hover {
  box-shadow: 0px 3.26px 12.21px 0px #b4b4b440;
  background: #f8f9fa;
  // padding: 5px;
}
.card-image-container {
  position: relative;
  min-width: 40%;
  overflow: hidden;
  .product-card-tag {
    position: absolute;
    top: 20px;
    right: 0;
    background: #fff;
    color: #000;
    font-family: "Cormorant", sans-serif;
    font-size: 15.46px;
    font-weight: 500;
    text-align: center;
    font-style: italic;
    text-transform: capitalize;
    width: 121px;
    height: 22.5px;
  }
}
.card-image-container img {
  width: 100%;
  height: 300px;
  object-fit: cover;
  display: block;
  transition: opacity 0.5s ease-in-out;
}
.view-similar,
.add-to-cart-btn {
  position: absolute;
  width: 100%;
  // padding: 10px 15px;
  background: #ffffff;
  color: #000000;
  border: none;
  cursor: pointer;
  opacity: 0;
  // transition: opacity 0.3s ease;
  // border: 0.6px solid #000000;
  font-family: "Lato", sans-serif;
  border-radius: 0px;
  font-size: 11px;
  font-weight: 400;
  text-align: center;
  bottom: 10px;
  // right: 5%; /* Start from outside the view */
  transition: right 0.5s ease, opacity 0.5s ease;
}
.view-similar {
  bottom: 23px;
}
.add-to-cart-btn {
  bottom: -10px;
  background: #000000;
  color: #ffffff;
  &:disabled {
    background-color: #595959;
    color: #fff;
    cursor: not-allowed; /* Change cursor to indicate disabled */
  }
}
.main-cards:hover .view-similar,
.main-cards:hover .add-to-cart-btn {
  opacity: 1;
  transform: translateY(-10px); /* Slide up effect */
}

.main-cards:hover .view-similar {
  transition-delay: 0.2s;
}

.main-cards:hover .add-to-cart-btn {
  transition-delay: 0.2s;
}
.view-top-right {
  position: absolute;
  padding: 5px 15px;
  background: #ffffff;
  color: #000000;
  border: none;
  cursor: pointer;
  font-family: Cormorant;
  font-size: 15.46px;
  font-style: italic;
  font-weight: 500;
  top: 20px;
  right: 0px;
}
.main-cards:hover .view-similar {
  opacity: 1; /* Show buttons when hovering */
}
.title-price {
  padding: 5px;
  .size-card {
    margin: 5px 0;
    font-family: "Lato", sans-serif;
    font-size: 14px;
    font-weight: 700;
    text-align: center;
    color: #595959;
  }
}

.title-price p {
  font-family: "Lato", sans-serif;
  font-size: 18px;
  // font-style: italic;
  font-weight: 400;
  margin: 0px;
  color: #363636;
  text-align: center;
}
.card-buttons {
  display: none;
}
.card-buttons button {
  padding: 10px;
  width: 100%;
  height: auto;
  margin-top: 10px;
  border-radius: 0%;
}
.main-cards:hover .card-buttons {
  display: block;
}
.add-cart {
  border: 0.65px solid #000000;
  font-family: Lato;
  font-size: 10.58px;
  font-weight: 400;
  text-align: center;
  color: #000000;
  background-color: #ffffff;
}
.buy-now {
  border: 0.66px solid #000000;
  font-family: Lato;
  font-size: 10.58px;
  font-weight: 400;
  letter-spacing: 0.025em;
  text-align: center;
  color: #fbf9f9;
  background-color: #000000;
  margin-top: 6px;
}
@media (max-width:1024px) {
  .colors-box{
    display: none;
  }
  .four-columns .all-cards-container{
    grid-template-columns: repeat(3, 1fr);
  }
}
@media (max-width: 1023px) {
  .title-price h6 {
    font-size: 20px;
  }
}
/* Media Queries */
@media (max-width: 992px) {
  .view-similar,
  .view-top-right {
    padding: 5px;
    font-size: 10px;
  }
  .title-price h6 {
    font-size: 18px;
  }
  // .card-image-container img {
  //   width: 220px;
  //   height: 300px;
  //   display: block;
  // }
  // .main-cards{
  //   width: 220px;
  // }
}
@media (min-width: 700px) and (max-width: 1000px) {
  .abstract-page-container {
    padding: 20px;
  }
  .all-cards-container {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media (max-width: 700px) {
  .all-cards-container {
    grid-template-columns: auto auto;
    justify-content: center;
  }
  .main-cards {
    width: 200px;
  }
  .card-image-container img {
    width: 200px;
    height: 250px;
    display: block;
  }
  .four-columns .all-cards-container{
    grid-template-columns: repeat(2, 1fr);
  }
}
@media (max-width: 576px) {
  .card-image-container img {
    width: 180px;
  }
  .main-cards {
    width: 180px;
    margin-bottom: 0;
  }
  .old-price-card {
    font-size: 12px;
  }
  .new-price-card {
    font-weight: 600;
  }
  .four-columns .all-cards-container{
    grid-template-columns: repeat(2, 1fr);
  }
}
@media (max-width: 400px) {
  .card-image-container img {
    width: 165px;
    height: 200px;
  }
  .main-cards {
    width: 160px;
  }
  .four-columns .all-cards-container{
    grid-template-columns: repeat(2, 1fr);
  }
}
@media screen and (max-width: 700px) and (orientation: landscape) {
  .all-cards-container {
    gap: 20px;
  }
  .main-cards {
    width: 295px;
  }
  .card-image-container img {
    width: 295px;
    height: 280px;
    display: block;
  }
}
