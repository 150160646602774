.intro-store-container {
  background-color: #e9e9e9;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 30px 80px;
}

.intro-store-container .heading-store {
  padding: 0;
  width: 100%;
}

.heading-store h2 {
  font-family: Lato;
  font-size: 18px;
  font-weight: 400;
  line-height: 25px;
  text-align: center;
  color: #595959;
  margin-bottom: 0;
}

.contact-section-mobile {
  display: none;
}

.map-content {
  padding: 50px;
  display: flex;
  gap: 1rem;
}

.location-search-container {
  text-align: center;
  margin: 20px;
}

h2 {
  margin-bottom: 20px;
  font-family: Cormorant;
  font-size: 28px;
  font-weight: 500;
  line-height: 25.01px;
  text-align: center;
}

.search-bar {
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 2px solid #333;
  padding-bottom: 5px;
  margin-bottom: 10px;
}

#locationInput::placeholder {
  font-family: "Lato", serif;
  font-size: 16px;
  color: #595959;
}

.search-bar input {
  border: none;
  outline: none;
  padding: 10px;
  font-size: 16px;
  width: 300px;
}

.search-bar button {
  background: none;
  border: none;
  cursor: pointer;
}

.search-bar img {
  width: 24px;
  height: 24px;
}

.or-text {
  margin: 15px 0;
  font-size: 18px;
  color: #888;
}
.location-display-container {
  // border: 1px solid black;
  // padding: 0 20px;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  height: 450px;
  width: 350px;
  overflow-y: auto;
  .location-display-list {
    .location-display-item {
      padding: 20px 0;
      display: flex;
      flex-direction: column;
      justify-content: center;
      gap: 20px;
      // border: 1px solid black;
      border-bottom: 1px solid #bbbbbb;
      .location-display-address {
        .store-locator-name {
          margin-bottom: 10px;

          span {
            font-weight: 600;
          }
        }
        span {
          // margin-bottom: 20px;
          font-family: "Lato", sans-serif;
          font-size: 18px;
          font-weight: 500;
          color: #595959;
          text-transform: capitalize;
        }
      }
      .location-display-maplink {
        display: flex;
        align-items: center;
        cursor: pointer;
        width: 100px;
        // border: 1px solid black;
        .location-direction-title {
          font-family: "Lato", sans-serif;
          font-size: 15px;
          font-weight: 500;
          margin-right: 10px;
          color: #595959;
        }
        .location-direction {
          svg {
            // color: #595959;
            width: 20px;
            height: 20px;
            margin-top: 5px;
          }
        }
      }
    }
  }
}
.store-locator-pop {
  .store-locator-maplink {
    margin-top: 5px;
    display: flex;
    align-items: center;
    gap: 5px;
    cursor: pointer;
    width: 80px;
    .store-locator-direction {
      svg {
        width: 18px;
        height: 18px;
        margin-top: 5px;
      }
    }
  }
}
.detect-location-btn {
  background-color: #000;
  color: #fff;
  border: none;
  border-radius: 0px;
  padding: 15px 40px;
  cursor: pointer;
  font-family: Lato;
  font-size: 12px;
  font-weight: 400;
  line-height: 14.4px;
  letter-spacing: 1px;
}

#output {
  margin-top: 20px;
  font-size: 18px;
  color: #555;
}

.map-container {
  width: 100%;
  max-width: 100%;
  z-index: 0;
}

iframe {
  width: 100%;
  height: 600px; /* You can adjust the height as needed */
}

.contact-section {
  padding: 50px;
  margin-bottom: 40px;
}

.contact-section h2 {
  font-family: Cormorant;
  font-size: 40px;
  font-weight: 500;
  // line-height: 44px;
  text-align: center;
  text-transform: uppercase;
}

.contact-content {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.Address h2 {
  font-family: Cormorant;
  font-size: 24px;
  font-weight: 500;
  text-align: center;
  text-transform: none;
  margin-top: 25px;
}

.Address p {
  font-family: Lato;
  font-size: 16px;
  font-weight: 400;
  text-align: center;
  color: #595959;
}

@media screen and (max-width: 820px) {
  .map-content {
    flex-direction: column;
  }
}

@media screen and (max-width: 480px) {
  .map-content {
    padding: 20px;
    margin-bottom: 50px;
  }

  .contact-section-mobile {
    display: block;
  }

  .contact-section-mobile {
    padding: 20px;
  }

  .contact-content {
    flex-direction: column;
  }
}
