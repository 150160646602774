.intro-track-container {
  background-color: #e9e9e9;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 30px 80px;
}
.intro-track-container .heading-track {
  padding: 0;
  width: 100%;
}
.heading-track h2 {
  font-family: Lato;
  font-size: 18px;
  font-weight: 400;
  line-height: 25px;
  text-align: center;
  color: #595959;
  margin-bottom: 0;
}
.track-order-container {
  width: 65%;
  padding: 80px;
  p {
    margin-bottom: 20px;
    font-family: "Cormorant", serif;
    font-size: 20px;
    font-weight: 400;
    font-style: italic;
  }
  h1 {
    margin-bottom: 10px;
    font-family: "Cormorant", serif;
    font-weight: 500;
    font-size: 36px;
    text-transform: uppercase;
  }
}
.track-order-form {
  display: flex;
  flex-direction: column;
  gap: 15px;
  button {
    width: 30%;
    padding: 15px 20px;
    font-size: 1rem;
    background-color: #000;
    color: #fff;
    border: none;
    cursor: pointer;
    border-radius: 5px;
    font-family: "Lato", serif;
    margin-top: 20px;
    border-radius: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  button:hover {
    background-color: #444;
  }
}
.form-group {
  text-align: left;
  input {
    width: 100%;
    padding: 15px;
    font-size: 1rem;
    border: 1px solid #ccc;
    border-radius: 5px;
  }

  input::placeholder {
    font-size: 0.95rem;
    color: #999;
    padding-left: 5px;
    font-family: "Lato", serif;
  }
  label {
    font-size: 1rem;
    display: block;
    margin-bottom: 5px;
  }
}
.order-status-container {
  padding: 50px 80px;
  .order-id {
    h4 {
      font-family: "Cormorant", sans-serif;
      font-weight: 500;
      font-size: 32px;
      line-height: 38.75px;
      color: #0c0c0c;
    }
    p {
      margin-top: 5px;
      font-family: "Lato", sans-serif;
      font-weight: 400;
      font-size: 20px;
      color: #595959;
      line-height: 24px;
    }
  }
  .order-tracking-status {
    display: flex;
    justify-content: center;
    .order-status-icon-container {
      display: flex;
      justify-content: center;
      gap: 100px;
      padding: 60px 0;
      position: relative;
      // margin: 0 230px;
      // gap: 250px;

      .order-status-item {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        position: relative;

        width: 250px;
        .order-status-icon {
          border-radius: 100px;
          background-color: #a3a3a3;
          width: 100px;
          height: 100px;
          display: flex;
          justify-content: center;
          align-items: center;
        }

        .order-status-title {
          margin-top: 10px;
          p {
            font-family: "Cormorant", sans-serif;
            font-weight: 500;
            font-size: 34px;
            color: #000;
          }
        }
        &.active {
          .order-status-icon {
            background-color: #0c0c0c; /* Green for active status */
            // transform: scale(1.1); /* Slightly enlarges icon */
          }

          &::before {
            background-color: #0c0c0c; /* Connect line active color */
          }
        }
      }
      .order-status-item::before {
        content: "";
        display: block;
        height: 3px;
        width: calc(100%);
        background-color: #dddddd;
        top: 50px;
        position: absolute;
        left: calc(-50% - 50px);
        z-index: 0;
        // transition: background-color 0.3s ease; /* Smooth transition */
      }

      // .order-status-item.active::before {
      //   background-color: #e21e1e; /* Active color */
      // }

      .order-status-item:first-child:before {
        display: none;
      }
    }
  }
  .order-status-log-container {
    .order-status-log-list {
      display: flex;
      flex-direction: column;
      gap: 30px;
      position: relative;
      .order-status-log-item {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        gap: 20px;
        // border: 1px solid black;
        height: 60px;
        .order-status-log-checkbox {
          position: relative;
          border-radius: 100px;
          border: 1px solid black;
          width: 25px;
          height: 25px;
          display: flex;
          justify-content: center;
          align-items: center;
          .order-status-log-circle {
            border-radius: 100px;
            background-color: #bcbcbc;
            width: 18px;
            height: 18px;
          }
        }
        &:not(:first-child) .order-status-log-checkbox::before {
          content: "";
          display: block;
          position: absolute;
          width: 2px;
          left: 11px; /* relative to marker width */
          bottom: 25px; /* offset top */
          height: 80px; /* responsive heights */
          margin: auto;
          border-left: 2px dashed #bcbcbc;
          z-index: -1; /* handle stacking context */
        }
        .order-status-log-info {
          // border: 1px solid black;
          .order-status-log-title {
            p {
              font-family: "Cormorant", sans-serif;
              font-size: 28px;
              font-weight: 500;
              line-height: 33.1px;
            }
          }
          .order-status-log-date {
            p {
              font-family: "Lato", sans-serif;
              font-size: 20px;
              font-weight: 300;
              line-height: 24px;
              color: #595959;
            }
          }
        }
        &.active {
          .order-status-log-checkbox {
            .order-status-log-circle {
              background-color: #0c0c0c; /* Green for active status */
              // transform: scale(1.1); /* Slightly enlarges icon */
            }
          }
          &:not(:first-child) .order-status-log-checkbox::before {
            border-left: 2px dashed #0c0c0c;
          }
        }
      }
    }
  }
}
/* Responsive */
@media (max-width: 1240px) {
  .order-status-container {
    padding: 50px 30px;

    .order-id {
      h4 {
        font-size: 28px;
      }

      p {
        font-size: 20px;
        margin-top: 0px;
      }
    }

    .order-tracking-status {
      .order-status-icon-container {
        gap: 100px;

        .order-status-item {
          width: 200px;

          .order-status-icon {
            width: 85px;
            height: 85px;

            svg {
              width: 45px;
              height: 45px;
            }
          }

          .order-status-title {
            p {
              font-size: 22px;
            }
          }

          &::before {
            top: 40px;
            left: calc(-50% - 58px);
            height: 3px;
            width: 108%;
          }
        }
      }
    }

    .order-status-log-container {
      .order-status-log-list {
        .order-status-log-item {
          .order-status-log-info {
            .order-status-log-title {
              p {
                font-size: 24px;
              }
            }

            .order-status-log-date {
              p {
                font-size: 18px;
              }
            }
          }
        }
      }
    }
  }
}
@media (max-width: 768px) {
  h1 {
    font-size: 1.5rem;
  }

  p {
    font-size: 0.9rem;
  }

  input,
  button {
    font-size: 0.9rem;
  }
  .track-order-container {
    width: 100%;
  }
}
@media (max-width: 700px) {
  .order-status-container {
    padding: 50px 30px;

    .order-id {
      h4 {
        font-size: 28px;
      }

      p {
        font-size: 20px;
        margin-top: 0px;
      }
    }

    .order-tracking-status {
      .order-status-icon-container {
        gap: 100px;

        .order-status-item {
          width: 170px;

          .order-status-icon {
            width: 65px;
            height: 65px;

            svg {
              width: 35px;
              height: 35px;
            }
          }

          .order-status-title {
            p {
              font-size: 22px;
            }
          }

          &::before {
            top: 30px;
            left: calc(-60% - 50px);
            height: 3px;
            width: 120%;
          }
        }
      }
    }

    .order-status-log-container {
      .order-status-log-list {
        .order-status-log-item {
          .order-status-log-info {
            .order-status-log-title {
              p {
                font-size: 24px;
              }
            }

            .order-status-log-date {
              p {
                font-size: 18px;
              }
            }
          }
        }
      }
    }
  }
}
@media (max-width: 480px) {
  .track-order-container {
    width: 100%;
    padding: 50px 40px;
  }
  .track-order-container h1 {
    font-size: 28px;
  }
  .track-order-form {
    gap: 0px;
    button{
      width: 35%;
      padding: 12px 15px;
    }
    .form-group {
      margin-bottom: 10px;
    }
  }
  h1 {
    font-size: 1.5rem;
  }
  p {
    font-size: 1.2rem;
  }
  input,
  button {
    font-size: 0.8rem;
  }
  .order-status-container {
    padding: 50px 15px;

    .order-id {
      h4 {
        font-size: 20px;
      }

      p {
        font-size: 12px;
        margin-top: 0px;
      }
    }

    .order-tracking-status {
      .order-status-icon-container {
        gap: 60px;

        .order-status-item {
          width: 92px;

          .order-status-icon {
            width: 50px;
            height: 50px;

            svg {
              width: 25px;
              height: 25px;
            }
          }

          .order-status-title {
            p {
              font-size: 14px;
            }
          }

          &::before {
            top: 25px;
            left: calc(-50% - 35.5px);
            height: 2px;
            width: 112%;
          }
        }
      }
    }

    .order-status-log-container {
      .order-status-log-list {
        .order-status-log-item {
          .order-status-log-info {
            .order-status-log-title {
              p {
                font-size: 20px;
              }
            }

            .order-status-log-date {
              p {
                font-size: 14px;
              }
            }
          }
        }
      }
    }
  }
}
