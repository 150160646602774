@import url("https://fonts.googleapis.com/css2?family=Cormorant:ital,wght@0,300..700;1,300..700&family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap");

.intro-container {
  height: 20vh;
  background-color: #e9e9e9;
  display: flex;
  justify-content: center;
  align-items: center;
}

.intro-container .heading {
  width: 90%;
}

.heading h2 {
  font-family: Lato;
  font-size: 18px;
  font-weight: 400;
  line-height: 25px;
  text-align: center;
  color: #595959;
}

.blog-content-container {
  padding: 50px;
  display: flex;
  gap: 3rem;
}

.content-container-1 {
  width: 65%;
}

.blog-image-box {
  width: 100%;
  position: relative;
}

.blog-image-box p {
  position: absolute;
  padding: 10px 45px;
  top: 0;
  right: 0;
  background-color: #fff;
  font-family: Cormorant;
  font-size: 16px;
  font-style: italic;
  font-weight: 400;
  line-height: 19.38px;
  text-align: left;
}

.blog-image-box img {
  width: 100%;
}

/* .blog-text .blog-content-img{
    max-width: 750px;
} */

.blog-content-img img {
  width: 100%;
}

.blog-text h6 {
  font-family: Lato;
  font-size: 16px;
  font-weight: 400;
  line-height: 19.2px;
  text-align: left;
  color: #595959;
  margin: 5px 0px;
}

.blog-text h3 {
  font-family: Cormorant;
  font-size: 42px;
  font-weight: 500;
  line-height: 53.28px;
  text-align: left;
  color: #000;
  margin: 20px 0px;
}

.blog-text h5 {
  font-family: Cormorant;
  font-size: 25px;
  font-weight: 500;
  line-height: 53.28px;
  text-align: left;
  color: #000;
  margin: 6px 0px;
}

.blog-text p {
  font-family: Lato;
  font-size: 18px;
  font-weight: 400;
  line-height: 25px;
  letter-spacing: 0.5px;
  text-align: left;
  color: #595959;
  margin: 10px 0px;
}

.content-container-2 {
  width: 33%;
}

.content-container-2 h2 {
  font-family: Cormorant;
  font-size: 22px;
  font-weight: 500;
  line-height: 26.64px;
  text-align: left;
  color: #000;
  margin: 0px;
  padding-bottom: 10px;
}

.recent-post-box {
  display: flex;
  justify-content: center;
  gap: 1.5rem;
  margin-bottom: 30px;
}

.recent-post-box .recent-img {
  max-width: 200px;
}

.recent-img img {
  width: 100%;
}

.recent-content h4 {
  font-family: Cormorant;
  font-size: 20px;
  font-weight: 500;
  line-height: 19.38px;
  text-align: left;
  color: #000;
  margin: 0px;
}

.recent-content p {
  font-family: Lato;
  font-size: 12.8px;
  font-weight: 500;
  line-height: 25px;
  text-align: left;
  color: #595959;
}

@media screen and (max-width: 820px) {
  .blog-content-container {
    flex-direction: column;
  }

  .content-container-1,
  .content-container-2 {
    width: 100%;
  }
}

@media screen and (max-width: 480px) {
  .blog-content-container {
    padding: 20px;
  }
  .intro-container{
    height: 10vh;
  }
  .heading h2 {
    margin-top: 15px;
  }
  .blog-text h3 {
    font-size: 30px !important;
    line-height: normal !important;
}
}
