.profile-address-page-container {
  width: 100%;
}
.main-content-of-profile-address {
  display: flex;
  flex-direction: column;
  gap: 25px;
}
.profile-address-main-details-show {
  border: 1px solid #c9c9c9;
  padding: 15px 10px;
  width: 80%;
}
.profile-address-main-details-show h6 {
  font-family: Lato;
  font-size: 18px;
  font-weight: 600;
  color: #0c0c0c;
  margin: 0px;
}
.profile-address-main-details-show p {
  font-family: Lato;
  font-size: 14px;
  font-weight: 300;
  color: #303030;
  margin: 0px;
  margin-top: 5px;
  width: 60%;
}
.profile-address-number-button {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  // margin-top: -15px;
  padding-top: 0px;
  padding-bottom: -10px;
}
.profile-address-number-button button {
  background-color: transparent;
  border-style: none;
  font-family: Cormorant;
  font-size: 20px;
  font-weight: 500;
  color: #595959;
  text-decoration: underline;
}
@media (max-width: 768px) {
  .profile-address-page-container {
    margin-top: 40px;
  }
  .profile-address-main-details-show {
    width: 100%;
  }
  .profile-address-main-details-show h6 {
    font-size: 14px;
  }
  .profile-address-main-details-show p {
    width: 80%;
  }
  .profile-address-number-button button {
    font-size: 12px;
  }
}
