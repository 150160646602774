.intro-QS-container {
  // height: 20vh;
  background-color: #e9e9e9;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 30px 80px;
}

.intro-QS-container .heading-QS {
  // border: 1px solid black;
  padding: 0;
  width: 100%;
}
.heading-QS h2 {
  font-family: "Lato", sans-serif;
  font-size: 18px;
  font-weight: 400;
  line-height: 25px;
  text-align: center;
  color: #595959;
  margin-bottom: 0;
}
.new-qs-container {
  padding: 50px 80px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 2rem;
  // margin-bottom: 40px;
}
.qs-intro {
  width: 65%;
  text-align: center;
}
.qs-intro h2 {
  font-family: Cormorant;
  font-size: 52px;
  font-weight: 400;
  line-height: 30px;
  letter-spacing: 0.01em;
  color: #000;
}

.qs-intro p {
  font-family: Cormorant;
  font-size: 20px;
  font-weight: 500;
  line-height: 26px;
  letter-spacing: 0.01em;
}

.qs-icon-container {
  display: flex;
  justify-content: center;
  align-items: stretch;
  gap: 3rem;
  width: 90%;
  // border: 1px solid black;
}

.qs-icon-container .qs-icon-box {
  text-align: center;
  width: 22%;
  // border: 1px solid black;
}
.qs-icon{
  img{
    width: 55px;
    height: 55px;
  }
}
.qs-icon-box .icon-content h5 {
  margin-top: 20px;
  font-family: Cormorant;
  font-size: 20px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 0.01em;
  color: #0c0c0c;
}
.qs-icon-box .icon-content p {
  margin-top: 20px;
  font-family: "Lato", sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: 0.01em;
}

.image-text-container {
  display: flex;
  justify-content: space-between;
  // align-items: stretch;
  gap: 1rem;
  padding: 30px 50px;
  width: 100%;
  // margin-bottom: 50px;
  // border: 1px solid black;
  margin: 0 auto;
}

.image-text-container .content-QS {
  padding: 0px 25px 0px 25px;
  // border: 1px solid black;
  text-align: center;
}

.content-QS h4 {
  text-align: center;
  font-family: "Cormorant", sans-serif;
  font-size: 36px;
  font-weight: 600;
  letter-spacing: 0.01em;
  color: black;
  // margin: 10px 0px;
  text-transform: uppercase;
  margin-bottom: 20px;
}

.content-QS p {
  font-family: Lato;
  font-size: 22px;
  font-weight: 400;
  line-height: 30px;
  letter-spacing: 0.01em;
  text-align: center;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;  
  color: #595959;
  width: 100%;
}

.image-text-container .image {
  // border: 1px solid black;
  width: 145%;
  height: 255px;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.qs-container-3 {
  display: flex;
  justify-content: space-between;
  align-items: start;
  gap: 2rem;
  padding: 30px 50px;
}

.qs-card {
  width: 29%;
  overflow: hidden;
  position: relative;
}

.qs-card img {
  width: 100%;
}

.qs-card .card-content {
  position: absolute;
  bottom: 5%;
  left: 10%;
  padding: 0px;
}

.card-content h2 {
  font-family: Cormorant;
  font-size: 33.3px;
  font-weight: 600;
  line-height: 40.23px;
  text-align: left;
  margin: 10px 0px;
}

.card-content p {
  font-family: Lato;
  font-size: 18px;
  font-weight: 400;
  line-height: 34.69px;
  text-align: left;
  margin: 10px 0px;
  color: #595959;
}

.card-content button {
  padding: 10px 55px;
  background-color: transparent;
  border: 0.77px solid #000000;
  cursor: pointer;
  color: #000;
  border-radius: 0px;
}

// .icon-box-container-2 {
//   width: 18%;
//   display: flex;
//   flex-direction: column;
//   justify-content: center;
//   align-items: center;
//   gap: 3.5rem;
// }

.icon-box-2 {
  text-align: center;
}

.icon-box-2 img {
  width: 50px;
}

.icon-box-2 h6 {
  font-family: Cormorant;
  font-size: 20px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 0.01em;
  margin: 10px 0px;
}

.craftmans-content {
  width: 71%;
}

.craftmans-content h2 {
  font-family: Cormorant;
  font-size: 36px;
  font-weight: 600;
  // line-height: 30px;
  letter-spacing: 0.01em;
  text-align: left;
  color: #000;
  text-transform: uppercase;
  padding-bottom: 20px;
  // margin-top: 15px;
}

.craftmans-content p {
  font-family: Lato;
  font-size: 22px;
  font-weight: 400;
  line-height: 35px;
  letter-spacing: 0.01em;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;  
  color: #595959;
  margin-top: 15px;
}

.final-container {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  // align-items: center;
  padding: 50px;
  gap: 3rem;
}

.final-content {
  width: 65%;
}

.final-content h2 {
  font-family: "Cormorant", sans-serif;
  font-size: 36px;
  font-weight: 600;
  line-height: 43.6px;
  letter-spacing: 0.01em;
  text-transform: uppercase;
  text-align: left;
}

.final-content p {
  font-family: Lato;
  font-size: 22px;
  font-weight: 400;
  line-height: 35px;
  letter-spacing: 0.01em;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;  
  color: #595959;
}

.final-image {
  width: 40%;
  // height: 100%;
  // width: 506px;
height: 225px;
}
.final-image2{
  height: 290px;
}
.final-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

@media screen and (max-width: 1280px) {
  .image-text-container {
    flex-direction: column;
    justify-content: center;
  }

  .image-text-container .image {
    text-align: center;
  }
  .content-QS p {
    width: 100%;
  }
  .image-text-container .image{
    width: 100%;
  }
}
@media screen and (max-width: 820px) {
  .new-qs-container {
    margin-bottom: 10px;
  }

  .qs-icon-container {
    flex-wrap: wrap;
    gap: 2rem;
    width: 100%;
  }

  .qs-intro {
    width: 85%;
  }

  .qs-icon-container .qs-icon-box {
    width: 30%;
  }

  .qs-container-3 {
    flex-wrap: wrap;
    gap: 1rem;
  }

  .qs-card {
    width: 50%;
  }

  .icon-box-container-2 {
    width: 38%;
  }

  .craftmans-content {
    width: 100%;
  }

  .final-container {
    flex-direction: column;
  }

  .final-content {
    width: 100%;
  }

  .final-image {
    width: 100%;
  }

  .image-content .image img {
    width: 100%;
  }

  .image-text-container .image img {
    width: 100%;
  }
}

@media screen and (max-width: 480px) {
  .image-text-container .image{
    width: 100%;
  }
  .new-qs-container {
    padding: 10px;
  }

  .qs-intro {
    width: 100%;
    margin-top: 50px;
  }

  .qs-intro h2 {
    font-size: 40px;
  }
  .qs-intro p {
    font-size: 18px;
  }
  .content-QS p {
    font-size: 18px;
    width: 100%;
  }
  .qs-icon-container .qs-icon-box {
    width: 45%;
  }

  .image-text-container {
    padding: 10px;
  }

  .image-text-container .content-QS {
    margin-bottom: 30px;
    width: 100%;
    padding: 0px;
  }

  .qs-container-3 {
    padding: 10px;
  }

  .qs-card {
    width: 100%;
  }

  .icon-box-container-2 {
    flex-direction: row;
    width: 100%;
    padding: 20px 0px;
  }

  .icon-box-2 h6 {
    font-size: 12px;
  }

  .craftmans-content h2 {
    font-size: 30px;
    text-align: center;
  }

  .craftmans-content p {
    font-size: 16px;
    line-height: 30px;
    text-align: center;
  }

  .final-container {
    padding: 10px;
    margin-bottom: 50px;
  }

  .final-content h2 {
    font-size: 30px;
    text-align: center;
  }

  .final-content p {
    text-align: center;
    font-size: 16px;
    line-height: 30px;
  }

  .content-QS h4 {
    font-size: 30px;
  }
  .final-image {
  height: 215px;
  }
  .final-image2{
    height: 215px;
  }
  .qs-icon{
    img{
      width: 45px;
      height: 45px;
    }
  }
}
