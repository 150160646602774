.head-rug-part {
  h2 {
    text-align: center;
    font-weight: 500;
    font-size: 40px;
    text-transform: uppercase;
    font-family: "Cormorant", sans-serif;
  }
  @media screen and (max-width: 500px) {
    h2 {
      font-size: 20px;
      // margin-top: 30px;
    }
  }
}
.head-krug-part {
  padding-top: 10px;
  h2 {
    text-align: center;
    font-weight: 500;
    font-size: 40px;
    text-transform: uppercase;
    font-family: "Cormorant", sans-serif;
  }
  @media screen and (max-width: 500px) {
    h2 {
      font-size: 20px;
      // margin-top: 30px;
    }
  }
}
.image-compare {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 40px;
  margin-top: 30px;
}
.image-compare-mobile-desing {
  position: relative;
  width: 100%;
  max-width: 600px;
  margin: auto;
  overflow: hidden;
  padding: 0px 20px;
  display: none;
}
.slider-container {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}
.slider-image{
  width: 500px !important;
  max-height: 600px !important;
  object-fit: cover !important;
}
.slider-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: opacity 0.10s ease-in-out;
}

.image-wrapper {
  position: relative;
  height: 42.9rem;
  width: 80%;
  overflow: hidden;
  background: #fff;
  display: flex;
  @media screen and (max-width: 500px) {
    height: 20rem;
  }
}

.image-wrapper .image {
  height: 100%;
  width: 100%;
  display: flex;
}

.image-1,
.image-2 {
  position: absolute;
  height: 100%;
  width: 100%;
}

.image-1 img,
.image-2 img {
  height: 100%;
  width: 100%;
}
.image-1 {
  left: 0; /* Start position of the first image */
}

.image-2 {
  clip-path: inset(0 50% 0 0); /* Initial clip-path value */
}
.image-slider {
  position: absolute;
  width: 100%;
  top: 0;
  z-index: 10;
}

.image-wrapper .image-slider input {
  width: 100%;
  outline: none;
  background: none;
  -webkit-appearance: none;
}

.image-wrapper .image-slider input::-webkit-slider-thumb {
  height: 48.6rem;
  width: 0.3rem;
  background: none;
  -webkit-appearance: none;
  cursor: col-resize;
}

.image-wrapper .image-slider .dragLine {
  width: 0.3rem;
  height: 48.6rem;
  position: absolute;
  left: 49.85%;
  pointer-events: none;
  @media screen and (max-width: 500px) {
    height: 20rem;
  }
}

.image-wrapper .image-slider .dragLine::before,
.image-wrapper .image-slider .dragLine::after {
  position: absolute;
  content: "";
  width: 100%;
  height: 222px;
  background: #fff;
  @media screen and (max-width: 500px) {
    height: 80px;
  }
}

.image-wrapper .image-slider .dragLine::before {
  top: 0;
}

.image-wrapper .image-slider .dragLine::after {
  bottom: 0;
}

.image-wrapper .image-slider .dragLine span {
  height: 4.2rem;
  width: 4.2rem;
  border: 0.3rem solid #fff;
  position: absolute;
  top: 50%;
  left: 50%;
  border-radius: 50%;
  transform: translate(-50%, -50%);
  @media screen and (max-width: 500px) {
    height: 3rem;
    width: 3rem;
  }
}

.image-wrapper .image-slider .dragLine span::before,
.image-wrapper .image-slider .dragLine span::after {
  position: absolute;
  content: "";
  top: 50%;
  border: 1rem solid transparent;
  border-bottom-width: 0;
  border-right-width: 0;
  transform: translate(-50%, -50%) rotate(45deg);
}

.image-wrapper .image-slider .dragLine span::before {
  border-left-color: #fff;
  left: 40%;
}

.image-wrapper .image-slider .dragLine span::after {
  left: 60%;
  border-top-color: #fff;
}

/* Image labels */
.image-wrapper .image-labels {
  position: absolute;
  bottom: 3rem;
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 0 1rem;
  z-index: 100;
  @media screen and (max-width: 500px) {
    bottom: 1.3rem;
  }
}

.image-wrapper .image-labels .label-1,
.image-wrapper .image-labels .label-2 {
  color: #ffffff;
  font-weight: bold;
  padding: 0.5rem 1rem;
  border-radius: 0.5rem;
  font-size: 1.2rem;
  @media screen and (max-width: 500px) {
    padding: 0.2rem 0.5rem;
    font-size: 12px;
  }
}

.image-wrapper .image-labels .label-1 {
  /* For the "Red Room" label */
  position: absolute;
  left: 1rem;
}

.image-wrapper .image-labels .label-2 {
  /* For the "Living Room" label */
  position: absolute;
  right: 2.5rem;
}
.blog-home-container {
  // @media screen and (max-width: 500px) {
  //   display: none;
  // }
  // @media screen and (max-width: 1000px) {
  //   display: flex;
  //   justify-content: center;
  // }
}
.blog-container {
  display: flex;
  justify-content: center;
  gap: 30px;
  padding: 10px 20px 20px 20px;
  width: 75%;
  margin: 10px auto 40px auto;
  @media screen and (max-width: 1000px) {
    display: block;
    width: 90%;
    padding: 0;
    gap: 0;
    margin: 0 20px 0 50px;
  }
  @media screen and (max-width: 500px) {
    display: block;
    width: 100%;
    padding: 0;
    gap: 0;
    margin: 0 10px;
  }
}

.blog-item {
  width: 32%;
  background-color: #fff;
  /* border: 1px solid #ddd; */
  // border-radius: 8px;
  overflow: hidden;
  position: relative;
  .blog-home-image {
    width: 350px;
    height: 215px;
    overflow: hidden;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      transition: transform 0.4s ease;
    }
  }
  .blog-home-image:hover img {
    transform: scale(1.1);
  }
  @media screen and (max-width: 1000px) {
    width: 95%;
    margin-bottom: 20px;
    // border: 1px solid black;
    // display: flex;
    .blog-home-image {
      width: 100%;
    }
  }
  @media screen and (max-width: 500px) {
    width: 95%;
    justify-content: center;
    margin-bottom: 20px;
    // border: 1px solid black;
    // display: flex;
    .blog-home-image {
      width: 100%;
    }
    // .blog-container{
    //   width: 100%;
    //   justify-content: center;
    // }
  }
  @media (min-width: 400px) and (max-width: 500px) {
  }
}

.blog-date {
  position: absolute;
  top: 10%;
  right: 0;
  background: #fff;
  padding: 5px 30px;
  font-size: 19px;
  font-weight: 400;
  font-family: "Cormorant", sans-serif;
  color: #000;
  font-style: italic;
  @media screen and (max-width: 500px) {
    font-size: 15px;
  }
}

/* .blog-content {
    padding: 20px;
  } */

.blog-category {
  font-family: "Lato", sans-serif;
  font-size: 16px;
  font-weight: 400;

  color: #595959;
  @media screen and (max-width: 500px) {
    font-size: 12px;
  }
}

.blog-content h3 {
  margin-top: -0.2rem;
  font-size: 27px;
  font-weight: 500;
  text-align: left;
  margin-bottom: 20px;
  font-family: "Cormorant", sans-serif;
  @media screen and (max-width: 500px) {
    margin-top: 5px;
    font-size: 22px;
    margin-bottom: px;
  }
}

.read-more {
  display: inline-block;
  color: #000;
  text-decoration: none;
  position: relative;
  padding-left: 60px;
  font-family: "Lato", sans-serif;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 2.4000000953674316px;
  text-align: left;
  @media screen and (max-width: 500px) {
    margin-top: 5px;
    font-size: 9px;
  }
}

.read-more::before {
  content: "";
  display: block;
  width: 50px; /* Adjust the line length */
  height: 1px;
  background-color: #000;
  position: absolute;
  top: 50%; /* Center the line vertically */
  left: 0;
  transform: translateY(-50%); /* Ensure perfect vertical centering */
}

.read-more:hover::before {
  background-color: #555; /* Line color on hover */
}

.read-more:hover {
  color: #555; /* Text color on hover */
}
@media (max-width:500px) {
  // .image-compare{
  //   display: none;
  // }
  .image-compare-mobile-desing{
    display: block;
  }
  .image-wrapper{
    width: 90%;
  }
}
@media screen and (max-width: 992px) and (orientation: landscape) {
  .head-rug-part {
    h2 {
      font-size: 30px;
    }
  } 
  .image-wrapper {
    width: 88%;
  }
  .head-krug-part{
    h2{
      font-size: 30px;
    }
  }
}