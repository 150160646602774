.intro-trade-container {
  background-color: #e9e9e9;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 30px 80px;
}
.intro-trade-container .heading-trade {
  padding: 0;
  width: 100%;
}
.heading-trade h2 {
  font-family: Lato;
  font-size: 18px;
  font-weight: 400;
  line-height: 25px;
  text-align: center;
  color: #595959;
  margin-bottom: 0;
}
.trade-program-container {
  // padding: 50px 80px;
  margin-top: 40px;
}
.trade-program-container .image-content {
  background-image: url("../images/banner-trade.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  width: 100%;
  height: 350px;
  display: flex;
  align-items: center;
  margin: 0px auto;
  padding-left: 50px;
}

.trade-program-container .image-content .content {
  width: 60%;
  height: 90%;
  background-color: #fff;
  padding: 20px 50px;
  border-radius: 4px;
}
.content p {
  font-family: Lato;
  font-size: 16px;
  font-weight: 500;
  line-height: 30px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  margin-top: 5px;
}
.trade-form-container {
  /* width: 100%; */
  display: flex;
  align-items: center;
  margin-bottom: 50px;
  margin-top: 60px;
}
.form-container {
  width: 70%;
  border: 1px solid #f5db8b;
  margin: 0px auto;
  padding: 30px 40px;
}
.form-container h2 {
  text-align: center;
  margin-bottom: 50px;
  font-family: Cormorant;
  font-size: 32px;
  font-weight: 500;
  line-height: 25px;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
}
.trade-program-form {
  display: flex;
  flex-direction: column;
  width: 85%;
  margin: 0px auto;
  .error {
    color: red;
    font-size: 0.9em;
  }
}
.form-group {
  margin-bottom: 20px;
}
.form-group input,
.form-group textarea {
  width: 100%;
  padding: 15px;
  border: 1px solid #c9c9c9;
  font-size: 14px;
  outline: none;
  // margin-bottom: 20px;
  border-radius: 0;
}
.form-row {
  display: flex;
  gap: 12rem;
}

.form-row .form-group {
  flex: 1;
}

.form-group textarea {
  height: 100px;
  resize: none;
  // color: #c9c9c9;
}
.form-group textarea::placeholder {
  color: #595959;
}
.form-group input::placeholder {
  color: #595959;
}
.trade-main-button {
  width: 30%;
  padding: 12px;
  background-color: black;
  color: white;
  font-size: 14px;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s;
  border-radius: 0;
}

.trade-main-button button:hover {
  background-color: #333;
}

input::placeholder,
textarea::placeholder {
  font-family: "Lato", sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 25px;
  text-align: left;
  color: #595959;
}
@media (max-width:1240px) {
  .trade-program-container .image-content{
    height: 450px;
  }
  .form-row {
    gap: 5rem;
  }
}
@media screen and (max-width: 820px) {
  .form-row {
    flex-direction: column;
    gap: 0rem;
  }

  .form-container {
    padding: 30px 20px;
  }
}

@media (max-width: 600px) {
  .trade-program-container {
    padding: 50px 20px;
  }
  .trade-program-container .image-content {
    width: 100%;
    height: 100%;
    padding: 0px;
  }

  .trade-program-container .image-content .content {
    width: 100%;
    background-color: transparent;
    padding: 30px;
  }

  .content p {
    font-size: 14px;
    color: white;
    line-height: 30px;
  }

  .trade-form-container {
    padding: 20px;
  }

  .form-container {
    width: 100%;
  }

  .trade-program-form {
    width: 100%;
    margin: 0px;
  }

  .trade-program-form button {
    width: 100%;
  }
}
