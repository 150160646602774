.checkout-signup-main-content {
  padding: 2% 4%;
  display: flex;
  justify-content: space-between;
  padding-bottom: 80px;
}
.checkout-signup-left-box {
  padding: 3% 4%;
  padding-bottom: 50px;
  display: flex;
  justify-content: space-between;
}
.signup-form-content {
  width: 45%;

  h4 {
    font-family: Cormorant;
    font-size: 26px;
    font-weight: 500;
    color: #000000;
  }
}
.checkout-signup-form .form-field-checkout {
  margin-top: 10px !important;
}
.form-field-checkout {
  display: flex;
  flex-direction: column;
  flex: 1;
  position: relative;
}
.form-field-checkout label {
  font-family: Lato;
  font-size: 18px;
  font-weight: 500;
  color: #595959;
  margin-top: 10px;
}
.form-field-checkout input {
  border: 1px solid #c9c9c9;
  height: 44px;
  margin-top: 8px;
  padding: 0px 20px;
  font-size: 16px;
  margin-bottom: 15px;
  color: #595959;
}
.form-field-checkout .error {
  color: red;
}
.form-field-checkout input[name="phone"] {
  // padding-left: 60px; /* Increase padding to make space for the code */
  // border: 1px solid #c9c9c9;
  // height: 44px;
  // margin-top: 8px;
  // padding: 0px 20px;
  // font-size: 16px;
  // margin-bottom: 15px;
}
.form-field-checkout .default-india-code-checkout {
  position: absolute;
  color: #595959;
  font-size: 16px;
  z-index: 20;
  top: 18px;
  margin-right: 20px;
  left: 20px;
}
.form-field-checkout .password-checkout-signup-icon,
.confirmpassword-checkout-signup-icon {
  position: absolute;
  right: 20px;
  top: 18px;
  cursor: pointer;
  color: #595959;
  font-size: 20px;
}
.password-text-checkout {
  font-family: Lato;
  font-size: 14px;
  font-weight: 500;
  line-height: 16.8px;
  text-align: left;
  color: #595959;
  margin-top: -5px;
  margin-bottom: 4px;
}
.checkout-signup-right-box {
  width: 40%;
  margin-top: 10px;
}
.buttons-text-checkout {
  margin-top: 50px;
  p {
    font-family: Lato;
    font-size: 16px;
    font-weight: 500;
    color: #595959;
    a {
      color: inherit;
    }
  }
  button {
    background: #000000;
    border: 1px solid #000000;
    font-family: Lato;
    font-size: 12px;
    font-weight: 400;
    line-height: 14.4px;
    letter-spacing: 1px;
    text-align: center;
    color: #ffffff;
    width: 100%;
    height: 44px;
    margin-top: 10px;
    border-radius: 0;
  }
  button:hover {
    border: 1px solid black;
    color: #000;
    background-color: #fff;
  }
  button:disabled {
    background-color: #ccc; /* Light gray background */
    color: #666; /* Dim text color */
    cursor: not-allowed; /* Change cursor to indicate the button is disabled */
    opacity: 0.6;
  }
}
.order-summary-text {
  width: 100%;
}
.border-of-order h5 {
  border-bottom: 1px solid #a6a6a6 !important;
  width: 100% !important;
}
.enter-code input {
  border-style: none;
  outline: 0;
}
.name-price {
  display: flex !important;
  justify-content: space-between !important;
  align-items: center !important;
}
.size-text-and-color {
  margin: 0px !important;
  margin-top: -12px !important;
}
.size-text-and-color p {
  margin: 0px !important;
  margin-top: -8px !important;
}
.order-summary-text h5 {
  font-family: Cormorant;
  font-size: 28px;
  font-weight: 500;
  color: #0c0c0c;
  padding-bottom: 10px;
}
.order-summary-text {
  border-bottom: 1px solid #a6a6a6;
  width: 100%;
}
.text-of-cart {
  margin-top: -16px !important;
}
.sign-in-with {
  margin-top: 25px;
  font-family: "Lato", sans-serif;
  color: #595959;
  font-size: 16px;
  text-align: center;
  span {
    text-decoration: underline;
    cursor: pointer;
  }
}
.facebook-google-button-checkout {
  display: flex;
  justify-content: center;
  margin-top: 15px;
  gap: 80px;
}
.facebook-google-button-checkout button {
  border: 1px solid #000000;
  width: 200px;
  height: 44px;
  padding: 10px 40px 10px 40px;
  display: flex;
  align-items: center;
  background-color: transparent;
  gap: 20px;
  font-family: Lato;
  font-size: 12px;
  font-weight: 400;
  letter-spacing: 1px;
  color: #000000;
  border-radius: 0;
}
.facebook-google-button-checkout button svg {
  color: #000000;
  font-size: 24px;
}
@media (max-width: 1051px) {
  .checkout-signup-left-box {
    width: 45%;
  }
  .checkout-signup-right-box {
    width: 50%;
  }
}
@media (max-width: 841px) {
  .checkout-signup-left-box {
    width: 40%;
  }
  .checkout-signup-right-box {
    width: 55%;
  }
}
@media (max-width: 768px) {
  .checkout-signup-main-content {
    display: block;
  }
  .checkout-signup-left-box {
    width: 100%;
    flex-direction: column;
  }
  .checkout-signup-right-box {
    width: 100%;
  }
}
@media (max-width: 576px) {
  .signup-form-content {
    width: 100%;
    padding: 40px 10px;
  }
  .payment-image img {
    width: 100px;
    height: 250px;
  }
  .name-price p {
    margin-top: -15px;
  }
  .size-text-and-color p {
    font-size: 12px;
  }
  .text-of-cart p {
    font-size: 10px;
  }
  .checkout-signup-left-box{
    margin-bottom: 40px;
  }
  .checkout-signup-right-box {
    margin-top: 40px;
  }
  .facebook-google-button-checkout button {
    border: 1px solid #000000;
    width: 180px;
    height: 44px;
    padding: 10px 20px;
    display: flex;
    align-items: center;
    background-color: transparent;
    gap: 20px;
    font-family: Lato;
    font-size: 12px;
    font-weight: 400;
    letter-spacing: 1px;
    color: #000000;
  }
  .facebook-google-button-checkout button svg {
    font-size: 18px;
  }
  .form-field-checkout label {
    font-size: 15px;
  }
  .form-field-checkout input {
    height: 40px;
  }
}
