.abstract-page-container {
  background-color: #ffffff;
  padding: 20px 80px;
}
.abstract-main-content {
  display: flex;
  gap: 30px;
  padding-bottom: 60px;
}
.abstract-left-box {
  width: 20%;
}
.shop-by-color-price-size {
  margin-top: 40px;
}
.filter-container {
  font-family: Arial, sans-serif;
  color: #333;
}

.filter-list {
  margin: 20px 0;
}
.menu-icon-rugs-in-mobile-view {
  display: none;
}
// .list-header {
//   display: flex;
//   justify-content: space-between;
//   align-items: center;
//   cursor: pointer;
// }
// .price-range-container {
//   position: relative;
// }
// .price-range {
//   display: flex;
//   align-items: center;
//   margin-top: 10px;
// }
// .slider {
//   width: 100%;
//   -webkit-appearance: none;
//   appearance: none;
//   height: 6px;
//   background: #C9C9C9;
//   outline: none;
// }
// .slider::-webkit-slider-thumb {
//   -webkit-appearance: none;
//   appearance: none;
//   width: 20px;
//   height: 20px;
//   // background: #333;
//   border-radius: 50%;
//   cursor: pointer;
// }
// .brown::-webkit-slider-thumb {
//   background-color: #767676;
// }
// /* Black slider */
// .black::-webkit-slider-thumb {
//   background-color: #232323;
// }
// .fixed-label {
//   font-family: Lato;
//   font-size: 17px;
//   font-weight: 300;
//   color: #000000;
//   padding: 0px 3px;
// }
// .dynamic-label {
//   position: absolute;
//   font-size: 12px;
//   font-weight: normal;
//   top: -15px; /* Adjust position above the sliders */
//   transform: translateX(-85%);
//   font-family: Lato;
//   font-weight: 300;
//   color: #000000;
// }
// .price-labels {
//   position: relative;
//   display: flex;
//   justify-content: space-between;
//   width: 100%;
//   margin-top: 15px;
// }
.price-range-section {
  font-family: Arial, sans-serif;
  color: #333;
  max-width: 400px;
  margin: 0 auto;
}

.price-range-section h3 {
  font-size: 20px;
  font-weight: bold;
}

.price-range-section p {
  font-size: 14px;
  margin-bottom: 15px;
  color: #666;
}

.price-inputs {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 30px;
  margin-top: 10px;
}

.min-input,
.max-input {
  display: flex;
  gap: 10px;
  align-items: center;
}
.price-inputs label {
  font-size: 16px;
  margin-bottom: 5px;
  color: #333;
}
.price-inputs input {
  width: 70px;
  padding: 7px;
  text-align: center;
  border: 1px solid #b8b8b8;
  border-radius: 4px;
  font-size: 16px;
  color: #333;
}
.price-inputs span {
  font-size: 24px;
  color: #333;
  font-weight: bold;
  margin-top: -10px;
}
.slider-container {
  position: relative;
  margin-top: 10px;
  display: flex;
  align-items: center;
  margin-top: 10px;
}
.slider-price {
  width: 100%;
  -webkit-appearance: none;
  appearance: none;
  height: 6px;
  background: #c9c9c9;
  outline: none;
  transition: background 0.1s ease;
}
.slider-price::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  cursor: pointer;
}
.brown::-webkit-slider-thumb {
  background-color: #767676;
}
.black::-webkit-slider-thumb {
  background-color: #232323;
}
.list-of-links ul {
  display: flex;
  gap: 5px;
}
.list-of-links ul li {
  list-style: none;
}
.list-of-links a {
  font-family: Lato;
  font-size: 17px;
  font-weight: 400;
  line-height: 27.72px;
  color: #595959;
  cursor: pointer;
  text-decoration: none;
}
.list-of-links a:hover {
  color: #a5a3a3;
}
.shop-by-color-price-size h4 {
  font-family: Cormorant;
  font-size: 27px;
  font-weight: 500;
  line-height: 32.15px;
  color: #000000;
}
.selected-filter {
  background-color: #a2a0a0;
  font-weight: bold !important;
}
.list-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0;
  cursor: pointer;
  margin-top: 10px;
}
.list-header p {
  margin: 0;
  font-family: Lato;
  font-size: 18px;
  font-weight: 600;
  color: #595959;
}
.list-header span svg {
  color: #595959;
  font-size: 20px;
}
.list ul {
  list-style: none;
  padding: 0;
}
.size-emty {
  width: 20px;
  height: 20px;
  border: 2px solid #a5a3a3;
}
.list li {
  cursor: pointer;
  margin: 10px 0;
  font-family: Lato;
  font-size: 16.77px;
  font-weight: 300;
  color: #000000;
  display: flex;

  padding: 2px;
  align-items: center;
  gap: 15px;
  input {
    accent-color: #000;
  }
}
.abstract-right-box {
  width: 80%;
  .load-more-container {
    display: flex;
    justify-content: center;
    margin-top: 20px;
  }
}
.abstract-right-box-content {
  margin-top: 40px;
}
.heading-text-and-color-box,
.heading-items,
.color-and-filter,
.colors-box,
.grey-color,
.black-color {
  display: flex;
  justify-content: space-between;
  align-items: end;
}
.heading-items {
  gap: 25px;
  align-items: center;
}
.heading-items h3 {
  font-family: Cormorant;
  font-size: 40px;
  font-weight: 500;
  color: #000000;
}
.heading-items p {
  font-family: Lato;
  font-size: 20px;
  font-weight: 400;
  color: #000000;
  margin-top: 7px;
}
.colors-box {
  gap: 15px;
}
.color-and-filter {
  gap: 15px;
}
.grey-color,
.black-color {
  gap: 2px;
  cursor: pointer;
}
.grey-box,
.black-box {
  width: 8px;
  height: 20px;
  background: #ffff;
  border: 1px solid;
  margin: 10px 0;
}
.black-box {
  background: #636262;
}
// .grey-color.active {
//   .grey-box {
//     background-color: rgb(221, 19, 19) ; // Change color when grey is active
//   }
//   .black-box {
//     background-color: pink ; // Change black box color only when grey is active
//   }
// }
/* Grey Color Styles */
.grey-color {
  .grey-box {
    background: #ffffff;
  }
  &.default {
    .grey-box {
      background: #ffffff;
    }
  }
  &.active {
    .grey-box {
      background: #636262;
      /* Red for active */
    }
  }

  &.inactive {
    .grey-box {
      background: #ffffff;
    }
  }
}

/* Black Color Styles */
.black-color {
  .black-box {
    background: #636262;
    /* Default inactive state */
  }
  &.default {
    .black-box {
      background-color: #636262; /* Default color when no box is clicked */
    }
  }
  &.active {
    .black-box {
      background: #636262;
    }
  }

  &.inactive {
    .black-box {
      background: #ffffff;
    }
  }
}

.sort-button button {
  border: 1.11px solid #c9c9c9;
  background-color: transparent;
  font-family: Lato;
  font-size: 16.63px;
  font-weight: 400;
  color: #595959;
  padding: 10px 20px;
  display: flex;
  align-items: center;
  border-radius: 0px;
}
.sort-button button svg {
  font-size: 30px;
}
.sort-dropdown {
  background-color: #ffffff;
  box-shadow: 0px 4px 10px 0px #00000033;
  padding: 20px;
  margin-top: 20px;
  position: absolute;
  width: 180px;
  transform: translateX(-30%);
  z-index: 10;
}
.sort-button {
  position: relative;
}
.sort-dropdown li {
  cursor: pointer;
  list-style: none;
  color: #636262;
  font-family: Lato;
  font-size: 14px;
  font-weight: 400;
  margin-top: 15px;
}
.sort-dropdown li:hover {
  color: #000000;
}
.selected-all-filter {
  display: flex;
  gap: 10px;
  margin-top: 10px;
  .selected-item {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    border-radius: 50px;
    border: 1px solid #ccc;
    padding: 10px;
    svg {
      cursor: pointer;
    }
  }
}
.abstract- {
  margin-top: 20px;
}
.sidebar-of-filter-rugs {
  position: relative;
  // top: 0;
  right: 0;
  width: 300px;
  height: 100%;
  background: #f9f9f9;
  box-shadow: -2px 0 5px rgba(0, 0, 0, 0.5);
  z-index: 1000;
  overflow-y: auto;
  transform: translateX(0);
  transition: transform 0.3s ease;
}

.sidebar-of-filter-rugs-content {
  padding: 20px;
}

.filter-rugs-close-btn {
  background: transparent;
  border: none;
  font-size: 18px;
  cursor: pointer;
}
@media (max-width:1024px) {
  .abstract-page-container{
    padding: 20px 40px;
  }
  .abstract-left-box{
    width: 25%;
  }
  .abstract-right-box{
    width: 75%;
  }
}
@media (max-width: 992px) {
  .list-of-links ul {
    gap: 5px;
  }
  .list-of-links a {
    font-size: 15px;
  }
  .shop-by-color-price-size h4 {
    font-size: 24px;
  }
  .list-header p {
    font-size: 18px;
  }
  .list-header span svg {
    font-size: 18px;
  }
  .heading-items {
    gap: 15px;
  }
  .heading-items h3 {
    font-size: 30px;
  }
  .heading-items p {
    font-size: 15px;
    margin-top: 7px;
  }
  .colors-box {
    gap: 15px;
  }
  .color-and-filter {
    gap: 10px;
  }
  .grey-color,
  .black-color {
    gap: 3px;
  }
  .grey-box,
  .black-box {
    width: 15px;
    height: 20px;
  }
  .sort-button button {
    padding: 10px 15px;
  }
  .sort-button button svg {
    font-size: 20px;
  }
  .sort-dropdown {
    width: 150px;
  }
}
@media (min-width: 700px) and (max-width: 1000px) {
  .abstract-page-container {
    padding: 20px;
  }
  .sort-dropdown {
    margin-top: 10px;
    width: 150px;
    transform: translateX(0%);
  }
}
@media (max-width: 992px) {
  .abstract-right-box {
    width: 100%;
  }
  .filter-box {
    display: flex;
    gap: 10px;
  }
  .abstract-left-box {
    display: none;
  }
  .menu-icon-rugs-in-mobile-view {
    display: block;
    svg {
      margin-top: 20px !important;
    }
  }
  .heading-text-and-color-box {
    display: block;
  }
  .sort-button-menu-icon {
    display: flex;
    align-items: center !important;
    justify-content: space-between;
  }
  .sort-dropdown {
    margin-top: 10px;
    width: 150px;
    transform: translateX(0%);
  }
  .colors-box {
    display: none;
  }
}
@media (max-width: 576px) {
  // .heading-text-and-color-box {
  //   display: block;
  // }
  .sort-dropdown {
    margin-top: 10px;
    width: 150px;
    transform: translateX(0%);
  }
  .heading-items h3 {
    font-size: 22px;
  }
  .heading-items p {
    font-size: 10px;
    margin-top: 4px;
  }
  .sort-button button {
    padding: 10px;
    font-size: 14px;
  }
  .sort-button button svg {
    height: 15px;
    width: 15px;
  }
  .abstract-page-container {
    padding: 20px 20px;
  }
  .color-and-filter {
    display: flex;
    justify-content: end;
    margin-top: 10px;
  }
  .heading-items {
    display: flex;
    justify-content: start;
    gap: 10px;
  }

  .selected-all-filter .selected-item {
    gap: 0.6rem;
    padding: 8px;
    font-size: 15px;
  }
  .menu-icon-rugs-in-mobile-view svg {
    width: 25px;
    height: 25px;
    // align-items: end;
  }
  .abstract-right-box-content {
    margin-top: 0;
  }
}
input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}
.test-product-props-details {
  display: none;
}
@media (max-width: 375px) {
  .abstract-page-container {
    padding: 15px;
  }
}
@media screen and (max-width: 700px) and (orientation: landscape) {
  .abstract-page-container{
    padding: 20px 30px;
  }
}