/* Global Reset */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
/* Footer Container */
.footer-container-page {
  background-color: #f8f9fa;
  padding: 20px 80px;
  box-sizing: border-box;
  position: relative;
  z-index: 20;
}
/* Main Footer Content */
.footer-main-content {
  display: flex;
  width: 100%;
  height: auto;
}
/* Left Side (Newsletter, Socials) */
.footer-left-box {
  width: 30%;
}
/* Right Side (Links and WhatsApp Icon) */
.footer-right-box {
  width: 70%;
  display: grid;
  /* grid-template-columns: repeat(4, 1fr); */
  grid-template-columns: 1fr 1fr 1fr 0.1fr;
}
/* Newsletter and Social Icons */
.heading-email-icons-box {
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  .message-subscribe {
    color: red;
    font-size: 12px;
  }
}
.heading-email-icons-box h6 {
  font-family: "Inter", sans-serif;
  font-size: 12px;
  font-weight: 300;
  letter-spacing: 2.16px;
  color: #303030;
  margin-bottom: 0;
}
.copy-right-box p {
  margin-top: 10px;
  font-family: Cormorant;
  font-size: 20px;
  font-weight: 300;
  color: #303030;
}
.heading-email-icons-box input {
  border: 0.9px solid #1c1c1c26;
  font-family: "Inter", sans-serif;
  font-size: 11px;
  font-weight: 400;
  padding: 12px 10px;
  width: 70%;
}
.heading-email-icons-box button {
  border: 0.73px solid #f5db8b;
  background: #f5db8b;
  font-family: "Inter", sans-serif;
  font-size: 8.86px;
  font-weight: 400;
  letter-spacing: 1.7px;
  color: #303030;
  width: 45%;
  padding: 10px;
  cursor: pointer;
  border-radius: 0px;
  margin-top: 10px;
}
/* Social Icons */
.left-box-icons {
  display: flex;
  color: #303030;
  margin-top: 15px;
  a {
    color: inherit;
  }
}
.left-box-icons svg {
  font-size: 23px;
  position: relative;
  z-index: 2; /* Make sure icons stay above the overlay */
}
.social-link-icons {
  position: relative;
  border: 1px solid rgb(162, 161, 161);
  border-right: none;
  padding: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  overflow: hidden; /* Clip the overlay to the box */
  cursor: pointer;
}
.social-link-icons:last-child {
  border-right: 1px solid rgb(162, 161, 161);
}
/* Before pseudo-element for the background overlay */
.social-link-icons::before {
  content: "";
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: black;
  z-index: 1;
  transition: top 0.5s ease; /* Smooth transition effect */
}
/* Hover effect to bring overlay up */
.social-link-icons:hover::before {
  top: 0;
}
/* Icon color change on hover */
.social-link-icons:hover svg {
  color: white;
}
/* Footer Link Section */
.all-links-of-footer h5 {
  font-family: Cormorant;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 2px;
  color: #303030;
  margin-bottom: 25px;
}
.footer-logo {
  img {
    width: 250px;
    height: auto;
  }
}
.all-links-of-footer ul {
  margin-top: 18px;
}
.all-links-of-footer ul li {
  list-style: none;
  margin-top: 15px;
}
.all-links-of-footer a {
  text-decoration: none;
  color: #303030a6;
  font-family: Lato;
  font-size: 15px;
  font-weight: 400;
  letter-spacing: 1px;
}
/* WhatsApp Icon */
.whatsapp-icon {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: fixed;
  width: 46px;
  height: 46px;
  bottom: 15px;
  right: 15px;
  background-color: #25d366;
  color: #fff;
  border-radius: 50px;
  text-align: center;
  font-size: 30px;
  box-shadow: 2px 2px 3px #999;
  z-index: 100;

  // margin-bottom: 20px;
}
.whatsapp-icon img {
  // border-radius: 50%;
  width: 35px;
  height: 35px;
  background-color: #25d366;
  padding: 1px 1px 1px 2px;
}
.copy-right-box-content {
  display: flex;
  align-items: center;
  /* justify-content: space-between;  */
  width: 100%;
}
.copy-right-content {
  text-align: center;
  flex: 0.9;
}
.copy-right-content p {
  font-family: "Inter", sans-serif;
  font-size: 12px;
  font-weight: 300;
  line-height: 14.52px;
  letter-spacing: 1.9800000190734863px;
  text-align: center;
  color: #303030;
}
.mobile-device-view,
.navbar {
  display: none;
}
.mobile-footer-container {
  margin-top: 30px;
}
.dropdown-header {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  width: 80vw;
}
.dropdown-header h5 {
  font-family: Cormorant;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 2px;
  color: #303030;
}
.dropdown-header svg {
  color: #303030;
  margin-top: 30px;
}
.dropdown-content li {
  list-style: none;
  margin-top: 15px;
}
.dropdown-content a {
  text-decoration: none;
  text-decoration: none;
  color: #303030a6;
  font-family: Lato;
  font-size: 15px;
  font-weight: 400;
  letter-spacing: 1px;
}
.scroll-icons {
  display: flex;
  align-items: center;
  // justify-content: space-around;
  // gap: 0px;
  // padding: 10px 0;
}
.up-arrow-button {
  display: flex;
  align-items: center;
  justify-content: end;
  position: fixed;
  right: 20px;
  bottom: 80px;
  // margin-top: 5px !important;
}

.up-arrow-button button {
  width: 40px;
  height: 40px;
  background: white !important;
  border-radius: 50%;
  border: none;
  // box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  cursor: pointer;
  // display: flex;
  // align-items: center;
  // justify-content: center;
}
/* Sticky positioning for when the scroll button is active */
.scroll-icons.sticky {
  position: fixed;
  bottom: 35px;
  right: 80px;
  flex-direction: column;
}
.up-arrow-button {
  margin-top: 20px;
  margin-left: 100px;
  // bottom: 150px;
  button {
    border-radius: 0;
    border: 1.02px solid #636262;
    background-color: transparent;
    padding: 15px 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
  }
}
.up-arrow-button button svg {
  color: #636262;
  font-size: 30px;
}
.fixed-navbar {
  position: fixed;
  bottom: 0;
  width: 100%;
  z-index: 1000 !important;
  left: 0;
  right: 0;
}
@media (max-width: 992px) {
  .whatsapp-icon img,
  .up-arrow-button button {
    width: 30px;
    height: 30px;
  }
  /* Sticky positioning for when the scroll button is active */
  .scroll-icons.sticky {
    bottom: 35px;
    right: 50px;
  }
  .footer-logo {
    img {
      width: 120px;
      height: auto;
    }
  }
  .copy-right-box p {
    font-size: 19px;
  }
  .left-box-icons svg {
    width: 15px;
    height: 15px;
  }
  .all-links-of-footer h5 {
    font-size: 12px;
  }
  .all-links-of-footer a {
    font-size: 12px;
  }
  // .whatsapp-icon {
  //   margin-bottom: 25px;
  // }
  .whatsapp-icon img {
    width: 25px;
    height: 25px;
  }
  .up-arrow-button {
    margin-top: -90px !important;
  }
  // .copy-right-content {
  //   flex: 0.85;
  // }
  // .up-arrow-button button {
  //   padding: 5px 5px !important;
  // }
  // .up-arrow-button button svg {
  //   width: 20px;
  // }
  .copy-right-content {
    // flex: 0.85;
  }
  .copy-right-content p {
    font-size: 11px;
  }
}
@media (max-width: 768px) {
  .up-arrow-button {
    margin-top: -120px !important;
  }
  .footer-main-content {
    display: block;
  }
  .footer-left-box {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 100%;
  }
  .heading-email-icons-box input {
    max-width: 100%;
    width: 60vw;
  }
  .heading-email-icons-box button {
    max-width: 100%;
    width: 40vw;
    margin-top: 10px;
  }
  .footer-right-box {
    width: 100%;
    margin-top: 40px;
  }
  .copy-right-box {
    margin-top: 20px;
  }
  .heading-email-icons-box h6 {
    text-align: center;
  }
}
@media (max-width: 576px) {
  .footer-container-page {
    padding-bottom: 80px;
  }
  .whatsapp-icon {
    width: 50px;
    height: 50px;
    bottom: 90px;
  }
  .whatsapp-icon img {
    width: 25px;
    height: 25px;
  }
  .left-box-icons svg {
    font-size: 16px !important;
  }
  .social-link-icons {
    padding: 12px;
  }
  .scroll-icons {
    display: none;
  }
  .footer-main-content,
  .copy-right-box {
    display: none;
  }
  .mobile-device-view,
  .navbar {
    display: block;
  }
  .mobile-device-view img {
    width: 130px;
    height: auto;
  }
  .content-of-mobile-device h5 {
    font-family: "Cormorant", sans-serif;
    font-size: 15px;
    font-weight: 300;
    letter-spacing: 2px;
    color: #1c1c1c;
    margin-top: 40px;
  }
  .left-box-icons {
    margin-top: 50px;
    // gap: 70px;
  }
  .email-button {
    display: flex;
    gap: 10px;
  }
  .content-of-mobile-device {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .email-button {
    display: flex;
    gap: 10px;
    margin-top: 15px;
  }
  .email-button input {
    border: 0.9px solid #1c1c1c26;
    font-family: "Inter", sans-serif;
    font-size: 11px;
    font-weight: 400;
    padding: 12px 10px;
    width: 40vw;
  }
  .email-button button {
    border: 0.73px solid #f5db8b;
    background: #f5db8b;
    font-family: "Inter", sans-serif;
    font-size: 8.86px;
    font-weight: 400;
    letter-spacing: 1.7px;
    color: #303030;
    width: 30vw;
    padding: 10px;
    cursor: pointer;
    border-radius: 0px;
  }
  .footer-right-box {
    display: block;
  }
  .copy-right-content {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 30px;
    position: relative;
    margin-bottom: 20px;
  }
  .center-content {
    flex-grow: 1; /* Ensures it takes up the remaining space */
    display: flex;
    justify-content: center;
  }
  .right-content {
    position: absolute;
    right: 0;
    left: 220px;
  }
  .right-content img {
    width: 30px;
    height: 30px;
  }
  .copy-right-content p {
    font-family: "Lato", sans-serif;
    font-size: 11px;
    font-weight: 300;
    color: #303030;
    letter-spacing: 1.98px;
  }
  .home-page {
    background-color: #ffffff;
    box-shadow: 0px 0px 9px 0px #0000001f;
    border: 1px solid #ffffff;
  }
  .home-content-icons {
    padding: 15px 25px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .icon-title {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 8px;
  }
  .icon-title svg {
    color: #232323;
    font-size: 25px;
    font-weight: bold;
  }
  .icon-title p {
    font-family: Segoe UI;
    font-size: 15px;
    font-weight: 400;
    line-height: 12px;
    color: #232323;
  }
  .message-subscribe {
    font-size: 10px;
    margin-top: 5px;
    color: red;
  }
  .up-arrow-button {
    display: none;
    button {
      display: none;
    }
  }
}

.up-arrow-button {
  margin-top: 20px;
  margin-left: 100px;
  // bottom: 150px;
  button {
    border-radius: 0;
    border: 1.02px solid #636262;
    background-color: transparent;
    padding: 15px 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
  }
}
.up-arrow-button button svg {
  color: #636262;
  font-size: 30px;
}
.fixed-navbar {
  position: fixed;
  bottom: 0;
  width: 100%;
  z-index: 1000 !important;
  left: 0;
  right: 0;
}
@media screen and (max-width: 916px) and (orientation: landscape) {
  // .copy-right-box{
  //   font-size: 14px !important;
  // }
}
