.intro-refund-container {
  // height: 20vh;
  background-color: #e9e9e9;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 30px 80px;
}

.intro-refund-container .heading-refund {
  // border: 1px solid black;

  padding: 0;
  width: 100%;
}

.heading-refund {
  h2 {
    font-family: "Lato", sans-serif;
    font-size: 18px;
    font-weight: 400;
    line-height: 25px;
    text-align: left;
    color: #595959;
    margin-bottom: 0;
    text-align: center;
  }
}
.refund-main-content {
  padding: 50px 80px;
  max-width: 1200px;
  margin: 0 auto;
}
.refund-faqs h5 {
  font-family: "Cormorant", sans-serif;
  font-size: 32px;
  font-weight: 500;
  letter-spacing: 0.005em;
  color: #000000;
  margin: 0px;
  text-align: center;
}
.faqs-main-content {
  margin-top: 20px;
}
.refund-faqs-details h6,
.refund-faqs-details p {
  font-family: "Lato", sans-serif;
  font-size: 18px;
  font-weight: 400;
  letter-spacing: 0.006em;
  line-height: 32px;
  color: #000000;
  margin: 0px;
  margin-bottom: 35px;
  .email-link {
    color: #595959;
    text-decoration: underline;
  }
  
}
.refund-faqs-details h6 {
  margin-top: 40px;
}
.refund-faqs-details p {
  color: #595959;
}
.refund-faqs-details{
  ul{
    li{
      color: #595959;
      font-size: 20px;
      list-style-type: disc;
      }
  }
}
@media (max-width: 992px) {
  .refund-faqs h5 {
    font-size: 28px;
  }
  .refund-faqs-details h6,
  .refund-faqs-details p {
    font-size: 16px;
  }
}
@media (max-width: 768px) {
  .refund-faqs h5 {
    font-size: 25px;
    margin-top: 30px;
  }
  .refund-faqs-details h6,
  .refund-faqs-details p {
    font-size: 14px;
  }
  .refund-faqs-details p {
    line-height: 25px;
  }
  .refund-faqs-details h6 {
    margin-top: 30px;
  }
}
@media (max-width: 576px) {
  .refund-main-content {
    padding-bottom: 30px;
    width: 100%;
    padding: 30px 30px;
  }

}
