.password-main-content {
  padding: 40px 80px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding-bottom: 80px;
}
.password-main-content h4 {
  font-family: Cormorant;
  font-size: 36px;
  font-weight: 500;
  color: #000000;
}
.password-main-content p {
  font-family: Lato;
  font-size: 18px;
  font-weight: 500;
  width: 85%;
  color: #595959;
}

.password-main-content {
  input {
    border: 1px solid #c9c9c9;
    height: 44px;
    color: #595959;
    padding-left: 20px;
    outline: none;
    font-size: 18px;
    width: 50%;
  }
  button {
    display: block;
    background: #000000;
    color: #ffffff;
    font-family: Lato;
    font-size: 12px;
    font-weight: 400;
    border-style: none;
    padding: 10px 0px;
    letter-spacing: 1px;
    width: 200px;
    margin-top: 20px;
    border-radius: 0;
  }
  .error-message {
    color: red;
  }
}
@media (max-width: 768px) {
  .password-main-content {
    padding: 40px 30px;
  }
  .password-main-content p {
    font-size: 15px;
    width: 100%;
  }
  .password-main-content h4 {
    font-size: 33px;
  }
  .password-main-content input {
    font-size: 15px;
    width: 100%;
  }
  .password-main-content button {
    padding: 10px 0px;
    width: 180px;
    height: 40px;
  }
}
@media (max-width: 576px) {
  .password-main-content p {
    margin-top: 20px;
  }
}
