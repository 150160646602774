.sidebar {
  position: fixed;
  top: 0;
  right: 0;
  width: 350px;
  height: 100vh;
  background-color: #fff;
  box-shadow: -2px 0 5px rgba(0, 0, 0, 0.5);
  transition: transform 0.3s ease-in-out;
  z-index: 1000;
  overflow-y: auto; /* Enable vertical scrolling */
  overflow-x: hidden; /* Prevent horizontal scroll */
}
.sidebar-enter {
  transform: translateX(100%);
}
.sidebar-exit {
  transform: translateX(0);
}
.vertical-carousel-page-container {
  padding: 5%;
  height: 100%;
}
.vertical-carousel-main-content {
  height: 100%;
  display: flex;
  flex-direction: column;
}
.heading-cross-icon {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.carousel-cards {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  // grid-template-columns: auto auto;
  gap: 10px;
  margin-top: 10px;
}
.carousel-card-content {
  box-shadow: 0px 0px 13.38px 0px #0000001f;
  padding: 6px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 5px;
  text-align: center;
  width: 100%;
  height: auto;
  margin-top: 10px;
  // border: 1px solid black;
}
.carousel-similar-img {
  width: 130px;
  height: 150px;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
.carousel-card-content h6 {
  font-family: Cormorant;
  font-size: 16px;
  font-weight: 500;
  color: #000000;
  margin: 0px;
}
.carousel-card-content p {
  font-family: Cormorant;
  font-size: 18px;
  font-style: italic;
  font-weight: 400;
  color: #363636;
  margin: 0px;
  margin-top: -10px;
}
.heading-cross-icon h5 {
  font-family: Cormorant;
  font-size: 33px;
  font-weight: 500;
  color: #000000;
}
.heading-cross-icon svg {
  color: #000000;
  font-size: 20px;
  cursor: pointer;
}
