.guest-checkout-page-container {
  width: 100%;
}
.guest-checkout-main-content {
  width: 100%;
  padding: 4%;
  padding-bottom: 7%;
  display: flex;
  justify-content: space-between;
  gap: 100px;
}
.guest-checkout-left-side-box {
  width: 50%;
}
.guest-checkout-left-side-box h3 {
  font-family: Cormorant;
  font-size: 36px;
  font-weight: 500;
  line-height: 44px;
  color: #000000;
}
.label-and-input-box-guest {
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;
  .error {
    color: red;
  }
}

.label-and-input-box-guest label {
  font-family: Lato;
  font-size: 18px;
  font-weight: 500;
  color: #595959;
  margin-top: 20px;
}
.label-and-input-box-guest input {
  border: 1px solid #c9c9c9;
  color: #595959;
  font-size: 18px;
  height: 48px;
  margin-top: 10px;
  width: 100%;
  padding: 0 20px;
  margin-bottom: 15px;
  position: relative;
}
.label-and-input-box-guest input[name="phone"] {
  padding-left: 60px; /* Increase padding to make space for the code */
  // border: 1px solid #c9c9c9;
  // height: 44px;
  // margin-top: 8px;
  // padding: 0px 20px;
  // font-size: 16px;
  // margin-bottom: 15px;
}
.label-and-input-box-guest .default-india-code-guest {
  position: absolute;
  color: #595959;
  font-size: 18px;
  z-index: 20;
  top: 21px;
  margin-right: 20px;
  left: 20px;
}
@media (max-width: 600px) {
  .guest-checkout-main-content {
    display: block;
    gap: 0px;
  }
  .guest-checkout-main-content {
    padding: 12% 4%;
    margin-bottom: 50px;
  }
  .guest-checkout-left-side-box {
    width: 100%;
  }
  .order-summary {
    width: 100%;
    margin-top: 50px;
  }
  .guest-checkout-left-side-box h3 {
    font-size: 26px;
  }

  .label-and-input-box-guest label {
    font-size: 15px;
  }

  .label-and-input-box-guest .payment-option h5 {
    font-size: 20px;
  }
  .emty-circle {
    width: 18px;
    height: 18px;
  }
  .circle-name h6 {
    font-size: 15px;
  }
  .bank-details p {
    font-size: 20px;
  }
  .country-field {
    margin-top: 25px !important;
    margin-bottom: 15px !important;
  }
}
