.intro-media-container {
  background-color: #e9e9e9;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 30px 80px;
}
.intro-media-container .heading-media {
  padding: 0;
  width: 100%;
}
.heading-media h2 {
  font-family: Lato;
  font-size: 18px;
  font-weight: 400;
  line-height: 25px;
  text-align: center;
  color: #595959;
  margin-bottom: 0;
}
.media-main-content {
  padding: 4% 5.5%;
  display: flex;
  gap: 20px;
  padding-bottom: 80px;
  width: 100%;
  // border: 1px solid black;
}
.media-menu-icons {
  display: flex;
  flex-direction: column;
  gap: 30px;
  width: 18%;
  // border: 1px solid black;
}
.conditional-render-div {
  width: 75%;
  // border: 1px solid black;
}
.media-icons-details {
  display: flex;
  gap: 15px;
  align-items: center;
}
.media-icons-details svg {
  font-size: 35px;
  color: #797979;
}
.media-icons-details p {
  font-family: Cormorant;
  font-size: 28px;
  font-weight: 400;
  color: #797979;
}
.media-icons-details {
  // Default styles for the icon details
  cursor: pointer; // Change cursor on hover
  &.active {
    p {
      color: #000000; // Active text color
    }
    svg {
      color: #000000;
    }
  }
}
.media-border-div {
  border-left: 1px solid #a6a6a6;
  height: 350px;
  position: relative;
}
.active {
  color: #0c0c0c;
}
@media (max-width: 992px) {
  .media-icons-details svg {
    font-size: 20px;
  }
  .media-icons-details p {
    font-size: 25px;
  }
  .media-border-div {
    height: 330px;
  }
}
@media (max-width: 768px) {
  .media-main-content {
    display: block;
    padding: 50px 20px;
  }
  .media-icons-details svg {
    font-size: 20px;
  }
  .media-icons-details p {
    font-size: 22px;
  }
  .media-border-div {
    display: none;
  }
  .media-menu-icons {
    width: 100%;
    flex-direction: row;
    overflow-x: auto;
    // overflow-x: hidden;
    scrollbar-width: none;
    white-space: nowrap;
  }
  .conditional-render-div {
    width: 100%;
  }
}
@media (max-width:576px) {
  .media-icons-details{
    gap: 10px;
  }
  .media-menu-icons {
    gap: 22px;
  }
}