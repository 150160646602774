.intro-terms-container {
  background-color: #e9e9e9;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 30px 80px;
}

.intro-terms-container .heading-terms {
  padding: 0;
  width: 100%;
}

.heading-terms h2 {
  font-family: "Lato", sans-serif;
  font-size: 18px;
  font-weight: 400;
  line-height: 25px;
  text-align: left;
  color: #595959;
  margin-bottom: 0;
  text-align: center;
}

.terms-content {
  padding: 50px 80px;
  max-width: 1200px;
  margin: 0 auto;
}

.terms-content h2 {
  font-family: Cormorant;
  font-size: 35px;
  font-weight: 500;
  line-height: 38.75px;
  letter-spacing: 0.005em;
  text-align: center;
  text-transform: uppercase;
}
.terms-content-details {
  ol {
    li {
      font-family: Lato;
      font-size: 18px;
      font-weight: 700;
      line-height: 32px;
      letter-spacing: 0.005em;
      text-align: left;
      text-underline-position: from-font;
      text-decoration-skip-ink: none;
      color: #595959;
    }
    h6 {
      font-family: Lato;
      font-size: 18px;
      font-weight: 700;
      line-height: 32px;
      letter-spacing: 0.005em;
      text-align: left;
      text-underline-position: from-font;
      text-decoration-skip-ink: none;
      color: #595959;
      margin-left: -25px;
    }
  }
  ul {
    li {
      font-family: Lato;
      font-size: 18px;
      font-weight: 400;
      line-height: 32px;
      letter-spacing: 0.005em;
      text-align: left;
      text-underline-position: from-font;
      text-decoration-skip-ink: none;
      color: #595959;
      list-style-type: disc;
      .email-link {
        color: #595959;
        text-decoration: underline;
      }
      .nested-list {
        ul {
          li {
            list-style-type: circle;
            margin-left: 20px;
          }
        }
      }
    }
    margin-bottom: 30px;
  }
}
.term-condition-contact-details {
  display: flex;
  flex-direction: column;
  gap: 30px;
  padding: 0px 60px;
  padding-bottom: 80px;
  h5 {
    font-family: Lato;
    font-size: 18px;
    font-weight: 700;
    line-height: 32px;
    letter-spacing: 0.005em;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: #595959;
  }
  p {
    font-family: Lato;
    font-size: 18px;
    font-weight: 400;
    line-height: 32px;
    letter-spacing: 0.005em;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: #595959;
    .email-link {
      color: #595959;
      text-decoration: underline;
    }
  }
}
@media screen and (max-width: 480px) {
  .terms-content {
    padding: 50px 30px;
    margin-bottom: 30px;
  }
  .terms-content h2 {
    font-size: 30px;
    margin-bottom: 40px;
  }
  .terms-content h6 {
    font-size: 25px;
    line-height: 30px;
    padding: 20px 0px;
  }
  .term-condition-contact-details {
    padding: 0px 0px;
  }
  // .terms-content p {
  //   margin-top: -20px;
  // }
}
