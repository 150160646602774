.cart-main-content {
  padding: 3% 4%;
  width: 100%;
}
.header-of-login-mobile-view {
  display: none;
}
/* Apply border-bottom only to the first cart */
.empty-cart-page {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 90vh;
  .empty-cart-content {
    .empty-cart-title {
      font-family: "Lato", sans-serif;
      font-weight: 500;
      font-size: 32px;
      margin-bottom: 10px;
    }
    .empty-cart-button {
      display: flex;
      justify-content: center;
      button {
        text-transform: uppercase;
        font-size: 14px;
      }
    }
  }
}
.quantity-selector{
  button{
    border-radius: 0px;
  }
}
.cart-border-bottom {
  border-bottom: 1px solid #d3d3d3;
}
.cart-main-content h4 {
  font-family: "Cormorant", serif;
  font-size: 26px;
  font-weight: 500;
  color: #000000;
}
.cart-left-right-box-content {
  display: flex;
  gap: 30px;
  margin-top: 25px;
}
.cart-left-box-content {
  width: 70%;
}
.cart-details-box {
  display: flex;
  gap: 30px;
  margin-top: 10px;
  padding-bottom: 40px;
  cursor: pointer;
}
.cart-image {
  width: 220px;
  height: 220px;
}
.cart-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.cart-text-content {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.title-price-div {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}
.title-text h5 {
  font-family: "Cormorant", sans-serif;
  font-size: 24px;
  font-weight: 500;
  color: #0c0c0c;
}
.cart-text-content p {
  font-family: "Lato", sans-serif;
  font-size: 15px;
  font-weight: 400;
  color: #595959;
}

.cartPrice p {
  font-family: "Cormorant", serif;
  font-size: 28px;
  font-style: italic;
  font-weight: 500;
  color: #0c0c0c;
}
/* Color, Size, and Address Info */
.color-size-address {
  margin-top: 15px;
}
.color-size-address p {
  font-family: "Lato", sans-serif;
  font-size: 15px;
  font-weight: 400;
  line-height: 18px;
  color: #595959;
}
/* Quantity Dropdown Button */
.cart-text-content button {
  border: 1.11px solid #c9c9c9;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: "Lato", sans-serif;
  font-size: 16px;
  font-weight: 400;
  color: #595959;
  background-color: transparent;
  width: 73px;
  height: 30px;
  padding: 3px 7px 3px 16px;
  margin-top: 20px;
  cursor: pointer;
}
.cart-text-content button svg {
  font-size: 23px;
  color: #111111;
}
/* Buttons for Remove and Add to Wishlist */
.detail-remove-add-button {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}
.detail-remove-add-button p {
  font-family: "Lato", sans-serif;
  font-size: 14px;
  font-weight: 400;
  color: #636262;
}
.remove-add-button {
  display: flex;
  gap: 10px;
  justify-content: space-between;
}
.remove-add-button p {
  font-family: Lato;
  font-size: 13px;
  font-weight: 400;
  color: #595959;
}
// right box style
.cart-right-box-content {
  margin-top: 40px;
  width: 30%;
}
.enter-code {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 10px 10px 22px;
  gap: 0px;
  opacity: 0px;
  border: 1px solid #c9c9c9;
}
.enter-code p {
  font-family: Lato;
  font-size: 14px;
  font-weight: 400;
  color: #636262;
}
.enter-code button {
  background: #000000;
  font-family: Lato;
  font-size: 12px;
  font-weight: 400;
  letter-spacing: 1px;
  color: #ffffff;
  padding: 10px 30px;
  display: flex;
  align-items: center;
}
.payment-card {
  margin-top: 25px;
  border: 1px solid #a5a3a3;
  border-radius: 2px 0px 0px 0px;
  border: 1px 0px 0px 0px;
  padding: 20px;
}
.total-price {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 10px;
  padding-bottom: 25px;
}
.total-price h5 {
  font-family: Cormorant;
  font-size: 18px;
  font-weight: 500;
  color: #000000;
}
.total-price p {
  font-family: Cormorant;
  font-size: 34px;
  font-weight: 600;
  color: #0c0c0c;
}
.emi-offer-div {
  margin-top: 10px;
  border-top: 2px solid rgb(138, 218, 247);
  border-right: 2px solid rgb(138, 218, 247);
  border-bottom: 2px solid rgb(138, 218, 247);
  border-left: 1px solid rgb(138, 218, 247);
  border-radius: 8px;
}
.emi-offer-div h5 {
  background-color: rgb(211, 235, 244);
  padding: 8px;
  font-size: 15px;
  color: #242424;
}
.view-plan-offer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: transparent;
  padding: 8px;
}
.view-plan-offer p {
  font-size: 13px;
  color: #595959;
}
.view-plan-offer h6 {
  font-size: 15px;
  color: #242424;
}
.payments-button {
  margin-top: 90px;
  padding: 0px 15px;
  width: 100%;
}
.proceed-button {
  width: 100%;
  padding: 10px 0px;
  font-family: Lato;
  font-size: 12px;
  font-weight: 400;
  letter-spacing: 1px;
  color: #ffffff;
  background: #000000;
}
.back-button {
  background: transparent;
  border: 1px solid #000000;
  width: 100%;
  padding: 10px 0px;
  font-family: Lato;
  font-size: 12px;
  font-weight: 400;
  letter-spacing: 1px;
  color: #000000;
  margin-top: 20px;
}
.quantity-dropdown {
  background-color: #ffffff;
  box-shadow: 0px 4px 10px 0px #00000033;
  padding: 10px;
  margin-top: 5px;
  position: absolute;
  width: 70px;
  z-index: 10;
}
.quantity-dropdown li {
  list-style: none;
  color: #636262;
  font-family: Lato;
  font-size: 14px;
  font-weight: 400;
  margin-top: 15px;
  text-align: center;
  cursor: pointer;
}
.mobile-view-left-cart {
  display: none;
}
.cart-left-box-content-mob {
  display: none;
  width: 100%;
  margin-bottom: 20px;
}
.cart-details-box-mob {
  display: flex;
  gap: 10px;
  margin-top: 30px;
  padding-bottom: 30px;
}
.cart-border-bottom-mob {
  border-bottom: 1px solid #d3d3d3;
}
.cart-image-mob {
  width: 95%;
  height: 100%;
  object-fit: cover;
  // border: 1px solid black;
  // width: 85px;
  // height: 112px;
}
.cart-image-mob img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.cart-text-content-mob {
  display: flex;
  flex-direction: column;
  gap: 5px;
  // justify-content: space-between;
}
.title-price-div-mob {
  display: block;
  // justify-content: space-between;
  align-items: flex-start;
  .title-text-mob {
    // border: 1px solid black;
    // padding: 2px 0;
    h5 {
      font-family: "Cormorant", sans-serif;
      font-size: 16px;
      font-weight: 500;
      color: #0c0c0c;
    }
  }
  .cartPrice-mob {
    // border: 1px solid black;
    padding: 0;
    display: flex;
    align-items: flex-start;
    // margin-top: -0.2rem;
    font-family: "Cormorant", serif;
    font-size: 22px;
    font-style: italic;
    font-weight: 500;
    color: #0c0c0c;
  }
}
.description-mob {
  // margin-top: 10px;
  font-family: "Lato", sans-serif;
  font-size: 10px;
  font-weight: 400;
}
.color-size-address-mob {
  // margin-top: 15px;
}
.color-size-address-mob p {
  font-family: "Lato", sans-serif;
  font-size: 12px;
  font-weight: 400;
  line-height: 12px;
  color: #595959;
}
.quantity-selector-mob {
  display: flex;
  gap: 10px;
  align-items: center;
  // justify-content: space-between;
  button {
    display: flex;
    // border-radius: 0;
    background: transparent;
    color: #595959;
    border: 0.91px solid #c9c9c9;
    padding: 5px 10px;
    border-radius: 0px !important; 
  }
  svg {
    margin-left: 10px;
  }
}
.quantity-selector p {
  width: 55%;
  font-size: 12px !important;
  margin-top: 20px !important;
}
.text-p-mob {
  font-family: "Lato", sans-serif;
  font-weight: 400;
  font-size: 8px;
  line-height: 9.6px;
}
.detail-remove-add-button-mob {
  display: flex;
  justify-content: space-between;
  align-items: center;
  // margin-top: 10px;
  width: 100%;
  background: #e9e9e9;
  padding: 10px;
}
.detail-remove-add-button-mob p {
  font-family: "Lato", sans-serif;
  font-size: 12px;
  font-weight: 400;
  color: #636262;
}
.remove-add-button-mob {
  display: flex;
  // gap: 10px;
  justify-content: space-around;
  align-items: center;
  // gap: 20px;
  width: 100%;
  padding: 0 22px;
}
.divider-mob {
  border-left: 1px solid #595959;
  height: 25px;
  // // margin-left: 38px;
  // // margin-right: 38px;
  font-size: 30px;
}
@media (max-width: 992px) {
  .cart-main-content h4 {
    font-size: 22px;
  }
  .cart-left-right-box-content {
    gap: 20px;
  }
  .title-text h5 {
    font-size: 18px;
  }
  .cart-text-content p {
    font-size: 13px;
  }
  .cartPrice p {
    font-size: 23px;
  }
  .color-size-address p {
    font-size: 13px;
  }
  .cart-text-content button {
    font-size: 14px;
    width: 60px;
  }
  .cart-text-content button svg {
    font-size: 20px;
  }
  .detail-remove-add-button p {
    font-size: 13px;
  }
  .remove-add-button p {
    font-size: 11px;
  }
  // right box style
  .enter-code button {
    padding: 7px 20px;
  }
  .total-price h5 {
    font-size: 15px;
  }
  .total-price p {
    font-size: 25px;
  }
  .emi-offer-div h5 {
    font-size: 13px;
  }
  .view-plan-offer p {
    font-size: 12px;
  }
  .view-plan-offer h6 {
    font-size: 13px;
  }
  .proceed-button {
    font-size: 10px;
  }
  .back-button {
    font-size: 10px;
  }
  .quantity-dropdown {
    width: 60px;
  }
  .quantity-dropdown li {
    font-size: 12px;
  }
  .cart-left-right-box-content {
    gap: 10px;
  }
  .cart-details-box {
    gap: 10px;
  }
  .cart-main-content {
    padding: 3% 2%;
  }
}
@media (max-width: 768px) {
  .cart-left-right-box-content {
    display: block;
  }
  .cart-left-box-content {
    width: 100%;
  }
  .cart-right-box-content {
    width: 100%;
    display: flex;
    justify-content: center;
  }
  .cart-main-content {
    padding: 3% 4%;
  }
  .center-the-cart {
    width: 60%;
  }
  .header-of-login-mobile-view {
    display: block;
    background: #e9e9e9;
    height: 100px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .header-of-login {
    display: none;
  }
  .header-of-login-mobile-view p {
    font-family: Lato;
    font-size: 18px;
    font-weight: 400;
    color: #595959;
    text-align: center;
    // padding-left: 100px;
  }
  .mobile-view-left-cart {
    display: block;
  }
  // .cart-left-box-content {
  //   display: none;
  // }
  .quantity-selector {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .quantity-selector p {
    width: 55%;
    font-size: 12px !important;
    margin-top: 20px !important;
  }
  .detail-remove-add-button {
    background: #e9e9e9;
    width: 100%;
    padding: 12px 50px;
    gap: 20px;
    justify-content: center;
  }
  .remove-button {
    border-right: 2px solid #595959;
    padding-right: 20px;
  }
  .abb-button {
    padding-left: 20px;
  }
  .quantity-selector p {
    font-family: "Lato", sans-serif;
    font-size: 14px;
    font-weight: 400;
    color: #636262;
    margin-top: 10px;
  }
  .cart-details-box {
    gap: 20px;
    padding-bottom: 0px;
  }
}
@media (min-width: 400px) and (max-width: 500px) {
  .cart-main-content {
    padding: 12% 4%;
    margin-bottom: 40px;
  }
  .cart-left-box-content {
    display: none;
  }
  .cart-left-box-content-mob {
    display: block;
  }
  .center-the-cart {
    width: 100%;
  }
  .remove-add-button-mob {
    gap: 30px;
  }
  .divider-mob {
    margin-left: 35px;
    margin-right: 35px;
  }
  .color-size-address-mob p {
    line-height: 20px;
  }
  .text-p-mob {
    font-size: 12px;
    line-height: normal;
    color: #595959;
  }
  .description-mob {
    font-size: 11px;
    color: #595959;
  }
  .empty-cart-page {
    .empty-cart-content {
      .empty-cart-title {
        font-size: 22px;
      }
      .empty-cart-button {
        button {
          font-size: 12px;
        }
      }
    }
  }
}

@media (min-width: 300px) and (max-width: 400px) {
  .cart-left-box-content {
    display: none;
  }
  .cart-left-box-content-mob {
    display: block;
  }
  .center-the-cart {
    width: 100%;
  }
  .color-size-address-mob p {
    line-height: 25px;
  }
  .text-p-mob {
    font-size: 12px;
    line-height: normal;
    color: #595959;
  }
  .cart-main-content {
    padding: 12% 4%;
    margin-bottom: 40px;
  }
  .empty-cart-page {
    .empty-cart-content {
      .empty-cart-title {
        font-size: 22px;
      }
      .empty-cart-button {
        button {
          font-size: 12px;
        }
      }
    }
  }
}
@media (min-width: 300px) and (max-width: 350px) {
  .title-price-div-mob {
    .title-text-mob {
      h5 {
        font-size: 14px;
      }
    }
    .cartPrice-mob {
      font-size: 20px;
    }
  }
  .detail-remove-add-button-mob {
    p {
      font-size: 12px;
    }
  }
  .remove-add-button-mob {
    gap: 10px;
    padding: 0 20px;
  }
  .divider-mob {
    margin-left: 35px;
    margin-right: 35px;
  }
  .color-size-address-mob p {
    line-height: 25px;
  }
  .text-p-mob {
    font-size: 12px;
    line-height: normal;
    color: #595959;
  }
  .cart-main-content{
    padding: 12% 4%;
  }
}
@media (max-width: 826px) {
  .text-p {
    font-size: 10px;
  }
  .remove-add-button p {
    font-size: 10px;
  }
}
