.intro-about-container {
  background-color: #e9e9e9;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 30px 80px;
}

.intro-about-container .heading-about {
  padding: 0;
  width: 100%;
}

.heading-about h2 {
  font-family: Lato;
  font-size: 18px;
  font-weight: 400;
  line-height: 25px;
  text-align: center;
  color: #595959;
  margin-bottom: 0;
}

.about-us-1 {
  padding: 50px;
  background-color: #fdf9ed;
  margin-top: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 3rem;
}

.about-content {
  width: 60%;
}

.about-content h2 {
  font-family: Cormorant;
  font-size: 44px;
  font-weight: 600;
  line-height: 53.28px;
  text-align: left;
  color: #0c0c0c;
  text-transform: uppercase;
  margin: 10px 0px;
}

.about-content p {
  font-family: Lato;
  font-size: 18px;
  font-weight: 400;
  line-height: 41px;
  text-align: left;
  color: #595959;
}

.about-image {
  width: 480px;
  height: 368px;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.about-us-2 {
  padding: 50px;
}

.about-us-2 .about-text {
  text-align: center;
}

.about-text h2 {
  font-family: Cormorant;
  font-size: 42px;
  font-weight: 600;
  line-height: 50.86px;
  text-align: center;
  color: #0c0c0c;
  margin: 10px 0px;
}

.about-text p {
  font-family: Lato;
  font-size: 20px;
  font-weight: 400;
  line-height: 35px;
  text-align: center;
  color: #595959;
}

.about-icon-container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4rem;
  margin-bottom: 50px;
}

.about-icon-container .icon-box {
  background-color: #f7f7f7;
  text-align: center;
  padding: 40px;
  width: 40%;
}

.icon-content h4 {
  font-family: Cormorant;
  font-size: 32px;
  font-weight: 500;
  line-height: 43.6px;
  color: #232323;
  text-transform: uppercase;
  margin: 10px 0px;
}

.icon-content p {
  font-family: Lato;
  font-size: 22px;
  font-weight: 400;
  line-height: 33.6px;
  text-align: center;
  color: #595959;
}

.icon-section {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px 50px;
}

.text-container {
  width: 50%;
}

.text-container {
  width: 551px;
  height: 272px;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
.text-container h2 {
  font-family: Cormorant;
  font-size: 32px;
  font-weight: 600;
  line-height: 38.75px;
  text-align: left;
}

.text-container p {
  font-family: Lato;
  font-size: 20px;
  font-weight: 400;
  line-height: 35px;
  text-align: left;
  color: #595959;
}

.icon-box-container {
  width: 60%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 1.5rem;
}

.icon-box-container .icon-box {
  width: 20%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.icon-box h4 {
  font-family: Cormorant;
  font-size: 18px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 0.01em;
  text-align: center;
  margin: 20px 0;
}

.final-text {
  padding: 50px;
}

.final-text h3 {
  font-family: Cormorant;
  font-size: 40px;
  font-weight: 600;
  line-height: 48.44px;
  text-align: left;
  color: #0c0c0c;
  text-transform: uppercase;
  margin: 10px 0px;
}

.final-text p {
  font-family: Lato;
  font-size: 18px;
  font-weight: 400;
  line-height: 30px;
  text-align: left;
  color: #595959;
}

@media screen and (max-width: 820px) {
  .about-us-1 {
    flex-direction: column;
    padding: 50px;
  }

  .about-content {
    width: 100%;
  }

  .about-content h2 {
    text-align: center;
  }

  .about-content p {
    text-align: center;
  }

  .about-image {
    width: 100%;
  }

  .icon-section {
    flex-direction: column;
    gap: 2rem;
  }

  .text-container,
  .icon-box-container {
    width: 100%;
  }

  .text-container h2 {
    text-align: center;
  }

  .text-container p {
    text-align: center;
    font-size: 18px;
  }

  .final-text h3 {
    text-align: center;
  }

  .final-text p {
    text-align: center;
  }
}

@media screen and (max-width: 480px) {
  .about-us-1 {
    padding: 40px 10px;
  }

  .about-content h2 {
    font-size: 30px;
  }

  .about-content p {
    font-size: 16px;
    line-height: 30px;
  }

  .about-us-2 {
    padding: 10px;
  }

  .about-icon-container {
    flex-direction: column;
  }

  .about-icon-container .icon-box {
    width: auto;
  }

  .icon-box h4 {
    font-size: 25px;
  }

  .icon-content p {
    font-size: 16px;
  }

  .about-text h2 {
    font-size: 30px;
  }

  .about-text p {
    font-size: 16px;
  }

  .icon-section {
    padding: 20px;
  }

  .text-container p {
    font-size: 12px;
    line-height: 30px;
  }

  .icon-box-container .icon-box {
    width: 45%;
  }

  .icon-box h4 {
    text-align: center;
    font-size: 14px;
  }

  .icon-section {
    padding: 20px;
  }

  .text-container p {
    font-size: 12px;
    line-height: 30px;
  }

  .icon-box-container .icon-box {
    width: 45%;
  }

  .icon-box h4 {
    text-align: center;
    font-size: 16px;
  }

  .final-text {
    padding: 10px;
  }

  .final-text h3 {
    font-size: 30px;
  }

  .final-text p {
    font-size: 16px;
  }
}
