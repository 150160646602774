.breadcrumb {
  width: 97%;
  margin: 20px 80px;
  font-family: "Lato", system-ui;
  font-size: 17px;
}

.breadcrumb a {
  text-decoration: none;
  color: #595959;
}
.viewCartBoxContainer {
  margin-top: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  // border: 1px solid black;
  padding: 0 80px;
  margin-bottom: 20px;
  .addToCartBox {
    border: 1.11px solid #c9c9c9;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 10px 15px;
    p {
      font-family: "Lato", sans-serif;
      font-weight: 400;
      font-size: 20px;
      color: #595959;
    }
    button {
      border-radius: 0%;
      font-size: 14px;
      font-family: "Lato", sans-serif;
      padding: 10px 40px;
    }
  }
}
/* Container Flexbox */
.prodContainer {
  display: flex;
  justify-content: flex-start;
  gap: 40px;
  width: 100%;
  margin: 0 0;
  padding: 5px 80px 80px 80px;
  // border: 1px solid black;
}

/* Left Column for Images */
.left-column {
  width: 40%;
  display: flex;
  flex-direction: row;
  gap: 10px;
}

.thumbnail-gallery {
  display: flex;
  flex-direction: column;
  // justify-content: space-between;
  gap: 10px;
  height: auto;
  overflow: scroll;
}

.thumbnail-gallery::-webkit-scrollbar {
  display: none;
}

/* For Internet Explorer and Edge (Legacy) */
.thumbnail-gallery {
  -ms-overflow-style: none;
}

/* Firefox */
.thumbnail-gallery {
  scrollbar-width: none;
}

.thumbnail-gallery img {
  width: 100%;
  max-width: 100px;
  object-fit: cover;
  cursor: pointer;
  border: 1px solid #ddd;
  transition: border 0.3s;
}

.thumbnail-gallery img:hover {
  border-color: #333; /* Add border on hover */
}

.main-image {
  display: flex;
  position: relative;
  width: 100%;
}

.main-image img {
  width: 100%;
  object-fit: cover;
  border: 1px solid #ddd;
}

.label {
  position: absolute;
  top: 05%;
  right: 0;
  background-color: #fff;
  padding: 8px 70px;
  font-size: 20px;
  font-weight: bold;
  color: #333;
  border: 1px solid #ddd;
  font-family: "Cormorant", serif;
  font-style: italic;
}

/* Right Column for Product Info */
.right-column {
  width: 55%;
  padding-left: 2px;
  // border: 1px solid black;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.ship-share-box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  // border: 1px solid black;
  width: 105%;
}
.right-column .ship-box {
  padding: 6px;
  border: 1px solid black;
  color: #595959;
  font-family: "Lato", system-ui;
  font-size: 13px;
  display: inline-block;
  margin-bottom: 10px;
}
.share-prod {
  position: relative;
  ul {
    position: absolute;
    list-style: none;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: #ffffff;
    li {
      // border: 1px solid black;
      padding: 8px 2px;
      svg {
        width: 30px;
        height: 30px;
      }
    }
  }
}
.prod-title-review {
  // border: 1px solid black;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  // border: 1px solid black;
}
.main-review-rating {
  display: flex;
  margin-top: 5px;
  gap: 15px;
  .prod-tot-reviews {
    font-family: "Lato", sans-serif;
    font-size: 18px;
    font-weight: 400;
    // line-height: 29.09px;
    // text-align: left;
    color: #595959;
    display: flex;
    align-items: center;
    // border: 1px solid black;
    height: auto;
    // padding: 0;
  }
  .star-rating {
    display: flex;
    align-items: center;
    justify-content: center;
    height: auto;
    font-size: 20px;
    margin-top: 2px;
    svg {
      // border: 1px solid black;
      color: #000;
      // padding: 1px;
      display: inline-flex;
      align-items: center;
      width: 24px;
      font-size: 20px;
    }
  }
}
.product-title {
  // margin-bottom: 10px;

  color: #000;
  font-family: "Cormorant", serif;
  font-size: 35px;
  font-weight: 500;
}
.product-title-mob {
  display: none;
}
.price {
  margin-top: 2px;
  font-size: 22px;
  color: #363636;
  margin-bottom: 5px;
  font-family: "Lato", sans-serif;
  font-weight: 500;
  font-style: italic;
  // border: 1px solid black;
  padding-top: 0;
  .tax {
    display: block;
  }
  .original-price {
    text-decoration: line-through;
    color: #b0b0b0; // Grey color for the original price
    margin-right: 8px;
  }
  .sale-price {
    font-weight: 600;
  }
}
.price-item {
  margin-top: 2px;
  font-size: 22px;
  color: #363636;
  margin-bottom: 5px;
  font-family: "Lato", sans-serif;
  font-weight: 500;
  font-style: italic;
  // border: 1px solid black;
  padding-top: 0;
  .tax {
    display: block;
  }
  .original-price {
    text-decoration: line-through;
    color: #b0b0b0; // Grey color for the original price
    margin-right: 8px;
  }
  .sale-price {
    font-weight: 600;
  }
}
// .price span {
//   font-family: Lato;
//   font-size: 16px;
//   font-weight: 400;
//   // line-height: 19.2px;
//   letter-spacing: 0.01em;
//   text-align: left;
//   font-style: normal;
//   color: #595959;
// }

.price span img {
  vertical-align: middle;
  margin: 0px 5px;
}
.price-mob {
  display: none;
  .original-price {
    text-decoration: line-through;
    color: #b0b0b0; // Grey color for the original price
    margin-right: 8px;
  }
}
.rating-review {
  font-size: 15px;
  margin-bottom: 10px;
  color: #bcbcbc;
  display: flex;
  align-items: center;
  gap: 2px;
}
.rating {
  font-size: 15px;
  display: none;
  // margin-bottom: 10px;
  color: #000;
  // display: flex;
  // align-items: center;
  // justify-content: space-between;
}

.rating p {
  font-family: Lato;
  font-size: 18px;
  font-weight: 400;
  line-height: 29.09px;
  text-align: left;
  color: #595959;
}
.rating svg {
  color: #000;
  padding: 1px;
  font-family: Lato;
  font-size: 18px;
  font-weight: 400;
  line-height: 29.09px;
  text-align: left;
}
.description {
  margin-top: 15px;
  margin-bottom: 15px;
  font-size: 18px;
  line-height: 1.5;
  color: #666;
  font-family: Lato;
  font-weight: 600;
}

.product-details {
  // display: flex;
  // align-items: center;
  // justify-content: space-between;
  margin-bottom: 15px;
  // border: 1px solid black;
  width: 100%;
  padding: 10px 20px;
  .label {
    font-weight: bold;
    margin-bottom: 5px;
  }
  .tableDetails {
    border: none;
    th,
    td {
      font-family: "Lato", sans-serif;
      text-align: left;
      font-size: 18px;

      color: #595959;
    }
    th {
      padding-right: 10px;
      font-weight: 600;
    }
    td {
      font-weight: 400;
    }
  }
}

.product-details p {
  margin-bottom: 5px;
  font-size: 14px;
  color: #595959;
  font-family: Lato;
  font-size: 18.62px;
  font-weight: 400;
  line-height: 29.09px;
  text-align: left;
}

.product-details span {
  font-family: Cormorant;
  font-size: 22.11px;
  font-style: italic;
  font-weight: 400;
  line-height: 29.09px;
  text-align: left;
  color: #494949;
}

/* Color and Size Selection */

.color-selection {
  margin-top: 8px;
  overflow: hidden;
  margin-bottom: 10px;
  p {
    font-family: Cormorant;
    font-size: 25px;
    font-weight: 500;
    line-height: 24.22px;
    text-align: left;
    color: #000;
    margin-bottom: 12px;
  }
}
.color-options {
  display: flex;
  gap: 10px; /* Add spacing between images */
  overflow-x: auto; /* Horizontal scrolling */
  white-space: nowrap; /* Prevent wrapping of items */
  padding-bottom: 10px; /* Prevent scroll bar from overlapping content */
  scroll-snap-type: x mandatory; /* Optional: smooth snapping for scroll */
  .color-image {
    width: 60px;
    height: 80px;
    cursor: pointer;
    overflow: hidden;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      margin-right: 20px;

      transition: transform 0.4s ease;
    }
  }
  .color-image-selected {
    border: 3px solid black;
  }
}
.color-options::-webkit-scrollbar {
  height: 8px; /* Adjust scrollbar height */
}

.color-options::-webkit-scrollbar-thumb {
  background: #ccc; /* Custom scrollbar color */
  border-radius: 4px;
}

.color-options::-webkit-scrollbar-thumb:hover {
  background: #888; /* Hover state for scrollbar */
}
.color-image:hover img {
  // border: 1px solid black;
  transform: scale(1.1); /* Zoom in the image */
  // border: 3px solid black;
}
.color-options .size-selection {
  margin-top: 10px;
  margin-bottom: 12px;
  width: 55%;
}

.size-head {
  display: flex;
  // justify-content: space-between;
  align-items: center;
  width: 50%;
  gap: 20px;
}

.size-head p {
  font-family: Cormorant;
  font-size: 25px;
  font-weight: 500;
  line-height: 24.22px;
  text-align: left;
  margin-bottom: 12px;
}

.size-head a {
  font-family: Cormorant;
  font-size: 22px;
  font-weight: 500;
  line-height: 21.8px;
  text-align: left;
  margin-bottom: 12px;
  margin-right: 60px;
  text-decoration: underline;
  color: #000;
}

.size-selection button {
  padding: 8px 25px;
  font-size: 14px;
  border: 1px solid #333;
  background-color: #fff;
  margin-right: 10px;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
  color: #000;
  border-radius: 0%;
}

.size-selection button:hover {
  background-color: #333;
  color: #fff;
}

.size-selection button.active {
  background-color: #333;
  color: #fff;
}
.size-selection {
  .size-selected {
    background: #000;
    color: #fff;
  }
}
/* Quantity and CTA Buttons */
.quantity-section {
  display: flex;
  flex-direction: column;
  // display: block;
  margin-top: 10px;
  margin-bottom: 12px;
  padding: 0;
}
.custom-select-wrapper {
  padding: 0%;
  position: relative;
  display: inline-block;
  width: 90px;
}
.custom-select {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  background-color: white;
  border: 1px solid #000;
  padding: 0;
  font-size: 14px;
  width: 100%;
  cursor: pointer;
  border-radius: 0;
  position: relative;
}

.custom-select-wrapper::after {
  content: "";
  position: absolute;
  top: 50%;
  right: 10px;
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid #000; /* Black arrow */
  pointer-events: none; /* So the arrow doesn't interfere with clicking */
  transform: translateY(-50%);
}

.quantity-section select:focus {
  outline: none;
  border-color: #000; /* Border color on focus */
  border-radius: 0%;
  //
}

.quantity-section p {
  font-family: Cormorant;
  font-size: 25px;
  font-weight: 500;
  line-height: 24.22px;
  text-align: left;
  margin-bottom: 12px;
}

.quantity-section select {
  padding: 8px 10px;
  font-family: Lato;
  font-size: 14px;
  font-weight: 400;
  line-height: 19.2px;
  text-align: left;
}
.pincode-check-container {
  margin-bottom: 20px;
  .error-message-pincode {
    // text-align: center;
    width: 80%;
    // border: 1px solid black;
  }
  .success-message-pincode {
    h4 {
      font-weight: 600;
      font-family: "Lato", sans-serif;
      color: green;
    }
    span {
      font-size: 12px;
      font-family: "Lato", sans-serif;
      font-weight: normal;
    }
  }
}

.pincode-check {
  display: flex;
  align-items: center;
  // margin-bottom: 20px;
}

.pincode-check p {
  font-family: Lato;
  font-size: 18px;
  font-weight: 300;
  line-height: 19.2px;
  text-align: left;
  margin-right: 20px;
}

.pincode-check input {
  padding: 10px;
  font-size: 14px;
  border: 1px solid #ccc;
  text-align: center;
  width: 115px;
}

#pincode-input::placeholder {
  text-align: center;
  color: #595959;
  font-family: Lato;
  font-size: 14px;
  font-weight: 500;
}

.cta-buttons {
  display: flex;
  gap: 35px;
  margin-bottom: 20px;
  // justify-content: space-between;
  // width: 1000%;
  // border: 1px solid black;
}
.out-of-stock-btn {
  width: 100%;
  button {
    padding: 15px 90px;
    font-size: 14px;
    font-weight: bold;
    border: 1px solid #333;
    background-color: #595959;
    color: #fff;
    cursor: not-allowed;
    transition: background-color 0.3s, color 0.3s;
    font-family: "Lato", system-ui;
    text-transform: uppercase;
    font-weight: 400;
    border-radius: 0;
    width: 528px;
  }
}

.cta-buttons button {
  padding: 15px 90px;
  font-size: 14px;
  font-weight: bold;
  border: 1px solid #333;
  background-color: #fff;
  color: #333;
  // cursor:cr;
  transition: background-color 0.3s, color 0.3s;
  font-family: "Lato", system-ui;
  text-transform: uppercase;
  font-weight: 400;
  border-radius: 0;
  width: 325px;
}
.cta-buttons button:disabled {
  background-color: #ccc; /* Light gray background */
  color: #666; /* Dim text color */
  cursor: not-allowed; /* Change cursor to indicate the button is disabled */
  opacity: 0.6;
}

.cta-buttons button:hover {
  background-color: #333;
  color: #fff;
}

.cta-buttons button.buy-now-product {
  background-color: #000;
  color: #fff;
}

.cta-buttons button.buy-now-product:hover {
  background-color: #555;
}

.extra-info {
  display: flex;
  gap: 15px;
  width: 100%;
  // justify-content: space-between;
  .extra-info-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
    span {
      margin-right: 15px;
      font-family: Cormorant;
      font-size: 22.11px;
      font-style: italic;
      font-weight: 400;
      line-height: 29.09px;
      text-align: left;
      color: #494949;
    }
  }
}

.tab-container {
  width: 97%;
  margin: 10px auto 20px auto;
  padding: 0 50px;
}

/* Style the tab */
.tab {
  overflow: hidden;
  display: flex;
  gap: 10px;
  border-bottom: 1px solid #595959;
}
.tab-container-list {
  display: none;
}

/* Style the buttons that are used to open the tab content */
.tab button {
  background-color: inherit;
  float: left;
  border: none;
  outline: none;
  cursor: pointer;
  padding: 14px 0px;
  transition: 0.3s;
  margin-right: 2rem;
  font-family: Lato;
  font-size: 18px;
  font-weight: 400;
  line-height: 29.09px;
  text-align: left;
  color: #a3a3a3;
}

/* Change background color of buttons on hover */
.tab button:hover {
  color: #000;
}

/* Create an active/current tablink class */
.tab button.active {
  color: #000;
}

/* Style the tab content */
.tabcontent {
  display: none;
  padding: 20px 0px;
  border-top: none;
}

.tabcontent p {
  font-family: Lato;
  font-size: 18px;
  font-weight: 400;
  line-height: 29.09px;
  text-align: left;
  color: #595959;
}

.maintenance-tips {
  margin: auto;
  background-color: #fff;
  padding: 10px 20px;
  border-radius: 8px;

  .rug-care {
    margin-bottom: 30px;
    h5 {
      color: #595959;
      font-family: "Lato", sans-serif;
      font-size: 18px;
      margin-bottom: 8px;
      font-weight: 600;
    }
    ul {
      margin-left: 20px;
      li {
        color: #595959;
        font-family: "Lato", sans-serif;
        font-size: 18px;
        margin-bottom: 8px;
        font-weight: 400;
      }
    }
  }
}

.testimonial-review-slider {
  position: relative;
  margin: auto;
  overflow: hidden;
  // padding: 10px 20px;
}

.testimonial-slide {
  display: none;
  padding: 20px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  background-color: #fff;
  border-radius: 8px;
  text-align: left;
  position: relative;
}

.testimonial-slide.active {
  display: block;
}

.quote-mark {
  width: 20%;
  margin-left: 3%;
}

.testimonial-content {
  padding-left: 40px; /* Space for the quote mark */
}

.author {
  margin-top: 10px;
  font-family: Lato;
  font-size: 18px;
  font-weight: 600;
  line-height: 30px;
  text-align: left;
}

.author span {
  display: block;
  font-family: Lato;
  font-size: 18px;
  font-weight: 400;
  line-height: 30px;
  text-align: left;
  color: #595959;
}

.rating-star {
  margin-top: 10px;
}

.rating-star span {
  font-size: 1.4rem;
  color: #595959;
  padding: 2px;
}

.rating-star span.active {
  color: #595959;
}

.testimonial-review-slider button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: transparent;
  border: none;
  font-size: 24px;
  cursor: pointer;
}

.prev-btn {
  left: 0;
  color: #000;
}

.next-btn {
  right: 0;
  color: #000;
}

.review-form-container {
  background-color: #fff;
  padding: 10px 20px;
  border-radius: 8px;
  width: 95%;
  // border: 1px solid black;
}

.review-form-container h2 {
  margin-bottom: 20px;
  font-family: Cormorant;
  font-size: 37px;
  font-weight: 500;
  line-height: 44.24px;
  text-align: left;
}

.rating-container {
  margin-bottom: 20px;
}

.rating-container .rating {
  display: flex;
  justify-content: start;
}

.star {
  font-size: 2rem;
  color: #ccc;
  cursor: pointer;
  transition: color 0.2s;
}

.star:hover,
.star.selected {
  color: #ccc;
}

textarea {
  width: 100%;
  height: 100px;
  margin-bottom: 20px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  resize: none;
}

.review-form-container input[type="text"],
.review-form-container input[type="email"] {
  width: 100%;
  padding: 10px;
  margin-bottom: 20px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

button {
  background-color: black;
  color: white;
  padding: 10px 15px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
}

.review-form-container button:hover {
  background-color: #333;
}
.why-choose {
  font-family: "Lato", sans-serif;
  font-size: 18px;
  font-weight: 400;
  letter-spacing: 0.01em;
  padding: 10px 20px;
}
.shippingInfo {
  padding: 10px 30px;

  ul {
    li {
      font-family: "Lato", sans-serif;
      font-size: 18px;
      line-height: 29px;
      font-weight: 400;
      color: #595959;
    }
  }
}
.product-dec {
  width: 97%;
  margin: 0 auto 20px auto;
  padding: 20px 50px 0 50px;
  .similarProduct-carousel {
    position: relative;
    width: 100%;
    margin: 20px auto 0 auto;
    overflow: hidden;

    // border: 1px solid black;
  }
  .prev-similar-btn,
  .next-similar-btn {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background: transparent;
    //   color: white;
    border: none;
    padding: 15px;
    cursor: pointer;
    z-index: 10;
  }

  .prev-similar-btn {
    left: -40px;
    // right: 500px;
  }

  .next-similar-btn {
    right: -20px;
  }
  .similar-product-wrapper {
    width: 100%;
    overflow: hidden;
    margin: 0px auto;
  }
}

.heading-price {
  // display: flex;
  // justify-content: space-between;
  // align-items: center;
  margin: 5px 0px;
}

.product-dec h2 {
  font-family: Cormorant;
  font-size: 36px;
  font-weight: 500;
  line-height: 43.6px;
  text-align: left;
  text-transform: uppercase;
  margin-bottom: 20px;
}

.product-row {
  display: flex;
  align-items: flex-start;
  cursor: pointer;
  transition: transform 0.3s ease-in-out;
  gap: 1rem;
  margin-bottom: 20px;
  // border: 1px solid black;
  // padding: 20px;
}

.product-item {
  // flex: 1 1 26%; /* 4 items per row */
  background-color: #f8f9fa;
  min-width: 24%;
  max-width: 24%;
  padding: 10px;
  box-sizing: border-box;
  cursor: pointer;
  overflow: hidden;
  // margin-bottom: 20px;
}

.similar-product-image {
  width: 100%;
  height: 380px;
  overflow: hidden;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: transform 0.4s ease;
  }
}
.similar-product-image:hover img {
  transform: scale(1.1); /* Zoom in the image */
}
.product-item h3 {
  font-family: Cormorant;
  font-size: 24px;
  font-weight: 500;
  line-height: 33.73px;
  text-align: left;
  max-width: 290px;
  white-space: nowrap; /* Prevent text wrapping */
  overflow: hidden; /* Hide the overflowing text */
  text-overflow: ellipsis; /* Add ellipses (...) for truncated text */
}

.product-item .heading-price p {
  font-family: "Lato", sans-serif;
  font-size: 16px;
  font-style: italic;
  font-weight: 400;
  line-height: 29.09px;
  text-align: left;
}

.product-item p {
  font-family: Lato;
  font-size: 16px;
  font-weight: 400;
  line-height: 30.99px;
  text-align: left;
  color: #595959;
}

.product-dec-2 {
  width: 97%;
  margin: 40px auto 40px auto;
  padding: 0 50px 50px 50px;
}

.product-dec-2 h2 {
  font-family: Cormorant;
  font-size: 36px;
  font-weight: 500;
  line-height: 43.6px;
  text-align: left;
  text-transform: uppercase;
  margin-bottom: 20px;
}

.recently-view-dec {
  display: flex;
  align-items: center;
  gap: 1rem;
  .recently-item {
    min-width: 24%;
    max-width: 24%;
    padding: 10px;
    width: 24%;
    box-sizing: border-box;
    padding: 10px;
    text-align: center;
    position: relative;
    overflow: hidden;
    border: 1px solid #cccccc;
    cursor: pointer;
  }
}

.recently-view-dec .recently-item .item-image .overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 18px;
  text-transform: uppercase;
  opacity: 0; /* Initially hidden */
  transition: opacity 0.3s ease; /* Smooth fade-in transition */
}

.recently-view-dec .recently-item:hover .item-image .overlay {
  opacity: 1; /* Show the overlay on hover */
}

.overlay a {
  width: 100%;
  color: white;
  text-decoration: none;
  background-color: #000;
  display: block;
  text-align: center;
  padding: 10px 0px;
  box-sizing: border-box;
  font-family: Lato;
  font-size: 14.15px;
  font-weight: 400;
  line-height: 16.49px;
  letter-spacing: 0.025em;
}

.recently-view-dec .recently-item .item-image {
  width: 100%;
  height: 380px;
  overflow: hidden;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: transform 0.4s ease;
    display: block;
  }
}
.item-image:hover img {
  transform: scale(1.1);
}
.recently-item-content {
  // display: flex;
  // justify-content: space-between;
  // align-items: center;
  margin: 5px 0px;
}

.recently-item-content h5 {
  font-family: Cormorant;
  font-size: 24px;
  font-weight: 500;
  line-height: 33.73px;
  text-align: left;
  max-width: 290px;
  white-space: nowrap; /* Prevent text wrapping */
  overflow: hidden; /* Hide the overflowing text */
  text-overflow: ellipsis; /* Add ellipses (...) for truncated text */
}

.recently-item-content p {
  font-family: "Lato", sans-serif;
  font-size: 16px;
  font-style: italic;
  font-weight: 400;
  line-height: 29.09px;
  text-align: left;
  color: #595959;
}

/* Footer Details */
.footer-details {
  margin-top: 20px;
  font-size: 12px;
  color: #888;
  display: flex;
  gap: 15px;
}

@media screen and (max-width: 1280px) {
  .cta-buttons button {
    padding: 15px 60px;
  }
  .out-of-stock-btn {
    button {
      padding: 15px 80px;
    }
  }
  // .extra-info{
  //   width: 100%;
  // }
}

@media screen and (max-width: 1024px) {
  .size-selection {
    width: 80%;
  }
  .cta-buttons button {
    padding: 15px 60px;
  }
  .out-of-stock-btn {
    button {
      padding: 15px 60px;
    }
  }
  .size-head {
    width: 100%;
  }
  .pincode-check input {
    font-size: 16px;
    width: 125px;
  }
  .pincode-check input::placeholder {
    font-size: 16px !important;
  }
  .extra-info-item {
    svg {
      width: 35px;
      height: 35px;
    }
  }
  .product-item {
    min-width: 25%;
    max-width: 25%;
  }
  .recently-view-dec .recently-item {
    min-width: 25%;
    max-width: 25%;
  }
  .similar-product-image {
    height: 270px;
  }
  .recently-view-dec .recently-item .item-image {
    height: 270px;
  }
  .left-column {
    flex-direction: column;
  }
  .thumbnail-gallery {
    flex-direction: row;
    order: 2;
  }
}
/* Responsiveness */
@media (max-width: 1000px) {
  .prodContainer {
    flex-direction: column;
    padding: 5px 20px 20px 20px;
    .ship-share-box {
      width: 100%;
    }
  }
  .tab-container {
    width: 100%;
    padding: 0 20px;
    // margin: ;
  }
  .product-dec,
  .product-dec-2 {
    width: 100%;
    padding: 20px 0 20px 20px;
  }
  .product-row {
    overflow-x: auto;
    scroll-behavior: smooth;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: none; /* Hide scrollbar in Internet Explorer and Edge */
    scrollbar-width: none; /* Hide scrollbar in Firefox */
    .product-item {
      min-width: 35%;
      max-width: 35%;
    }
  }
  .recently-view-dec {
    overflow-x: auto;
    scroll-behavior: smooth;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: none; /* Hide scrollbar in Internet Explorer and Edge */
    scrollbar-width: none; /* Hide scrollbar in Firefox */
    .recently-item {
      min-width: 35%;
      max-width: 35%;
    }
  }
  .left-column,
  .right-column {
    width: 100%;
  }

  .thumbnail-gallery {
    order: 2;
  }

  .thumbnail-gallery img {
    max-width: 80px; /* Adjust thumbnail size for smaller screens */
  }

  .size-head {
    width: 70%;
  }

  .main-image img {
    object-fit: contain;
  }

  .cta-buttons {
    flex-direction: row;
  }

  .cta-buttons button {
    padding: 15px 50px;
    width: 100%;
  }
  .out-of-stock-btn {
    button {
      padding: 15px 50px;
      width: 100%;
    }
  }
  .tab button {
    margin-right: 1rem;
    font-size: 13px;
  }

  .tabcontent p {
    font-size: 16px;
  }

  .product-row {
    gap: 0.5rem;
  }

  .product-item p {
    font-size: 14px;
  }

  .recently-view-dec {
    gap: 1.5rem;
  }
}

@media (max-width: 480px) {
  .breadcrumb {
    width: 100%;
    margin: 25px 20px 0px 20px;
  }
  .prodContainer {
    display: block;
    padding: 5px 20px;
  }
  .product-title-mob h1 {
    font-size: 30px !important;
  }
  .share-prod {
    display: none;
  }
  .prod-title-review {
    display: none;
  }
  .left-column {
    flex-direction: column;
  }
  .product-dec-2 {
    width: 100%;
  }
  .product-row {
    gap: 1.4rem;
  }
  .product-item {
    min-width: 60% !important;
    max-width: 60% !important;
    background-color: #f8f9fa;
    .similar-product-image {
      width: 100%;
      height: 200px;
    }
  }
  .right-column {
    padding: 0px;
  }
  .why-choose {
    padding: 0px 0px;
  }
  .shippingInfo {
    padding: 0px 10px;
  }
  .thumbnail-gallery {
    flex-direction: row;
    height: auto;
    order: 2;
  }
  .main-image img {
    width: 100%;
    height: 450px;
    object-fit: cover;
  }
  .right-column .ship-box {
    display: none;
  }
  .product-title-mob {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
    margin-top: 10px;
    h1 {
      font-size: 45px;
      font-family: "Cormorant", sans-serif;
      font-weight: 500;
    }
    .share-prod-mob {
      position: relative;
      ul {
        position: absolute;
        list-style: none;
        background-color: white;
        z-index: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        li {
          padding: 2px;
        }
      }
    }
  }
  .product-title {
    display: none;
  }
  .price {
    display: none;
  }
  .price-mob {
    display: inline-block;
    font-size: 22px;
    color: #363636;
    margin-bottom: 10px;
    font-family: "Lato", serif;
    font-weight: 500;
    // font-style: italic;
    span {
      font-size: 22px;
      color: #363636;
      margin-bottom: 10px;
      font-family: "Lato", serif;
      font-weight: 500;
      // font-style: italic;
    }
  }
  // .price-mob span {
  //   font-family: Lato;
  //   font-size: 12px;
  //   font-weight: 400;
  //   line-height: 12px;
  //   letter-spacing: 0.01em;
  //   text-align: left;
  //   font-style: normal;
  //   color: #595959;
  // }
  .price-mob span img {
    vertical-align: middle;
    margin: 0px 5px;
  }
  .description {
    font-size: 16px;
  }
  .product-details {
    padding: 10px 0px;
    display: inline-block;
  }
  .product-details span {
    font-size: 19px;
  }
  .product-details p {
    font-size: 16px;
  }
  .rating p {
    font-size: 16px;
    // border: 1px solid black;
  }
  .rating svg {
    padding: 2px;
    width: 20px;
    height: 30px;
    font-size: 24px;
    // border: 1px solid black;
  }
  .color-selection p {
    font-size: 16px;
    margin-bottom: 10px;
  }
  .size-head {
    width: 120%;
    justify-content: left;
    gap: 10px;
  }
  .size-head p {
    font-size: 16px;
    margin-bottom: 10px;
  }
  .size-head a {
    font-size: 12px;
    margin-bottom: 10px;
    text-decoration: underline;
    cursor: pointer;
  }
  .size-selection button {
    padding: 8px 20px;
    font-size: 12px;
    border-radius: 0;
  }
  .color-options {
    overflow: scroll;
    // white-space: nowrap;
    // scroll-behavior: smooth;
    // -webkit-overflow-scrolling: touch;
    min-width: 200%;
  }
  .quantity-section p {
    font-size: 16px;
    margin-bottom: 10px;
  }
  .custom-select-wrapper {
    width: 70px;
  }
  .extra-info span {
    font-size: 12px;
    gap: 5px !important;
    margin-right: 1rem;
  }
  .pincode-check p {
    font-size: 12px;
  }
  .pincode-check input {
    padding: 8px;
    font-size: 10px;
  }
  .pincode-check input::placeholder {
    font-size: 10px;
  }
  .cta-buttons button {
    padding: 10px 15px;
    font-size: 12px;
    border-radius: 0;
  }
  .out-of-stock-btn {
    button {
      padding: 10px 15px;
      font-size: 12px;
      border-radius: 0;
    }
  }
  .tab-container {
    display: none;
  }
  .tab-container-list {
    display: block;
    width: 95%;
    margin: 20px auto 20px auto;

    .tab-mob {
      list-style: none;
      padding-left: 10px;
      display: flex;
      overflow-x: auto;
      // scrollbar-width: none;
      white-space: nowrap;
      gap: 20px;
      padding-bottom: 20px;
      li {
        padding: 8px 0;
        font-family: "Lato", sans-serif;
        font-weight: 400;
        font-size: 12px;
        border-right: 1px solid #595959;
        color: #a3a3a3;
        padding-right: 20px;
        // line-height: 1.5;
        height: 15px;
        display: flex;
        align-items: center;
      }
      li:last-child {
        border-right: none;
      }
      .active {
        color: #000;
      }
    }
  }
  .tab button {
    line-height: normal;
  }
  .tabcontent {
    padding: 10px;
  }
  .testimonial-slide {
    padding: 8px;
  }
  .testimonial-content {
    padding: 0px;
  }
  .quote-mark {
    width: 10%;
    margin: 0;
    padding: 0;
    img {
      width: 50%;
    }
  }
  .author {
    margin-top: 0;
  }
  .rating {
    display: flex;
    gap: 10px;
  }
  .rating-review {
    margin-bottom: 8px;
    svg {
      width: 14px;
    }
  }
  .tabcontent p {
    font-size: 12px;
    line-height: 25px;
  }
  .maintenance-tips {
    padding: 5px;
  }
  .maintenance-tips li {
    font-size: 14px;
    line-height: 19px;
  }
  .review-form-container {
    width: 100%;
    padding: 0px;

    h2 {
      font-size: 19px;
      margin-bottom: 10px;
    }
    label {
      font-size: 12px;
    }
    .rating-star {
      svg {
        width: 15px;
      }
    }
    button {
      border-radius: 0;
      font-size: 12px;
      font-family: "Lato", sans-serif;
    }
  }
  .product-item {
    flex: none;
    width: 100%;
  }

  // .product-row {
  //   justify-content: center;
  //   flex-wrap: wrap;
  //   gap: 1rem;
  // }

  .product-item h3 {
    font-size: 25px;
  }

  .product-item p {
    font-size: 12px;
  }
  .product-dec-2 {
    padding: 0px 20px;
  }
  .product-dec h2 {
    font-size: 28px;
    text-align: center;
  }
  .product-dec-2 h2 {
    font-size: 28px;
    text-align: center;
  }
  // .recently-view-dec {
  //   flex-direction: column;
  // }

  .recently-view-dec .recently-item {
    width: 100%;
    padding: 5px;
  }
  .recently-view-dec {
    .recently-item {
      min-width: 60%;
      max-width: 60%;
      .item-image {
        width: 100%;
        height: 200px;
      }
    }
  }

  .recently-item-content h5 {
    font-size: 25px;
  }
  .viewCartBoxContainer {
    .addToCartBox {
      p {
        font-size: 12px;
      }
      button {
        padding: 5px 10px;
        font-size: 10px;
      }
    }
  }
  .product-dec {
    width: 100%;
    padding: 0px 20px 0 20px;
  }

  .extra-info {
    width: 103%;
    gap: 20px;
    .extra-info-item {
      svg {
        // display: none;
        width: 26px;
        height: 26px;
      }
      span {
        font-size: 14px;
        margin-right: 0;
        line-height: normal;
      }
    }
  }
  .pincode-check-container {
    .error-message-pincode {
      font-size: 12px;
    }
    .success-message-pincode {
      h4 {
        font-size: 14px;
      }
      span {
        font-size: 10px;
      }
    }
  }
  .main-review-rating {
    display: none;
  }
  .product-details {
    .tableDetails {
      th,
      td {
        font-size: 12px;
      }
    }
  }
  .maintenance-tips {
    .rug-care {
      h5 {
        font-size: 16px;
      }
      ul {
        li {
          font-size: 12px;
        }
      }
    }
  }
  .shippingInfo {
    ul {
      li {
        font-size: 12px;
      }
    }
  }
}

@media screen and (max-width: 368px) {
  .color-options img {
    margin-right: 10px;
  }

  .product-dec-2 h2 {
    font-size: 35px;
  }
  .product-item {
    min-width: 45%;
    max-width: 45%;
  }
}
@media screen and (max-width: 1050px) and (orientation: landscape) {
  .breadcrumb {
    width: 100%;
    margin: 0px 20px;
    margin-top: 20px;
  }
  // .extra-info {
  //   gap: 110px;
  //   // white-space: nowrap;
  // }
  .product-dec h2 {
    font-size: 28px;
  }
  .product-dec-2 h2 {
    font-size: 28px;
  }
}
@media screen and (max-width: 768px) and (orientation: landscape) {
  // .extra-info{
  //   width: 108%;
  // }
  // .extra-info{
  //   // gap: 70px;
  //   // white-space: nowrap;
  // }
  .extra-info {
    width: 103%;
  }
  .extra-info {
    gap: 110px;
    // justify-content: space-between;
  }
}
@media screen and (max-width: 992px) and (orientation: landscape) {
  .extra-info {
    width: 103%;
  }
  .extra-info {
    gap: 96px;
    // white-space: nowrap;
  }
  .similar-product-image {
    height: 300px;
  }
  .recently-view-dec .recently-item .item-image {
    height: 300px;
  }
  .product-row .product-item {
    min-width: 30%;
    max-width: 30%;
  }
  .recently-view-dec .recently-item {
    min-width: 30%;
    max-width: 30%;
  }
}
@media screen and (max-width: 850px) and (orientation: landscape) {
  .extra-info {
    width: 108%;
  }
  .extra-info {
    gap: 70px;
    // white-space: nowrap;
  }
}
