.header {
  z-index: 1000;
  position: sticky;
  width: 100%;
  transition: background-color 0.3s ease;
  background: #f8f9fa;
  top: 0;
  .container {
    max-width: 100%;
    .discountContainer {
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: #cccccc;
      height: 40px;
      .discountArrow {
        svg {
          font-family: "Lato", sans-serif;
          font-weight: 400;
          width: 16px;
          height: 16px;
          color: #0c0c0c;
        }
      }
      .discountArrow {
        svg {
          width: 16px;
          height: 16px;
        }
      }
      .discountText {
        padding-left: 50px;
        padding-right: 50px;
        font-family: "Lato", sans-serif;
        // text-transform: uppercase;
        font-weight: 400;
        font-size: 14px;
      }
    }
    .logoCartSearchContainer {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 30px 80px;
      // background: #f8f9fa;
      // background-color: transparent;
      position: relative;
      .storeLocator {
        display: flex;
        align-items: flex-start;
        // border: 1px solid black;
        gap: 10px;
        // flex-grow: 1;
        // margin-left: 55px;
        .locationIcon {
          display: flex;
          align-items: center;
          svg {
            width: 22px;
            height: 22px;
            color: #595959;
            // color: #FFFFFF;
          }
        }
        .locatorText {
          font-family: "Lato", sans-serif;
          font-size: 16px;
          font-weight: 400;
          color: #595959;
          cursor: pointer;
        }
      }
      .logoContainer {
        position: absolute;
        left: 50%;
        transform: translateX(-50%); // Center the logo horizontally
        cursor: pointer;
        // border: 1px solid black;
        img {
          // border: 1px solid black;
          height: auto;
          max-width: 320px; // Adjust logo size if necessary
        }
      }

      .searchCartContainer {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        // flex-grow: 1;
        gap: 20px;

        /* Search Box Styles */
        .searchBox {
          display: flex;
          align-items: center;
          border-radius: 3px;
          padding: 12px 10px;
          width: 0; /* Start with 0 width */
          overflow: hidden; /* Hide overflow during the animation */
          background-color: transparent;
          transition: width 0.3s ease-in-out; /* Smooth width expansion animation */
          position: relative;
          .searchIcon {
            margin-right: 8px;
            cursor: pointer;
            // border: 1px solid black;
            svg {
              width: 26px;
              height: 26px;
              margin-top: 5px;
              margin-left: -12px;
            }
          }

          .searchInput {
            flex-grow: 1;
            display: none; /* Initially hidden */
            opacity: 0; /* Start with opacity 0 for smooth fade-in */
            transition: opacity 0.3s ease; /* Smooth fade-in effect */

            input {
              border: none;
              outline: none;
              background: transparent;
              font-family: "Lato", sans-serif;
              font-size: 16px;
              color: #595959;
              width: 100%;

              ::placeholder {
                color: #595959;
              }
            }
          }

          &.searchVisible {
            width: 300px; /* Increase the width to 300px when visible */
            background-color: #e5e5e5; /* Change background when visible */

            .searchInput {
              display: block; /* Show the input field */
              opacity: 1; /* Fade the input in */
            }

            .searchIcon svg {
              color: #595959; /* Change icon color when search is visible */
              margin-left: 0px;
            }
          }
        }
        .searchResults {
          position: absolute;
          background: white;
          width: 300px;
          border: 1px solid #ddd;
          box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
          z-index: 100;
          margin-top: 35px;
          top: 10px;
          right: 90px;

          .MuiListItem-root {
            cursor: pointer;
            padding: 8px 16px;
          }

          .MuiListItem-root:hover {
            background-color: #f0f0f0;
          }
          .listItemSearch {
            span {
              font-size: 16px;
            }
          }
        }
        .account {
          cursor: pointer;
          svg {
            width: 26px;
            height: 26px;
          }
        }
        .cart {
          cursor: pointer;
          position: relative;
          svg {
            width: 24px;
            height: 24px;
          }
          .customBadge .MuiBadge-badge {
            background-color: #000000; /* Badge background color */
            color: #ffffff; /* Badge text color */
          }
        }
      }
    }
    .menuList {
      display: flex;
      justify-content: center;
      padding: 0;
      // background: #f8f9fa;
      // background-color: transparent;
      .headerList {
        list-style: none;
        width: 80%;
        display: flex;
        justify-content: space-between;
        margin-bottom: 10px;
        li {
          font-family: "Lato", sans-serif;
          font-size: 14px;
          font-weight: 400;
          line-height: 16.8px;
          text-transform: uppercase;
          color: #0c0c0c;
          cursor: pointer;
        }
      }
      .headerList li:hover {
        font-weight: 800;
      }
    }
    .customDropDown {
      display: flex;
      justify-content: center;
      background: transparent;
      width: 100%;

      margin: 0 auto;
      position: relative;
    }
    // .customDropDown::before {
    //   left: 0;
    //   bottom: -3px;
    //   content: "";
    //   z-index: 2;
    //   width: 100%; // You might want to change this to fit your design
    //   height: 0; // Start height
    //   position: absolute;
    //   transition: height 0.3s ease; // Transitioning height
    //   background-color: #191919;
    // }
    .dropDownContainer {
      width: 100%;
      height: auto;
      position: absolute;
      background: #f8f9fa;
      padding: 20px 80px;
      display: flex;
      // align-items: center;
      justify-content: space-between;
      // gap: 130px;
      box-sizing: border-box;
      overflow-y: auto;
      .rugsList,
      .sizeList {
        display: flex;
        justify-content: center;
        flex-grow: 1;
        // border: 1px solid black;
        ul,
        li {
          cursor: pointer;
          list-style: none;
          padding: 8px 0;
          margin: 0;
          font-family: "Lato", sans-serif;
          font-weight: 300;
          font-size: 16.77px;
          // color: #0c0c0c;
          color: #767676;
          &:hover {
            // font-weight: 600; /* Change font weight on hover */
            color: #0c0c0c;
          }
        }
        ul li:nth-child(1) {
          color: #0c0c0c; /* Fixed color */
          pointer-events: none; /* Disable hover and interaction */
        }

        /* Optional: Prevent hover style on the specific child */
        ul li:nth-child(1):hover {
          color: #0c0c0c; /* Prevent hover color change */
        }
        .rugsHeader,
        .sizeHeader,
        .colorHeader,
        .roomHeader,
        .shapeHeader,
        .patternHeader {
          font-family: "Cormorant", serif;
          font-weight: 800;
          font-size: 18.66px;
          line-height: 22.84px;
          margin-bottom: 8px;
        }
      }
      .rugsList::after {
        content: "";
        position: absolute;
        left: 320px;
        right: 20px;
        top: 5px;
        margin-top: 5px;
        bottom: 0;
        width: 1px;
        height: 90%;
        opacity: 0.1;
        background-color: black; /* Line color */
        @media screen and (max-width: 1000px) {
          display: none;
        }
      }
      .colorCircle {
        vertical-align: middle;
      }
    }
    .dropDownColContainer {
      width: 100%;

      margin: 0 auto; // Centers the container
      padding: 20px;
      position: fixed;
      background: #f8f9fa;
      box-sizing: border-box;
      // border: 1px solid black;
      display: flex;
      justify-content: center;
      .collection {
        // border: 1px solid black;
        // border: 1px solid black;
        display: grid;
        grid-template-columns: repeat(4, 1fr); /* 4 fixed columns */
        // column-gap: 20px;
        // gap: 20px;
        row-gap: 20px;
        column-gap: 15px;
        padding: 0;
        width: 85%;

        // margin-left: 10px;
        // gap: 30px;
        // flex-wrap: wrap;
        .collectionItem {
          padding: 0;
          margin: 0;
          display: flex;
          flex-direction: column;
          width: 100%;

          align-items: center;
          cursor: pointer;
          // border: 1px solid black;
          &:hover p {
            font-weight: 600; /* Change font weight on hover */
          }
          .collectionItem-image {
            width: 100%;
            height: 100%;
            img {
              width: 100%;
              height: 100%;
              object-fit: cover;
            }
          }
        }

        p {
          // border: 1px solid black;
          font-family: "Lato", sans-serif;
          font-size: 18px;
          font-weight: 400;
          text-align: center;
          color: #0c0c0c;
          // margin-top: -0.1rem;
        }
      }
      @media screen and (max-width: 3000px) {
        .collection {
          padding: 0 20px;
          margin-right: 10px;
          img {
            width: 340px;
            height: 150px;

            // border: 1px solid black;
          }
        }
      }
    }
    .header-mob {
      display: none;
    }

    @media (min-width: 300px) and (max-width: 1050px) {
      .discountContainer {
        height: 15px;
        .discountArrow {
          svg {
            width: 4.33px;
            height: 4.33px;
          }
        }
        .discountText {
          padding-left: 5px;
          padding-right: 5px;
          font-family: "Lato", sans-serif;
          // text-transform: uppercase;
          font-weight: 400;
          font-size: 12px;
        }
      }
      .logoCartSearchContainer {
        display: none;
      }
      .header-mob {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 15px 15px;
        .logo-mob {
          flex-grow: 1;
          img {
            max-width: 140px;
          }
        }
        .logoCartMenuContainer {
          // flex-grow: 1;
          display: flex;
          justify-content: center;
          gap: 20px;
          .search-mob,
          .cart-mob {
            // border: 1px solid black;
            display: flex;
            align-items: center;
            svg {
              width: 20px;
              height: 20px;
            }
            // .badge-color .MuiBadge-badge {
            //   background-color: ;
            //   color: #000000;
            // }
          }
          .navDrawer {
          }
        }
      }
      // .drawerContent {
      //   background-color: #0c0c0c !important;
      // }

      .menuList {
        display: none;
      }
    }
  }
}
.drawerMob {
  overflow-y: scroll;
}
.list-drawer {
  .list-drawer-head {
    span {
      font-size: 20px;
      font-family: "Lato", sans-serif;
    }
  }
  .shopMenuListMob {
    // border: 1px solid black;
    // padding: 2px;
    margin-top: -10px;
    .rugsListMob {
      // display: grid;
      // grid-template-columns: repeat(2, 1fr);
      .rugsHeaderMob {
        font-family: "Cormorant", sans-serif;
        font-weight: 500;
        font-size: 20px;
      }
      .rugListMenuMob {
        padding-top: 2px;
        padding-bottom: 2px;
        span {
          font-family: "Lato", sans-serif;
          font-weight: 300;
          font-size: 16px;
        }
      }
    }
    .mainSubMenuList {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
    }
  }
}
.shopList {
  display: flex;
}
.header-transparent {
  background-color: transparent;
  transition: background-color 0.3s ease;
  position: fixed;
  .logoCartSearchContainer {
    .locationIcon {
      svg {
        color: #ffffff !important;
      }
    }
    .locatorText {
      color: #ffffff !important;
    }
  }
  .logoContainer {
    filter: brightness(0) invert(1);
  }
  .menuList {
    .headerList {
      li {
        color: #ffffff !important;
      }
    }
  }
  .account {
    svg {
      color: #ffffff;
    }
  }
  .cart {
    svg {
      color: #ffffff;
    }
    .customBadge .MuiBadge-badge {
      background-color: #ffffff !important; /* Badge background color */
      color: #000000 !important; /* Badge text color */
    }
  }
  .searchBox {
    // background-color: #e5e5e5;
    .searchIcon {
      svg {
        color: #ffffff;
      }
    }
    .searchInput {
      input {
        color: #595959;
        ::placeholder {
          color: #595959;
        }
      }
    }
  }
}
.header-transparent:hover {
  background-color: #f8f9fa;
  .logoCartSearchContainer {
    .locationIcon {
      svg {
        color: #000000 !important;
      }
    }
    .locatorText {
      color: #000000 !important;
    }
    .logoContainer {
      filter: brightness(1) invert(0);
    }
  }
  .menuList {
    .headerList {
      li {
        color: #0c0c0c !important;
      }
    }
  }
  .account {
    svg {
      color: #0c0c0c;
    }
  }
  .cart {
    svg {
      color: #0c0c0c;
    }
    .customBadge .MuiBadge-badge {
      background-color: #000000 !important; /* Badge background color */
      color: #ffffff !important; /* Badge text color */
    }
  }
  .searchBox {
    // background-color: #e5e5e5;
    .searchIcon {
      svg {
        color: #000000;
      }
    }
    .searchInput {
      input {
        color: #595959;
        ::placeholder {
          color: #595959;
        }
      }
    }
  }
}
.header-mob-transparent {
  .logo-mob {
    filter: brightness(0) invert(1);
  }
  .logoCartMenuContainer {
    .search-mob svg {
      color: #ffffff;
    }
    .cart-mob {
      svg {
        color: #ffffff;
      }
      .badge-color .MuiBadge-badge {
        background-color: white;
        color: #000000;
      }
    }
    .navDrawer {
      .menu-header-mob-icon {
        svg {
          color: #ffffff;
          fill: #ffffff;
        }
      }
    }
  }
  // .css-20bmp1-MuiSvgIcon-root {
  //   fill: #ffffff;
  // }
}
.header-mob-transparent:hover {
  .logo-mob {
    filter: brightness(1) invert(0);
  }
  .logoCartMenuContainer {
    .search-mob svg {
      color: #000000;
    }
    .cart-mob {
      svg {
        color: #000000;
      }
      .badge-color .MuiBadge-badge {
        background-color: #000000;
        color: #ffffff;
      }
    }
    .navDrawer {
      .menu-header-mob-icon {
        svg {
          color: #000000;
          fill: #000000;
        }
      }
    }
  }
  // .css-20bmp1-MuiSvgIcon-root {
  //   fill: #000000;
  // }
}
.css-tosvlo-MuiPaper-root-MuiDrawer-paper {
  width: 100% !important;
  max-width: 100% !important;
  // height: 50vh !important;
  height: auto !important;
  padding: 0px !important;
}
.css-1u169s7-MuiList-root {
  padding-left: 0px !important;
}
.drawerContent {
  // padding: 0px 20px;
  .list-drawer {
    padding: 30px 20px !important;
    .list-text-with-arrow-icon {
      display: flex;
      justify-content: space-between;
      align-items: center;
      svg {
        font-size: 25px;
      }
    }
  }
}
.add-bottom-border {
  border-bottom: 0.5px solid #d7d7d7;
}
.menu-logo-and-cross-icon {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 35px 25px;
  .menu-logo-ddecor {
    width: 136px;
    height: 33px;
    img {
      width: 100%;
      height: auto;
    }
  }
  .menu-cross-icon {
    svg {
      font-size: 20px;
    }
  }
}
@media screen and (max-width: 916px) and (orientation: landscape) {
  .logoContainer img {
    width: 240px;
    height: auto;
  }
}
