.in-the-news-container {
    width: 105%;
    // padding: 10px 0px;
    // margin-left: 10px;
    display: flex;
    // justify-content: center;
    // border: 1px solid black;
    // padding: auto 10px;
    // padding-left: 20px;
    .in-the-news-articles {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      column-gap: 20px;
      row-gap: 30px;
      .in-the-news-item {
        cursor: pointer;
        max-width: 315px;
        width: 315px;
        height: 215px;
        padding: 0px 10px;
        margin-top: 8px;
        border: 1px solid #dddddd;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        transition: transform 0.3s ease, border-color 0.3s ease;
        &:hover {
          transform: scale(1.04); /* Scale on hover */
          border-color: #595959; /* Change border color to black */
        }
        .in-the-news-divider {
          width: 100%; /* Make it span the full width */
          height: 1px; /* Thin line */
          background-color: #dddddd; /* Matches the specified border color */
          margin: 10px 0; /* Adjust spacing as needed */
        }
        .news-content {
          .news-content-title {
            margin-top: 10px;
            p {
              font-family: "Cormorant", sans-serif;
              font-size: 24px;
              font-weight: 500;
              text-align: center;
              font-family: Cormorant;
              line-height: 29.06px;
              letter-spacing: -0.005em;
  
              text-underline-position: from-font;
              text-decoration-skip-ink: none;
            }
          }
          .news-content-date {
            margin-top: 15px;
            p {
              font-family: "Lato", sans-serif;
              font-size: 16px;
              font-weight: 300;
              line-height: 19.2px;
              text-align: center;
              text-underline-position: from-font;
              text-decoration-skip-ink: none;
              color: #595959;
            }
          }
        }
      }
    }
  }
  @media (min-width: 600px) and (max-width: 1024px) {
    .in-the-news-container {
      //   margin-left: 0;
      .in-the-news-articles {
        grid-template-columns: repeat((2, 1fr));
  
        .in-the-news-item {
          width: 350px;
          .news-image {
            img {
              width: 140px;
              height: 35px;
            }
          }
        }
      }
    }
  }
  @media (min-width: 400px) and (max-width: 600px) {
    .in-the-news-container {
      margin-left: 0;
      .in-the-news-articles {
        grid-template-columns: repeat((2, 1fr));
        .in-the-news-item {
          width: 190px;
          height: 150px;
          .news-image {
            img {
              width: 112px;
              height: 28px;
            }
          }
          .news-content {
            .news-content-title {
              p {
                font-size: 12px;
              }
            }
            .news-content-date {
              p {
                font-size: 9px;
              }
            }
          }
        }
      }
    }
  }
  @media (min-width: 365px) and (max-width: 400px) {
    .media-main-content {
      display: block;
      padding: 50px 15px;
  }
    .in-the-news-container {
      margin-left: 0;
      .in-the-news-articles {
        grid-template-columns: repeat((2, 1fr));
        justify-content: center;
        gap: 12px;
        .in-the-news-item {
          width: 165px;
          height: 150px;
          .news-image {
            img {
              width: 112px;
              height: 28px;
            }
          }
          .news-content {
            .news-content-title {
              p {
                font-size: 12px;
              }
            }
            .news-content-date {
              p {
                font-size: 12px;
              }
            }
          }
        }
      }
    }
  }
  @media (min-width: 350px) and (max-width: 365px) {
    .in-the-news-container {
      margin-left: 0;
      margin: 0 10px;
      .in-the-news-articles {
        grid-template-columns: repeat((2, 1fr));
        .in-the-news-item {
          width: 165px;
          height: 180px;
          .news-image {
            img {
              width: 112px;
              height: 28px;
            }
          }
          .news-content {
            .news-content-title {
              p {
                font-size: 12px;
              }
            }
            .news-content-date {
              p {
                font-size: 9px;
              }
            }
          }
        }
      }
    }
  }
  @media (min-width: 300px) and (max-width: 350px) {
    .in-the-news-container {
      margin-left: 0;
      margin: 0 10px;
      .in-the-news-articles {
        grid-template-columns: repeat((2, 1fr));
        .in-the-news-item {
          width: 145px;
          height: 180px;
          .news-image {
            img {
              width: 112px;
              height: 28px;
            }
          }
          .news-content {
            .news-content-title {
              p {
                font-size: 12px;
              }
            }
            .news-content-date {
              p {
                font-size: 9px;
              }
            }
          }
        }
      }
    }
  }
  @media (max-width: 768px) {
    .in-the-news-container {
      margin-top: 40px;
    }
  }
  