.head-cust-part {
  h2 {
    text-align: center;
    font-weight: 500;
    font-size: 40px;
    text-transform: uppercase;
    font-family: "Cormorant", sans-serif;
  }
  @media screen and (max-width: 1000px) {
    h2 {
      // font-size: 20px;
      margin-top: 100px;
    }
  }
  @media screen and (max-width: 480px) {
    h2 {
      font-size: 20px;
      margin-top: 30px;
    }
  }
}

.testimonial-container {
  background-image: url("../images/bg-image.jpeg");
  background-size: cover;
  background-position: center;
  margin-top: 20px;
  background-repeat: no-repeat;
  // border: 1px solid black;
  padding-bottom: 10px;
}

.testimonial-slider {
  position: relative;
  width: 90%;
  max-width: 1400px;
  margin: 50px auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.slider-wrapper-review {
  overflow: hidden;
  width: 100%;
}

.slider {
  display: flex;
  justify-content: center;
  transition: transform 0.5s ease-in-out;
  // border: 1px solid black;
  // padding: 20px;
}

.testimonial {
  flex: 0 0 26%;
  box-sizing: border-box;
  padding: 30px 30px;
  margin: 0 50px;
  border: 1px solid #ccc;
  background: #fff;
  text-align: left;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transform: scale(0.9);
  height: 300px;
  img {
    width: 30px;
    height: 30px;
    object-fit: none;
    margin-bottom: 10px;
  }
}

.testimonial h4 {
  font-size: 21.67px;
  font-family: "Cormorant", sans-serif;
  line-height: 1.5;
  font-weight: 400;
  color: #494949;
  font-style: italic;
}

.testimonial p {
  margin-top: 15px;
  font-weight: 400;
  font-size: 16.67px;
  font-family: "Lato", sans-serif;
  color: #595959;
}

.testimonial span {
  display: block;
  font-weight: 400;
  font-size: 16.67px;
  font-family: "Lato", sans-serif;
  color: #595959;
}

/* Center slide larger */
.center-slide {
  transform: scale(1);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  border: 1px solid #000;
}

/* Arrow buttons */
.arrowCust {
  background-color: transparent;
  color: #333;
  border: none;
  font-size: 24px;
  cursor: pointer;
  z-index: 2;
}

.prevCust {
  position: absolute;
  left: -20px;
  top: 50%;
  transform: translateY(-50%);
}

.nextCust {
  position: absolute;
  right: -20px;
  top: 50%;
  transform: translateY(-50%);
}
.testimonial-container-mob {
  // background-image: url("../images/bg-image.jpeg");
  // background-size: cover;
  // background-position: center;
  margin-top: 5px;
  // background-repeat: no-repeat;
  // border: 1px solid black;
  padding-bottom: 0;
  display: none;
}

.testimonial-slider-mob {
  position: relative;
  // width: 90%;
  // max-width: 90%;
  margin: 25px 5px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.slider-wrapper-review-mob {
  overflow: hidden;
  width: 100%;
}

.slider-mob {
  display: flex;
  transition: transform 0.5s ease-in-out;
  overflow-x: auto;
  scroll-behavior: smooth;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: none; /* Hide scrollbar in Internet Explorer and Edge */
  scrollbar-width: none; /* Hide scrollbar in Firefox */
}

.testimonial-mob {
  border: 1px solid black;
  flex: 0 0 90%;
  box-sizing: border-box;
  padding: 40px 20px;
  margin: 0 10px;
  border: 1px solid #ccc;
  background: #fff;
  text-align: left;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  // transform: scale(0.9);
  // height: 250px;
  transform: scale(1);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  border: 1px solid #000;
}

.testimonial-mob img {
  margin-bottom: 10px;
}

.testimonial-mob h4 {
  font-size: 21.67px;
  font-family: "Cormorant", sans-serif;
  line-height: 1.5;
  font-weight: 400;
  color: #494949;
  font-style: italic;
}

.testimonial-mob p {
  margin-top: 15px;
  font-weight: 400;
  font-size: 16.67px;
  font-family: "Lato", sans-serif;
  color: #595959;
}

.testimonial-mob span {
  display: block;
  font-weight: 400;
  font-size: 16.67px;
  font-family: "Lato", sans-serif;
  color: #595959;
}

/* Center slide larger */
.center-slide-mob {
  transform: scale(1);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  border: 1px solid #000;
}
@media (max-width:1024px) {
  .testimonial-slider {
    width: 95%;
  }
  .testimonial {
    flex: 0 0 30%;
    padding: 20px 20px;
    margin: 0 25px;
  } 
}
@media (min-width: 500px) and (max-width: 1000px) {
  .testimonial-container {
    display: none;
  }
  .testimonial-container-mob {
    display: block;
  }
  .testimonial-mob {
    flex: 0 0 40%;
  }
}
@media (min-width: 300px) and (max-width: 500px) {
  .testimonial-container {
    display: none;
  }
  .testimonial-container-mob {
    display: block;
  }
}
@media screen and (max-width: 992px) and (orientation: landscape) {
  .head-cust-part {
    h2 {
      font-size: 30px;
    }
  }
}