.head-social-part {
  h2 {
    text-align: center;
    font-weight: 400;
    font-size: 40px;
    // text-transform: uppercase;
    font-family: "Cormorant", sans-serif;
  }
  @media screen and (max-width: 500px) {
    h2 {
      font-size: 20px;
      // margin-top: 30px;
    }
  }
}
.head-ins-part {
  h2 {
    text-align: center;
    font-weight: 500;
    font-size: 40px;
    text-transform: uppercase;
    font-family: "Cormorant", sans-serif;
  }
  @media screen and (max-width: 500px) {
    h2 {
      font-size: 20px;
      // margin-top: 30px;
    }
  }
}
.video-part {
  margin-top: 20px;
  padding-top: 10px;
  width: 100%;
  min-height: 200%;
  max-height: 200%;
  display: flex;
  justify-content: center;
  // border: 1px solid black;
  @media screen and (max-width: 500px) {
    min-height: 150%;
    max-height: 150%;
  }
}

.video-image {
  display: flex;
  justify-content: center;
  margin: 30px 0px;
  position: relative;
}

.video-image img {
  width: 100%;
}

.play-btn {
  position: absolute;
  top: 45%;
  left: 50%;
}
.socialImages {
  // margin-top: 8rem;
  margin-top: 20px;
}
/* Desktop layout */
.gallery-wrapper {
  overflow-x: auto; /* Allow horizontal scrolling */
  white-space: nowrap; /* Prevent items from wrapping onto the next line */
  width: 100%; /* Ensure it takes up full width of the parent */
  max-width: 1920px;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: flex-start;
}
.gallery-wrapper::-webkit-scrollbar {
  display: none;
}
.gallery {
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
  gap: 1.5rem;
  margin: 30px 0px;
  @media screen and (max-width: 500px) {
    margin: 30px 20px;
    gap: 0.2rem;
  }
}

.gallery-item {
  width: 250px;
  height: 250px; /* Set a minimum width for each item */
  flex-shrink: 0;
  position: relative;
  cursor: pointer;
  @media screen and (max-width: 500px) {
    min-width: none;
  }
}

.gallery img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 0;
  transition: transform 0.3s ease;
  @media screen and (max-width: 500px) {
    width: 90%;
  }
}
.gallery-item:hover img {
  transform: scale(1.05);
}
/* Mobile view adjustments */
@media screen and (max-width: 500px) {
  .gallery-item {
    width: 270px;
    height: 250px; /* Adjust item width for mobile */
  }
  .gallery-wrapper {
    width: 100%;
    justify-content: flex-start;
  }
}
.gallery-item:hover img {
  transform: scale(1.05);
  filter: brightness(70%);
}
.icon-overlay {
  position: absolute;
  top: 50%;
  left: 50%;
  opacity: 0;
  transform: translate(-50%, -50%);
  color: white;
  font-size: 48px;
  display: none;
}
.gallery-item:hover .icon-overlay {
  display: block;
  opacity: 1;
}
@media screen and (max-width: 992px) and (orientation: landscape) {
  .head-ins-part{
    h2{
      font-size: 30px;
    }
    margin-top: 80px;
  }
}