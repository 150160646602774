.order-summary {
  width: 45%;
  @media (max-width: 500px) {
    width: 100%;
  }
  .coupenInput {
    position: relative;
    width: 100%;

    input {
      padding: 15px 20px;
      width: 100%;
      font-size: 15px;
      margin-top: 20px;
    }

    button {
      position: absolute;
      right: 10px;
      top: 50%;
      transform: translateY(-50%);
      font-family: "Lato", sans-serif;
      font-size: 15px;
      padding: 8px 25px;
      font-weight: 400;
      letter-spacing: 1px;
      border-radius: 0%;
      margin-top: 10px;
      
    }
  }
  .order-card {
    margin-top: 15px;
    border: 1px solid #a5a3a3;
    padding: 10px 20px;
    .order-summary {
      border-bottom: 1px solid #a6a6a6;
      width: 100%;
    }
    .order-summary h5 {
      font-family: Cormorant;
      font-size: 28px;
      font-weight: 500;
      color: #0c0c0c;
      padding-bottom: 10px;
    }
    .order-details-cart {
      margin-top: 20px;
      display: flex;
      justify-content: space-between; /* Ensures space between details and price */
      cursor: pointer;
      gap: 15px;
      .order-image {
        width: 119px;
        height: 119px;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
      .order-details-payment {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        flex-grow: 1; /* Ensures it takes up available space */
        .order-name-price {
          display: flex;
          justify-content: space-between;
          align-items: start;
          h6 {
            font-family: "Cormorant", sans-serif;
            font-size: 20px;
            font-weight: 600;
            color: #0c0c0c;
          }
        }
        .order-size-text-and-color {
          p {
            font-family: "Lato", sans-serif;
            font-size: 14px;
            font-weight: 400;
            color: #595959;
            line-height: 18px;
          }
        }
        .delivery-text {
          p {
            font-family: "Lato", sans-serif;
            font-size: 12px;
            font-weight: 400;
            color: #636262;
            margin-top: 10px;
          }
        }
      }
      .orderprice {
        p {
          font-family: Cormorant;
          font-size: 24px;
          font-weight: 500;
          color: #363636;
        }
      }
    }
    .divider {
      margin-top: 20px;
      border-top: 1px solid #c9c9c9;
    }
    .total-order-price {
      margin-top: 20px;
      display: flex;
      justify-content: space-between;
      h5 {
        font-family: "Cormorant", sans-serif;
        font-weight: 500;
        font-size: 30px;
      }
      p {
        font-family: "Cormorant", sans-serif;
        font-weight: 500;
        font-size: 30px;
      }
    }
    .paymentoption {
      margin-top: 10px;
      border-top: 2px solid rgb(138, 218, 247);
      border-right: 2px solid rgb(138, 218, 247);
      border-bottom: 2px solid rgb(138, 218, 247);
      border-left: 1px solid rgb(138, 218, 247);
      border-radius: 8px;
      .plan-name {
        display: flex;
        align-items: center;
        padding: 5px;
        width: 100%;
        background-color: rgb(211, 235, 244);

        h5 {
          font-weight: bold;
          font-size: 22px;
          color: #242424;
        }
      }
      .view-plan {
        padding: 5px 8px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        p {
          font-size: 12px;
        }
        h6 {
          font-size: 15px;
          font-weight: 500;
        }
      }
    }
    .order-button {
      margin-top: 5rem;
      padding: 0px 15px;
      width: 100%;
      .checkout-button {
        font-family: "Lato", sans-serif;
        font-weight: 400;
        font-size: 12px;
        letter-spacing: 1px;
        margin-bottom: 10px;
        border-radius: 0%;
      }
      .shop-button {
        font-family: "Lato", sans-serif;
        font-weight: 400;
        font-size: 12px;
        letter-spacing: 1px;
        margin-bottom: 10px;
        background-color: #fff;
        color: #000;
        border: 1px solid #000000;
        border-radius: 0%;
      }
    }
  }
}
@media (max-width:576px) {
  .order-summary{
    margin-top: 45px !important;
  }
  .coupenInput {
    input {
      font-size: 12px;
      padding: 15px;
    }
    input::placeholder{
      font-size: 12px;
    }

    button {
      font-size: 12px !important;
      padding: 8px 20px !important;   
    }
  }
  .order-card {
    .order-summary h5 {
      font-size: 20px !important;
    }
    .total-order-price {
      h5 {
        font-size: 18px !important;
      }
      p {
        font-size: 24px !important;
      }
    }
  }
  .order-details-cart {
    .order-image {
      width: 100px !important;
      height: 119px !important;
    }
    .order-details-payment {
      .order-name-price {
        display: block !important;
        h6 {
          font-size: 18px !important;
          margin-bottom: 10px !important;
        }
      }
      .order-size-text-and-color {
        p {
          font-size: 10px !important;
          line-height: 15px !important;
        }
      }
      .delivery-text {
        p {
          font-size: 9px !important;
          margin-top: 5px !important;
        }
      }
    }
    .orderprice {
      p {
        font-size: 22px !important;
        margin-top: -10px !important;
      }
    }
  }
  
.order-summary .order-card .order-button {
  margin-top: 1rem;
}
}