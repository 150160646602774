.intro-blog-container {
  background-color: #e9e9e9;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 30px 80px;
}
.intro-blog-container .heading-blog {
  padding: 0;
  width: 100%;
}
.heading-blog h2 {
  font-family: Lato;
  font-size: 18px;
  font-weight: 400;
  line-height: 25px;
  text-align: center;
  color: #595959;
  margin-bottom: 0;
}
.blog-main-container {
  padding: 50px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 4rem;
  cursor: pointer !important;
}
.blog-box {
  width: 30%;
}
.blog-img {
  position: relative;
  width: 100%;
  margin-bottom: 5px;
}
.blog-img p {
  position: absolute;
  top: 0;
  right: 0;
  padding: 10px 55px;
  background-color: white;
  font-family: Cormorant;
  font-size: 19px;
  font-style: italic;
  font-weight: 500;
  line-height: 24.28px;
  text-align: left;
  margin-top: 35px;
}
.blog-image-main {
  overflow: hidden;
  // width: 394px;
  // height: 290px;
}
.blog-image-main:hover img {
  transform: scale(1.1);
}
.blog-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.4s ease;
}

.blog-content h6 {
  font-family: Lato;
  font-size: 14px;
  font-weight: 400;
  line-height: 16.8px;
  text-align: left;
  color: #595959;
  margin-bottom: 3px;
  margin-top: 10px;
}

.blog-content h3 {
  font-family: Cormorant;
  font-size: 27px;
  font-weight: 500;
  line-height: 32.99px;
  text-align: left;
  color: #000;
  margin: 8px 0px;
}

.blog-content p {
  font-family: Lato;
  font-size: 16px;
  font-weight: 300;
  line-height: 20px;
  letter-spacing: 0.01em;
  text-align: left;
  color: #595959;
}

.read-more {
  display: inline-flex;
  align-items: center;
  text-decoration: none;
  color: black;
  font-family: Lato;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 2.4000000953674316px;
  text-align: left;
  text-transform: uppercase;
}

.read-more::before {
  content: "";
  width: 50px; /* Adjust the length of the line */
  height: 2px; /* Adjust the thickness of the line */
  background-color: black;
  margin-right: 10px; /* Space between the line and text */
}

@media screen and (max-width: 820px) {
  .blog-main-container {
    padding: 20px;
  }

  .blog-box {
    width: 45%;
  }
}

@media screen and (max-width: 480px) {
  .blog-box {
    width: 100%;
  }
  .blog-main-container {
    padding: 50px 20px;
  }
  .blog-content h3 {
    font-size: 18px;
  }
  .read-more {
    font-size: 10px;
  }
}
