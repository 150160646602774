.login-page-container {
  background-color: #ffffff;
}
.header-of-login {
  background-color: #e9e9e9;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 30px 80px;
}
.header-of-login p {
  font-family: Lato;
  font-size: 18px;
  font-weight: 400;
  color: #595959;
  text-align: center !important;
  // padding-left: 150px;
  // border: 1px solid black;
  width: 100%;
}
.login-form {
  display: flex;
  flex-direction: column;
  position: relative;
}
.login-main-content {
  width: 45%;
  padding: 5%;
  padding-bottom: 120px;
}
.login-form-content h4 {
  font-family: Cormorant;
  font-size: 36px;
  font-weight: 500;
  color: #000000;
}
.login-form {
  margin-top: 20px;
}
.login-form label {
  font-family: Lato;
  font-size: 18px;
  font-weight: 500;
  color: #595959;
  margin-top: 10px;
}
// .login-form input {
//   border: 1px solid #c9c9c9;
//   height: 44px;
//   margin-top: 8px;
//   padding: 0px 20px;
//   font-size: 16px;
//   margin-bottom: 15px;
//   width: 100%;
//   position: relative;
// }
.form-field-input input {
  width: 100%;
}
.login-form .password-login-icon {
  // position: absolute;
  right: 20px;
  // top: 86px;
  cursor: pointer;
  color: #595959;
  font-size: 20px;
}
.login-form .error {
  color: red;
}
.buttons-remember-text {
  display: flex;
  gap: 25px;
  align-items: center;
  margin-top: 25px;
}
.buttons-remember-text button {
  border-radius: 0;
  background: #000000;
  border: 1px solid #000000;
  font-family: Lato;
  font-size: 12px;
  font-weight: 400;
  line-height: 14.4px;
  letter-spacing: 1px;
  text-align: center;
  color: #ffffff;
  width: 100%;
  height: 44px;
}
.buttons-remember-text button:hover {
  border: 1px solid black;
  color: #000;
  background-color: #fff;
}
.buttons-remember-text button:disabled {
  background-color: #ccc; /* Light gray background */
  color: #666; /* Dim text color */
  cursor: not-allowed; /* Change cursor to indicate the button is disabled */
  opacity: 0.6;
}
.remember-box {
  // margin-top: 20px;
  display: flex;
  gap: 5px;
  align-items: center;
  // border: 1px solid black;
}
.remember-box p {
  font-family: Lato;
  font-size: 14px;
  font-weight: 400;
  color: #595959;
  font-style: normal;
  margin-bottom: 0;
  line-height: 0;
}
.remember-box input {
  border: 1px solid #c9c9c9;
  height: 18px;
  width: 18px;
  margin-top: 0;
  // margin-top: 8px;
  padding: 2px;
  font-size: 14px;
  margin-bottom: 0;
}
.emty-box {
  width: 18px;
  height: 18px;
  border: 2px solid #595959;
}
.password-create-new {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.password-create-new p {
  margin-top: 10px;
}
.password-create-new p a {
  text-decoration: none;
  font-family: Lato;
  font-size: 16px;
  font-weight: 500;
  color: #232323;
}
.otherSignin {
  margin-top: 40px;
  p {
    text-align: left;
    font-family: "Lato", sans-serif;
    font-weight: 400;
    font-size: 14px;
    color: #232323;
    // text-transform: uppercase;
    .underline {
      text-decoration: underline;
      text-decoration-color: #797979; /* Set the underline color */
      text-decoration-thickness: 2px; /* Adjust the underline thickness */
      text-underline-offset: 4px;
    }
  }
  .otherLoginButton {
    margin-top: 5px;
    display: flex;
    align-items: center;
    gap: 20px;
    button {
      border-radius: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 8px;
      background: #fff;
      border: 1px solid #fff;
      font-family: Lato;
      font-size: 12px;
      font-weight: 400;
      line-height: 14.4px;
      letter-spacing: 1px;
      text-align: center;
      color: #000;
      width: 100%;
      height: 44px;
      margin-top: 10px;
      border: 1px solid #000;
    }
    svg {
      color: #000000;
      font-size: 24px;
    }
  }
}
@media (max-width: 992px) {
  .header-of-login p {
    padding-left: 100px;
  }
  .login-main-content {
    width: 70%;
  }
  .login-form-content h4 {
    font-size: 33px;
  }
}
@media (max-width: 768px) {
  .header-of-login p {
    padding-left: 80px;
  }
  .login-main-content {
    width: 80%;
  }
  .login-form-content h4 {
    font-size: 33px;
  }
}
@media (max-width: 576px) {
  .header-of-login p {
    padding-left: 50px;
  }
  .login-main-content {
    width: 100%;
    padding: 40px 20px;
    margin-bottom: 40px;
  }
  .buttons-remember-text button {
    width: 150px;
    height: 40px;
  }
  .login-form label {
    font-size: 15px;
  }
  .password-create-new {
    display: block;
  }
  .buttons-remember-text {
    display: block;
  }
  .remember-box {
    margin-top: 20px;
  }
  .emty-box {
    width: 16px;
    height: 16px;
  }
}
