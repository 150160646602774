.subscribe-popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(189, 188, 188, 0.5); // Semi-transparent background
  // background: #00000059;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}
.all-the-content-of-popup {
  display: flex;
  justify-content: center;
  align-items: center;
  // margin-top: 100px;
  width: 65%;
  background: #ffffff;
  padding: 25px;
}
.popup-content {
  width: 60%;
}
.popup-content-with-image-details {
  background: #fbf9f9;
  box-shadow: 2.06px 2.06px 20.64px 0px #00000040;
  height: 480px;
  //   height: auto;
  width: 100%;
}
.popup-image-div-with-details {
  display: flex;
}
.popup-image {
  box-shadow: 2.06px 2.06px 20.64px 0px #00000040;
  width: 380px;
  //   height: auto;
  //   height: 460px;
  height: 480px;
  //   margin-top: -20px;
  img {
    width: 100%;
    height: 100%;
    // object-fit: cover;
  }
}
.popup-closs-icon {
  text-align: right !important;
  cursor: pointer;
  svg {
    font-size: 20px;
    color: #111111;
  }
}
.popup-text-content {
  padding: 15px 20px;
}
.inputs-and-subscribes {
  padding: 10px 30px 30px 30px;
  text-align: center;
  h4 {
    font-family: Cormorant;
    font-size: 33.02px;
    font-weight: 400;
    line-height: 39.99px;
    text-align: center;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: #000000;
  }
  p {
    font-family: Lato;
    font-size: 18.57px;
    font-weight: 400;
    line-height: 22.29px;
    text-align: center;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: #595959;
    margin-top: 20px;
  }
}
.email-subscribe {
  display: flex;
  flex-direction: column;
  //   gap: 30px;
}
.email-input-wrapper {
  border: 1px solid #595959;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 15px;
  margin-top: 30px;
  svg {
    color: #0c0c0c;
    font-size: 22px;
  }
  input {
    border-style: none;
    outline: none;
    background-color: transparent;
    font-family: Lato;
    font-size: 15.48px;
    font-weight: 400;
    line-height: 18.57px;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    height: 44px;
    color: #0c0c0c;
    width: 100%;
  }
  input::placeholder {
    font-family: Lato;
    font-size: 15.48px;
    font-weight: 400;
    line-height: 18.57px;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: #0c0c0c;
  }
}
.subscribe-form-field {
  button {
    border-radius: 0px;
    text-transform: uppercase;
    font-family: Lato;
    font-size: 14.45px;
    font-weight: 400;
    line-height: 19.35px;
    letter-spacing: 0.2em;
    // text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: #fbf9f9;
    background: #000000;
    width: 100%;
    margin-top: 40px;
  }
}
.subscribepopup-dropdown {
  width: 100%;
  //   padding: 0px 20px;
  border: 1px solid #ccc;
  //   border-radius: 5px;
  font-size: 14px;
  background: #fff;
  cursor: pointer;
}
.dropdown-content-of-subscribe p:hover {
  background: #f0f0f0;
}

.dropdown-content-of-subscribe p.selected {
  background: #f0f0f0;
  color: #595959;
}

.dropdown-wrapper {
  position: relative;
}
.dropdown-content-of-subscribe{
  position: absolute;
  top: 62%;
  background: #fff;
  border: 1px solid #ccc;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  z-index: 1000;
  max-height: 150px; /* Set maximum height */
  overflow-y: auto; /* Add vertical scrollbar */
  overflow-x: hidden; /* Hide horizontal scrollbar */
  width: 30.15%;
  //   .inputs-and-subscribes p {
  //     line-height: 0px !important;
  //   }
  p {
    text-align: left;
    font-size: 16px;
    color: #595959;
    padding: 10px 10px 10px 18px;
    line-height: 8px;
    margin-top: 5px;
  }
}
.error-message {
  color: red;
  text-align: left;
}
@media (max-width: 700px) {
  .all-the-content-of-popup {
    width: 90%;
  }
  .popup-image {
    display: none;
  }
  .popup-content {
    width: 100%;
    padding-left: 0px;
    //   height: 580;
  }
  .popup-content-with-image-details {
    height: auto;
  }
  .all-the-content-of-popup {
    margin-top: 0px;
  }
  .email-input-wrapper {
    svg {
      font-size: 18px;
    }
    input {
      font-size: 12px;
      height: 40px;
    }
    input::placeholder {
      font-size: 12px;
    }
  }
  .subscribe-form-field {
    button {
      font-size: 12px;
    }
  }
  .inputs-and-subscribes {
    h4 {
      font-size: 20px;
    }
    p {
      font-size: 12px;
    }
  }
  .popup-closs-icon {
    text-align: right;
    svg {
      font-size: 18px;
    }
  }
  .dropdown-content-of-subscribe {
    top: 61%;
    width: 65.60%;
  }
}
@media (max-width:480px) {
    .inputs-and-subscribes {
        padding: 10px 10px 10px 10px;
    }   
    .popup-text-content {
        padding: 10px 5px;
    } 
    .dropdown-content-of-subscribe {
        top: 64%;
        width: 69%;
      }
}
@media screen and (max-width: 700px) and (orientation: landscape) {
  .all-the-content-of-popup {
    width: 70%;
    margin-top: 50px;
  }
  .dropdown-content-of-subscribe {
    top: 62%;
    max-height: 120px; /* Set maximum height */
    width: 47%;
  }
  .inputs-and-subscribes {
    p {
      margin-top: 8px;
    }
  }
  .email-input-wrapper {
    margin-top: 10px;
  }
  .subscribe-form-field {
    button {
      margin-top: 20px;
    }
  }
  .inputs-and-subscribes{
    padding: 0px 30px 30px 30px;
  }
}
@media screen and (max-width: 992px) and (orientation: landscape) {
  .all-the-content-of-popup {
    width: 95%;
    margin-top: 50px;
  }
  .popup-content-with-image-details {
    height: 400px;
    width: 100%;
  }
  .popup-image {
    width: 300px;
    height: 400px;
  }
  .dropdown-content-of-subscribe {
    top: 62%;
    max-height: 120px; /* Set maximum height */
    width: 36%;
  }
  .inputs-and-subscribes {
    p {
      margin-top: 8px;
    }
  }
  .email-input-wrapper {
    margin-top: 10px;
  }
  .subscribe-form-field {
    button {
      margin-top: 20px;
    }
  }
  .inputs-and-subscribes{
    padding: 0px 30px 30px 30px;
  }
}