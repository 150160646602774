.banner-part {
  width: 100%;
  height: 60vh;
  display: flex;

  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 40px 0px 40px;
  background-image: url("../images/banner.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  @media screen and (max-width: 480px) {
    height: 50vh;
  }
}

.banner-part h1 {
  font-family: "Cormorant", sans-serif;
  font-size: 50px;
  font-weight: 500;
  line-height: 60.55px;
  text-align: center;
  color: #fff;
  margin-bottom: 30px;
  @media screen and (max-width: 480px) {
    font-size: 30px;
    line-height: 36px;
  }
}

.banner-part {
  button {
    padding: 15px 55px;
    font-family: "Lato", sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 18.75px;
    letter-spacing: 0.3em;
    text-align: center;
    background-color: #fff;
    color: #000;
    border: none;
    border-radius: 0%;

    @media screen and (max-width: 480px) {
      font-size: 10px;
      line-height: 13px;
      padding: 12px 40px;
    }
    &:hover {
      background-color: #000; /* Change to desired hover background color */
      color: #fff; /* Change to desired hover text color */

      transition: all 0.3s ease; /* Adds a smooth transition */
    }
  }
}
.featured-in {
  padding-top: 20px;
  @media screen and (max-width: 480px) {
    padding-top: 10px;
  }
}
.head-featured-part {
  h2 {
    text-align: center;
    font-weight: 500;
    font-size: 40px;
    text-transform: uppercase;
    font-family: "Cormorant", sans-serif;
  }
  @media screen and (max-width: 480px) {
    h2 {
      font-size: 20px;
      // margin-top: 30px;
    }
  }
}
.slide img {
  width: 200px;
  /* height: 100px; */
  /* animation: scroll 60s linear infinite; */
  @media screen and (max-width: 480px) {
    width: 100px;
  }
}

.slide-track {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 65px;
  scroll-behavior: smooth;
  overflow-x: auto;
  scroll-snap-type: x mandatory;
  justify-content: space-between;
  scrollbar-width: none;
  @media screen and (max-width: 480px) {
    gap: 30px;
  }
}
.slide-track::-webkit-scrollbar {
  display: none;
}

.logo-slider {
  margin: 20px 0px 50px 0px;
  padding: 30px 40px;
  // padding: 3em 5em;
  @media screen and (max-width: 480px) {
    margin: 10px;
    padding: 10px 5px 30px 10px;
  }
}
@media screen and (max-width: 1000px) {
  .banner-part {
    height: 35vh;
  }
}
@media screen and (max-width: 360px) {
  .banner-part h1 {
    font-size: 30px;
  }
}
@media screen and (max-width: 992px) and (orientation: landscape) {
  .banner-part {
    height: 80vh;
    h1 {
      font-size: 35px;
    }
  }
  .banner-part button {
    padding: 12px 40px;
  }
  .head-featured-part h2 {
    font-size: 30px;
  }
  .slide-track{
    gap: 40px;
  }
}
