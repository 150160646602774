.intro-pri-container {
  background-color: #e9e9e9;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 30px 80px;
}

.intro-pri-container .heading-pri {
  padding: 0;
  width: 100%;
}

.heading-pri h2 {
  font-family: Lato;
  font-size: 18px;
  font-weight: 400;
  line-height: 25px;
  text-align: center;
  color: #595959;
  margin-bottom: 0;
}

.privacy-content {
  padding: 50px 80px;
  max-width: 1200px;
  margin: 0 auto;
}

.main-Privacy-Head {
  h2 {
    font-family: "Cormorant", sans-serif;
    font-size: 35px;
    font-weight: 500;
    line-height: 38.75px;
    letter-spacing: 0.005em;
    text-align: center;
    text-transform: uppercase;
    // border: 1px solid black;
  }
  .main-Privacy-Headpara {
    margin-top: 20px;
  }
}

.privacy-content h6 {
  font-family: Lato;
  font-size: 18px;
  font-weight: 600;
  text-align: left;
  color: #595959;
  margin-top: 40px;
}

.privacy-content p {
  font-family: Lato;
  font-size: 18px;
  font-weight: 400;
  line-height: 32px;
  letter-spacing: 0.005em;
  text-align: left;
  color: #595959;
  margin: 10px 0;
}

.privacy-content p a {
  text-decoration: underline;
  color: #595959;
}
.privacy-policy-contact-details {
  h5 {
    font-family: Lato;
    font-size: 18px;
    font-weight: 700;
    line-height: 32px;
    letter-spacing: 0.005em;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: #595959;
  }
  p {
    font-family: Lato;
    font-size: 18px;
    font-weight: 400;
    line-height: 32px;
    letter-spacing: 0.005em;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
  }
}

@media screen and (max-width: 480px) {
  .main-Privacy-Head h2 {
    font-size: 30px;
  }
  .privacy-content h6 {
    padding-bottom: 20px;
    line-height: 35px;
  }
  .privacy-content p {
    font-size: 16px;
  }
  .privacy-content {
    padding: 50px 20px;
  }

  .privacy-content h6 {
    font-size: 25px;
  }

  .privacy-content p {
    margin-top: -20px;
  }
  .privacy-policy-contact-details {
    p {
      margin-top: 10px;
    }
  }
}
