.new-password-main-content {
  padding: 4% 5% 6% 5%;
}
.details-of-new-password h6 {
  font-family: Lato;
  font-size: 18px;
  font-weight: 500;
  color: #595959;
}
.new-password-form {
  width: 45%;
  margin-top: -10px;
}
.new-password-form {
  .form-field-newPass {
    display: flex;
    flex-direction: column;
    flex: 1;
    position: relative;
  }
  .form-field-newPass label {
    font-family: Lato;
    font-size: 18px;
    font-weight: 500;
    color: #595959;
    margin-top: 10px;
  }
  .form-field-newPass input {
    border: 1px solid #c9c9c9;
    height: 44px;
    margin-top: 8px;
    padding: 0px 20px;
    font-size: 16px;
    margin-bottom: 15px;
    // position: relative;
  }
  .form-field-newPass .error {
    color: red;
  }
  .form-field-newPass .password-main-forget-icon {
    position: absolute;
    right: 20px;
    top: 50px;
    cursor: pointer;
    color: #595959;
    font-size: 20px;
  }
  .form-field-newPass .confirmpassword-main-forget-icon {
    position: absolute;
    right: 20px;
    top: 50px;
    cursor: pointer;
    color: #595959;
    font-size: 20px;
  }
}
.confirm-field label {
  margin-top: 35px;
  text-align: left;
}
.confirm-field input {
  margin-top: 10px !important;
}
.reset-password-button {
  margin-top: 30px;
  border-radius: 0px;
  font-size: 12px;
  padding: 10px;
  width: 250px;
  font-family: "Lato", sans-serif;
  letter-spacing: 1px;
}
@media (max-width: 768px) {
  .new-password-main-content {
    padding: 6% 5% 6% 5%;
  }
  .details-of-new-password h6 {
    font-size: 15px;
  }
  .new-password-form {
    width: 100%;
    margin-top: -15px;
  }
  .new-password-form {
    .form-field-newPass input {
      padding: -10px;
    }
    .form-field-newPass p {
      width: 100%;
      font-size: 12px;
    }
  }
  .confirm-field label {
    margin-top: 25px;
    font-size: 15px;
  }
  .reset-password-button {
    width: 60%;
  }
}
@media (max-width: 576px) {
  .new-password-main-content {
    padding: 10% 7% 8% 7%;
  }
}
