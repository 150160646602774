.searchbar-popup-main-content {
  box-shadow: 0px 4px 20px 0px #00000040;
  background: #f8f9fa;
  padding: 20px;
  border-radius: 0px;
}
.main-popup-div-for-input {
  background: #e5e5e5;
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 15px 20px;
}
.main-popup-div-for-input svg {
  font-size: 15px;
}
.main-popup-div-for-input input {
  border-style: none;
  outline: none;
  border: none;
  background: #e5e5e5;
  font-size: 15px;
  color: #595959;
}
.main-popup-div-for-input::placeholder {
  font-size: 12px;
  color: #595959;
}
.css-rsks93-MuiPaper-root-MuiDialog-paper {
  border-radius: 0px !important;
}
