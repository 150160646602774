.signup-main-form-content {
  width: 100%;
  padding: 4% 5%;
  padding-bottom: 50px;
}
.signup-main-form-content h4 {
  font-family: Cormorant;
  font-size: 36px;
  font-weight: 500;
  color: #000000;
}
.signup-main-form-content {
  margin-top: 10px;
}
.signup-form {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  gap: 40px;
  margin-top: 10px;
}
.form-field {
  display: flex;
  flex-direction: column;
  flex: 1;
  position: relative;
}
.form-field label {
  font-family: Lato;
  font-size: 18px;
  font-weight: 500;
  color: #595959;
  margin-top: 10px;
}
.form-field input {
  border: 1px solid #c9c9c9;
  height: 44px;
  margin-top: 8px;
  padding: 0px 20px;
  font-size: 16px;
  margin-bottom: 15px;
  position: relative;
  color: #595959;
}
.form-field .default-india-code-main-field{
  position: absolute;
  color: #595959;
  font-size: 18px;
  z-index: 20;
  top: 15.5px;
  margin-right: 20px;
  left: 20px;
  margin-top: 2px;
}

.form-field input[name="phone"] {
  // padding-left: 51px; /* Increase padding to make space for the code */
  // border: 1px solid #c9c9c9;
  // height: 44px;
  // margin-top: 8px;
  // padding: 0px 20px;
  // font-size: 16px;
  // margin-bottom: 15px;
}
.form-field .password-main-signup-icon {
  position: absolute;
  right: 20px;
  top: 18px;
  cursor: pointer;
  color: #595959;
  font-size: 20px;
}
.form-field .confirmpassword-main-signup-icon {
  position: absolute;
  right: 20px;
  top: 18px;
  cursor: pointer;
  color: #595959;
  font-size: 20px;
}
.form-field .error {
  color: red;
}
.already-acc-text {
  margin-top: 25px;
}
.password-text {
  font-family: Lato;
  font-size: 14px;
  font-weight: 500;
  line-height: 16.8px;
  text-align: left;
  color: #595959;
  margin-top: 10px;
}
.buttons-text {
  margin-top: 50px;
}
.buttons-text p,
.signup-main-form-content p {
  font-family: Lato;
  font-size: 16px;
  font-weight: 500;
  color: #595959;
}
.signup-main-form-contentp {
  margin-top: 25px;
}
.signup-main-form-content p a {
  font-family: Lato;
  font-size: 16px;
  font-weight: 500;
  color: #595959;
  // text-decoration: none;
}
.buttons-text button {
  border-radius: 0;
  background: #000000;
  border: 1px solid #000000;
  font-family: Lato;
  font-size: 12px;
  font-weight: 400;
  line-height: 14.4px;
  letter-spacing: 1px;
  text-align: center;
  color: #ffffff;
  width: 200px;
  height: 44px;
  margin-top: 10px;
}
.buttons-text button:hover {
  border: 1px solid black;
  color: #000;
  background-color: #fff;
}
.buttons-text button:disabled {
  background-color: #ccc; /* Light gray background */
  color: #666; /* Dim text color */
  cursor: not-allowed; /* Change cursor to indicate the button is disabled */
  opacity: 0.6;
}
.google-facebook {
  margin-top: 30px;
}
.google-facebook p {
  font-family: Lato;
  font-size: 16px;
  font-weight: 400;
  color: #232323;
}
.facebook-google-button {
  display: flex;
  margin-top: 15px;
  gap: 15px;
}
.facebook-google-button button {
  border: 1px solid #000000;
  width: 200px;
  height: 44px;
  padding: 10px 40px 10px 40px;
  display: flex;
  align-items: center;
  background-color: transparent;
  gap: 20px;
  font-family: Lato;
  font-size: 12px;
  font-weight: 400;
  letter-spacing: 1px;
  color: #000000;
  border-radius: 0;
}
.facebook-google-button button svg {
  color: #000000;
  font-size: 24px;
}
@media (max-width: 992px) {
  .password-text {
    font-size: 10px;
  }
}
@media (max-width: 576px) {
  .signup-main-form-content h4 {
    font-size: 32px;
  }
  .signup-form {
    display: block;
  }
  .form-field label {
    font-size: 15px;
  }
  .form-field input {
    height: 40px;
  }

  .password-text {
    font-size: 12px;
    margin-top: 5px;
  }
  .buttons-text p,
  .signup-main-form-content p {
    font-size: 14px;
  }
  .google-facebook p {
    font-size: 14px;
  }
  .facebook-google-button button {
    border: 1px solid #000000;
    width: 180px;
    height: 44px;
    padding: 10px 20px;
    display: flex;
    align-items: center;
    background-color: transparent;
    gap: 20px;
    font-family: Lato;
    font-size: 12px;
    font-weight: 400;
    letter-spacing: 1px;
    color: #000000;
  }
  .facebook-google-button button svg {
    font-size: 18px;
  }
  .signup-main-form-content {
    padding: 10% 5%;
    padding-bottom: 80px;
}
}
@media (max-width: 400px) {
  .form-field .default-india-code-main {
    margin-top: 0px;
  }
}
