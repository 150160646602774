.address-of-person {
  margin-top: 20px;
  border: 1px solid #c9c9c9;
  padding: 20px 15px;
}
.address-of-person h6 {
  font-family: Lato;
  font-size: 18px;
  font-weight: 600;
  color: #0c0c0c;
}
.person-address-details {
  margin-top: 10px;
}
.person-address-details p {
  font-family: Lato;
  font-size: 14px;
  font-weight: 300;
  color: #303030;
}
.person-address-details p a {
  font-family: Lato;
  font-size: 14px;
  font-weight: 300;
  color: #303030;
}
.edit-number-box {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.payment-option h5 {
  font-family: Cormorant;
  font-size: 26px;
  font-weight: 500;
  color: #000000;
  margin-top: 40px;
}
.all-options-of-payment {
  border: 1px solid #c9c9c9;
  padding: 10px 20px;
  margin-top: 20px;
}
.circle-name {
  display: flex;
  gap: 20px;
  align-items: center;
}
.emty-circle {
  width: 25px;
  height: 25px;
  border: 3px solid #595959;
  border-radius: 50%;
  cursor: pointer;
}
.filled-circle {
  background-color: #000; // Black color for the selected circle
}
.circle-name h6 {
  font-family: Lato;
  font-size: 20px;
  font-weight: 500;
  color: #0c0c0c;
  margin-bottom: 0;
}
.bank-details p {
  font-family: Cormorant;
  font-size: 28px;
  font-weight: 500;
  color: #0c0c0c;
}
.bank-details {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.order-button {
  margin-top: 15px;
}
.order-button button {
  width: 100%;
  background-color: black;
  color: white;
  padding: 10px 15px;
  border: none;
  border-radius: 0;
  cursor: pointer;
  font-size: 16px;
}
.order-button button:hover {
  background-color: #fff;
  color: black;
  border: 1px solid black;
}
.order-button button:disabled {
  background-color: #ccc; /* Light gray background */
  color: #666; /* Dim text color */
  cursor: not-allowed; /* Change cursor to indicate the button is disabled */
  opacity: 0.6; /* Slight transparency to indicate disabled state */
}
@media (min-width:320px) and (max-width:576px) {
  .checkout-address-content{
    h5{
      font-size: 20px;
    }
  }
  .address-of-person h6 {
    font-size: 14px;
  }
  .person-address-details {
    margin-top: 10px;
  }
  .person-address-details p {
    font-size: 12px;
  }
  .person-address-details p a {
    font-size: 12px;
  }
  .order-button button {
    font-size: 12px;
  }
}