.profile-order-page-container {
  width: 100%;
}
.not-order-present {
  border: 1px solid #595959;
  box-shadow: 0px 4px 20px 0px #59595940;
  padding: 10px 25px;
  display: flex;
  gap: 15px;
  align-items: center;
}
.not-order-present p {
  font-family: Lato;
  font-size: 20px;
  font-weight: 400;
  color: #595959;
}
.not-order-present svg {
  color: #000000;
  font-size: 33px;
}
.card-of-order {
  border: 1px solid #595959;
  box-shadow: 0px 4px 20px 0px #59595940;
  padding: 15px;
  // margin-top: 40px;
  display: grid;
  grid-template-columns: auto;
  gap: 25px;
  margin-bottom: 20px;
  .innerPart-of-order {
    // border: 1px solid black;
    width: 100%;
    display: flex;
    gap: 25px;
  }
}
.order-card-image img {
  width: 152px;
  height: 172px;
}
.order-card-details {
  width: 100%;
}
.order-name-price {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.order-name-price h6 {
  font-family: Cormorant;
  font-size: 20px;
  font-weight: 500;
  color: #0c0c0c;
}
.order-name-price p {
  font-family: Cormorant;
  font-size: 30px;
  font-style: italic;
  font-weight: 500;
  color: #0c0c0c;
}
.order-text-p p {
  font-family: Lato;
  font-size: 15px;
  font-weight: 400;
  color: #595959;
}
.order-color-size-address {
  margin-top: 20px;
}
.order-color-size-address p {
  font-family: Lato;
  font-size: 15px;
  font-weight: 400;
  color: #595959;
}
.order-card-buttons {
  margin-top: 25px;
  display: flex;
  justify-content: space-between;
}
.mobile-view-button{
  display: none;
}
@media (max-width:768px) {
  .desktop-view-button{
    display: none;
  }
  .mobile-view-button{
    display: flex;
    justify-content: space-between !important;
    button{
      font-size: 12px;
    }
  }
  .card-of-order {
    gap: 0px;
  }
  .order-card-buttons{
    width: 100% !important;
    justify-content: space-between !important;
    gap: 30px !important;
    button{
      width: 50% !important;
    }
    
  }
  .order-name-price p{
    font-size: 22px;
  }
  .order-name-price h6 {
    font-size: 18px;
    margin-bottom: 0px;
  }
  .order-color-size-address p {
    line-height: 25px;
  }
  .profile-order-main-content{
    margin-top: 40px;
  }
  .order-card-image img {
    width: 142px;
    height: 170px;
}
.card-of-order .innerPart-of-order {
  gap: 15px;
}
.order-name-price {
  align-items: start; 
  display: block;
}
}
