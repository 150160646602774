.menu-box {
  position: fixed;
  top: 0;
  // margin-top: 220px;
  right: -250px; /* Initially hidden off-screen */
  width: 250px;
  height: 100vh; /* Fixed height */
  background-color: #fff;
  box-shadow: -2px 0 5px rgba(0, 0, 0, 0.2);
  transition: right 0.3s ease;
  z-index: 1000;
  padding: 0px 25px;
  padding-bottom: 20px;
  overflow-y: auto; /* Enable scrolling */
}

.menu-box.open {
  right: 0; /* Slide in when open */
}

.load-more-container{
  button{
    border-radius: 0px;
  }
}
.shopby-crossicon{
  display: flex;
  justify-content: space-between;
  align-items: center;
}
@media (max-width: 768px) {
  /* Only display the menu box when open on mobile */
  .menu-box.open {
    display: block;
  }
}
