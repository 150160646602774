.thankyou-main-content {
  padding-bottom: 60px;
}
.thankyou-text-icon {
  background: #000000;
  text-align: center;
  padding: 6% 0;
}
.thankyou-text-icon h3 {
  font-family: Cormorant;
  font-size: 64px;
  font-weight: 700;
  color: #ffffff;
}
.thankyou-text-icon svg {
  margin-top: 25px;
  color: #ffffff;
  font-size: 50px;
}
.detail-of-payment-process {
  padding: 5% 7%;
  width: 100%;
}
.payment-table {
  border: 1px solid #e7e7e7;
}
.payment-table h5 {
  font-family: Lato;
  font-size: 24px;
  font-weight: 500;
  color: #000000;
  border-bottom: 1px solid #e7e7e7;
  padding: 15px 20px;
}
.all-div-of-payment-details {
  display: flex;
}
.name-email-details {
  width: 100%;
  border-right: 1px solid #e7e7e7;
  padding: 20px;
  padding-bottom: 40px;
}
.name-email-details p {
  font-family: Lato;
  font-size: 18px;
  font-weight: 500;
  color: #636262;
}
.name-email-details h6 {
  font-family: Lato;
  font-size: 18px;
  font-weight: 500;
  color: #000000;
  margin-top: 20px;
}
@media (max-width: 992px) {
  .thankyou-text-icon h3 {
    font-size: 44px;
  }
  .thankyou-text-icon svg {
    font-size: 35px;
  }
  .payment-table h5 {
    font-size: 20px;
  }
  .name-email-details p {
    font-size: 15px;
  }
  .name-email-details h6 {
    font-size: 15px;
  }
}
@media (max-width: 768px) {
  .thankyou-text-icon h3 {
    font-size: 28px;
  }
  .thankyou-text-icon svg {
    font-size: 25px;
  }
  .all-div-of-payment-details {
    display: block;
  }
  .payment-table h5 {
    font-size: 18px;
  }
  .name-email-details p {
    font-size: 18px;
  }
  .name-email-details h6 {
    font-size: 18px;
  }
  .name-email-details {
    padding-bottom: 20px;
    border-bottom: 1px solid #e7e7e7;
    border-right: none;
  }
  .payment-table {
    border: none;
  }
  .payment-table h5 {
    border: 1px solid #e7e7e7;
  }
}
@media (max-width: 576px) {
  .thankyou-text-icon {
    padding: 12% 0;
  }
  .thankyou-text-icon svg {
    margin-top: 15px;
  }
  .payment-table h5 {
    padding: 15px 10px;
    font-size: 15px;
  }
  .name-email-details p {
    font-size: 15px;
  }
  .name-email-details h6 {
    font-size: 15px;
  }
  .detail-of-payment-process {
    margin-top: 20px;
  }
}
