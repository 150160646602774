.swiper-container {
  .slideContent {
    background-size: cover;
    background-position: bottom;
    background-repeat: no-repeat;
    height: 90vh;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    padding-top: 80px;
    // &::before {
    //   content: "";
    //   position: absolute;
    //   top: 0;
    //   left: 0;
    //   right: 0;
    //   bottom: 0;
    //   background: rgba(0, 0, 0, 0.4); // Semi-transparent overlay
    //   box-shadow: 0 4px 20px rgba(0, 0, 0, 0.5); // Add shadow effect
    //   z-index: 1;
    // }
  }

  .textContainer {
    text-align: center;
    color: #fdfdfd;
    // z-index: 2;
    h2 {
      font-family: "Cormorant", sans-serif;
      font-size: 50px;
      margin-bottom: 50px;
      line-height: 55px;
      font-weight: 500;
      text-transform: uppercase;
    }

    .exploreBtn {
      background-color: #fdfdfd;
      color: #000;
      padding: 15px 40px;
      border: none;
      cursor: pointer;
      font-size: 12px;
      letter-spacing: 0.3em;
      text-align: center;
      transition: all 0.3s ease;
      font-family: "Lato", sans-serif;
      font-weight: 600;
      border-radius: 0%;
      text-transform: uppercase;
      &:hover {
        background-color: #000;
        color: #fff;
      }
    }
  }

  .swiper-button-prev-custom,
  .swiper-button-next-custom {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 10;
    cursor: pointer;

    svg {
      width: 35px;
      height: 78px;
      color: #fdfdfd;
    }
  }

  .swiper-button-prev-custom {
    left: 20px; // Position the left arrow
  }

  .swiper-button-next-custom {
    right: 20px; // Position the right arrow
  }
}
.swiper-container-mob {
  display: none;
}
@media (max-width:1024px) {
  .swiper-container {
    .slideContent {
      height: 60vh;
    } 
    .swiper-button-prev-custom,
    .swiper-button-next-custom {
      top: 33%;
    }
  }
}
@media (min-width: 600px) and (max-width: 1000px) {
  .swiper-button-next-custom,
  .swiper-button-prev-custom {
    display: none;
  }
}
@media (min-width: 300px) and (max-width: 600px) {
  .swiper-container {
    display: none;
  }
  .swiper-container-mob {
    display: block;
    .slideContent-mob {
      background-size: cover;
      background-position: center;
      height: 70vh; // Full-screen height
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
    }

    .textContainer-mob {
      text-align: center;
      color: #fdfdfd;

      h2 {
        font-family: "Cormorant", sans-serif;
        font-size: 20px;
        margin-bottom: 20px;
        line-height: 20.33px;
        font-weight: 500;
      }

      .exploreBtn-mob {
        text-transform: uppercase;
        border-radius: 0;
        background-color: #fdfdfd;
        color: #000;
        padding: 12px 20px;
        border: none;
        cursor: pointer;
        font-size: 9px;
        letter-spacing: 0.3em;
        text-align: center;
        transition: all 0.3s ease;
        font-family: "Lato", sans-serif;
        font-weight: 600;

        &:hover {
          background-color: #000;
          color: #fff;
        }
      }
      .swiper-pagination-bullet {
        background-color: #fff; // Change dot color as needed
        width: 10px;
        height: 10px;
        opacity: 0.7;
        transition: opacity 0.3s ease;
      }

      .swiper-pagination-bullet-active {
        opacity: 1;
        background-color: #000; // Active dot color
      }
    }
  }
}
@media screen and (max-width: 992px) and (orientation: landscape) {
  .swiper-container {
    .textContainer {
      h2 {
        font-size: 25px;
        // margin-top: 130px;
        margin-bottom: 20px;
        // line-height: 20px;
      }
      .exploreBtn {
        padding: 15px 30px;
      }
    }
  }
  .logoContainer {
    img {
      max-width: 200px;
    }
  }
  .swiper-container {
    .slideContent {
      height: 100vh;
    }
  }
}
