.brochure-page-container {
  width: 100%;
}
.brochure-page-main-content {
  padding: 5% 4%;
  width: 100%;
}
.all-brochure-cards-box {
  display: grid;
  grid-template-columns: repeat(3, 2fr);
  gap: 30px;
}
.brochure-main-card-details {
  width: 100%;
  height: auto;
  text-align: center;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.brochure-card-image {
  width: 100%;
  // height: 100%;
  position: relative;
  // border: 1px solid black;
  .brochure-image {
    width: 100%;
    height: auto;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}

.download-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6); // Dark overlay
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  transition: opacity 0.3s ease;
}

.brochure-main-card-details:hover .download-overlay {
  opacity: 1;
}
.brochure-download {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
}
.download-icon {
  // color: #fff;
  font-size: 2rem;
}

.brochure-main-card-details p {
  font-family: Cormorant;
  font-size: 32px;
  font-weight: 500;
  letter-spacing: 0.005em;
  color: #0c0c0c;
  margin-top: 2px;
}

@media (max-width: 600px) {
  .all-brochure-cards-box {
    grid-template-columns: repeat(2, 2fr);
    gap: 20px;
  }
  .brochure-main-card-details p {
    font-size: 14px;
    padding-bottom: 5px;
  }
  // .brochure-card-image img {
  //   height: 223px;
  // }
  .brochure-main-card-details {
    box-shadow: 0px 0px 9.31px 0px #0000001f;
  }
  // .brochure-card-image {
  //   height: 230px;
  // }
  .brochure-page-main-content {
    padding: 10% 4%;
    margin-bottom: 30px;
  }

  .brochure-download {
    width: 40px;
    height: 40px;
  }
}
