.profile-signout-page-container {
  width: 100%;
  //   display: flex;
  //   justify-content: center;
  //   align-items: center;
}
.proffile-signout-main-content {
  width: 60%;
}
.profile-signout-popup-div {
  box-shadow: 0px 1px 20px 0px #00000040;
  padding: 35px 40px 30px 40px;
  display: flex;

  flex-direction: column;
  gap: 30px;
  text-align: center;
  align-items: center;
}
.profile-signout-popup-div p {
  font-family: Cormorant;
  font-size: 32px;
  font-weight: 400;
  color: #000000;
}
.profile-signout-div-buttons {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 50px;
}
.signout-popup-cancel-button,
.signout-popup-signout-button {
  border: 1px solid #000000;
  font-family: Lato;
  font-size: 14px;
  font-weight: 400;
  background-color: transparent;
  padding: 8px 50px;
  letter-spacing: 1px;
  color: #000000;
  border-radius: 0px;
}
.signout-popup-signout-button {
  color: #ffffff;
  background-color: #000000;
}
@media (max-width: 768px) {
  .profile-signout-page-container {
    margin-top: 40px;
  }
  .proffile-signout-main-content {
    width: 100%;
  }
  .profile-signout-popup-div {
    padding: 35px 20px 30px 20px;
  }
  .profile-signout-popup-div p {
    font-size: 20px;
  }
  .profile-signout-div-buttons {
    gap: 20px;
  }
  .signout-popup-cancel-button,
  .signout-popup-signout-button {
    font-size: 12px;
    padding: 8px 36px;
  }
}
